import { Color } from "@phosphor/server";
import type {
  StandardLonghandProperties,
  StandardShorthandProperties,
} from "csstype";
import { useMemo } from "react";

const DEFAULT_COLOR = Color.make({ chroma: 0.5, hue: 0 });

export function getColorStyles(color = DEFAULT_COLOR) {
  return {
    container: {
      backgroundColor: `oklch(0.95 ${color.chroma * 0.1} ${color.hue})`,
      borderColor: `oklch(0.89 ${color.chroma} ${color.hue})`,
    },
    listItem: {
      backgroundColor: `oklch(0.92 ${color.chroma * 0.2} ${color.hue})`,
    },
    textPrimary: { color: `oklch(0.4 ${color.chroma} ${color.hue})` },
    textSecondary: { color: `oklch(0.2 ${color.chroma * 0.5} ${color.hue})` },
    colorPreview: {
      backgroundColor: `oklch(0.75 ${color.chroma * 0.8} ${color.hue})`,
    },
  } satisfies Record<
    string,
    StandardLonghandProperties & StandardShorthandProperties
  >;
}

export function useColorStyles(color = DEFAULT_COLOR) {
  return useMemo(
    () => getColorStyles({ chroma: color.chroma, hue: color.hue }),
    [color.chroma, color.hue],
  );
}
