import { call } from "@phosphor/prelude";
import {
  type MVPWorkspaceCompacted,
  type MarkdownText,
  OrgUserID,
  WorkspaceAssumptionID,
  WorkspaceModuleID,
  WorkspacePartyID,
  WorkspaceSourceID,
  WorkspaceStrategyID,
  md,
} from "@phosphor/server";

const uuidFor = (id: number, prefix = 0) =>
  `${prefix.toString(16).padStart(8, "0")}-0000-0000-0000-${id.toString(16).padStart(12, "0")}`;

// Static sample data
export const SAMPLE_DATA = {
  parties: {
    BlackRock: {
      id: WorkspacePartyID.make(uuidFor(0, 200)),
      displayName: "BlackRock",
    },
    CrossBoundary: {
      id: WorkspacePartyID.make(uuidFor(1, 200)),
      displayName: "Cross Boundary",
    },
    Phosphor: {
      id: WorkspacePartyID.make(uuidFor(2, 200)),
      displayName: "Phosphor",
    },
    Point72: {
      id: WorkspacePartyID.make(uuidFor(3, 200)),
      displayName: "Point 72",
    },
    Terminal49: {
      id: WorkspacePartyID.make(uuidFor(4, 200)),
      displayName: "Terminal 49",
    },
    SolarCo: {
      id: WorkspacePartyID.make(uuidFor(5, 200)),
      displayName: "Solar Co",
    },
  } satisfies Record<
    string,
    {
      id: WorkspacePartyID;
      displayName: string;
    }
  >,
  modules: {
    Timing: {
      id: WorkspaceModuleID.make(uuidFor(6, 200)),
      displayName: "Timing Assumptions",
    },
    CapEx: {
      id: WorkspaceModuleID.make(uuidFor(7, 200)),
      displayName: "CapEx Assumptions",
    },
  } satisfies Record<
    string,
    {
      id: WorkspaceModuleID;
      displayName: string;
    }
  >,
  sources: {
    CBE: {
      id: WorkspaceSourceID.make(uuidFor(8, 200)),
      displayTitle: md`CBE Document`,
      fileName: "CBE_Financial_Close.pdf",
      uploaderComments: md`Financial Close document from CBE`,
    },
    LeaseAgreement: {
      id: WorkspaceSourceID.make(uuidFor(9, 200)),
      displayTitle: md`Lease Agreement`,
      fileName: "Lease_Agreement.pdf",
      uploaderComments: md`Lease Agreement between parties`,
    },
    FinancialClose: {
      id: WorkspaceSourceID.make(uuidFor(10, 200)),
      displayTitle: md`Financial Close`,
      fileName: "Financial_Close.pdf",
      uploaderComments: md`Financial Close documentation`,
    },
  } satisfies Record<
    string,
    {
      id: WorkspaceSourceID;
      displayTitle: MarkdownText;
      fileName: string;
      uploaderComments: MarkdownText;
    }
  >,
  assumptions: {
    FinancialClose: {
      id: WorkspaceAssumptionID.make(uuidFor(11, 200)),
      displayLabel: "Financial Close",
    },
    ConstructionStart: {
      id: WorkspaceAssumptionID.make(uuidFor(12, 200)),
      displayLabel: "Availability/Construction Start Date",
    },
    StartOfOperations: {
      id: WorkspaceAssumptionID.make(uuidFor(13, 200)),
      displayLabel: "Start of Operations",
    },
    SolarPVModules: {
      id: WorkspaceAssumptionID.make(uuidFor(14, 200)),
      displayLabel: "Solar PV Modules",
    },
    SolarEPCCost: {
      id: WorkspaceAssumptionID.make(uuidFor(15, 200)),
      displayLabel: "Solar - EPC cost (balance)",
    },
  } satisfies Record<
    string,
    {
      id: WorkspaceAssumptionID;
      displayLabel: string;
    }
  >,
  strategies: {
    FinancialHealth: {
      id: WorkspaceStrategyID.make(uuidFor(16, 200)),
      displayTitle: md`Financial Health Assessment`,
    },
    BusinessViability: {
      id: WorkspaceStrategyID.make(uuidFor(17, 200)),
      displayTitle: md`Business Viability Assessment`,
    },
    CollateralAssessment: {
      id: WorkspaceStrategyID.make(uuidFor(18, 200)),
      displayTitle: md`Collateral Assessment`,
    },
  } satisfies Record<
    string,
    {
      id: WorkspaceStrategyID;
      displayTitle: MarkdownText;
    }
  >,
  users: {
    JohnMarciano: {
      id: OrgUserID.make(uuidFor(19, 200)),
      profileName: "John Marciano",
      displayName: "john.marciano",
      photoURL: "https://example.com/john-marciano.jpg",
      role: "Tax Equity Lead at Black Sabbath",
    },
    AvaSmith: {
      id: OrgUserID.make(uuidFor(20, 200)),
      profileName: "Ava Smith",
      displayName: "ava.smith",
      photoURL: "https://example.com/ava-smith.jpg",
      role: "Financial Analyst",
    },
    MichaelMiller: {
      id: OrgUserID.make(uuidFor(21, 200)),
      profileName: "Michael Miller",
      displayName: "michael.miller",
      photoURL: "https://example.com/michael-miller.jpg",
      role: "Project Manager",
    },
  } satisfies Record<
    string,
    {
      id: OrgUserID;
      profileName: string;
      displayName: string;
      photoURL: string;
      role: string;
    }
  >,
};

export const SAMPLE_SOLAR = call((): MVPWorkspaceCompacted => {
  return {
    name: "Solar Infra Power Project",
    description: md`A comprehensive databook for the Solar Infra Power Project`,
    icon: {
      _tag: "lib:tabler",
      id: "sun",
    },
    modules: Object.values(SAMPLE_DATA.modules),
    agreementPartys: Object.values(SAMPLE_DATA.parties),
    agreementPartySources: [
      {
        partyId: SAMPLE_DATA.parties.BlackRock.id,
        sourceId: SAMPLE_DATA.sources.CBE.id,
        comment: md`Financial Close document`,
      },
      {
        partyId: SAMPLE_DATA.parties.Phosphor.id,
        sourceId: SAMPLE_DATA.sources.LeaseAgreement.id,
        comment: md`Lease Agreement`,
      },
      {
        partyId: SAMPLE_DATA.parties.SolarCo.id,
        sourceId: SAMPLE_DATA.sources.FinancialClose.id,
        comment: md`Financial Close document`,
      },
    ],
    assumptionSources: [
      {
        sourceId: SAMPLE_DATA.sources.CBE.id,
        assumptionId: SAMPLE_DATA.assumptions.FinancialClose.id,
        linkedBy: SAMPLE_DATA.users.JohnMarciano.id,
        linkedAt: new Date("2024-03-01T10:00:00Z"),
      },
      {
        sourceId: SAMPLE_DATA.sources.CBE.id,
        assumptionId: SAMPLE_DATA.assumptions.ConstructionStart.id,
        linkedBy: SAMPLE_DATA.users.AvaSmith.id,
        linkedAt: new Date("2024-03-02T14:30:00Z"),
      },
    ],
    assumptions: [
      {
        ...SAMPLE_DATA.assumptions.FinancialClose,
        moduleId: SAMPLE_DATA.modules.Timing.id,
        value: "September 01, 2024",
        format: "Date",
        unit: "",
        editorComments: md`Based on project schedule, adjusted to mode...`,
        lastChangedBy: SAMPLE_DATA.users.JohnMarciano.id,
        lastChangedAt: new Date("2024-03-01T10:00:00Z"),
      },
      {
        ...SAMPLE_DATA.assumptions.ConstructionStart,
        moduleId: SAMPLE_DATA.modules.Timing.id,
        value: "September 20, 2024",
        format: "Date",
        unit: "",
        editorComments: md`Based on project schedule, adjusted to mode...`,
        lastChangedBy: SAMPLE_DATA.users.AvaSmith.id,
        lastChangedAt: new Date("2024-03-02T14:30:00Z"),
      },
      {
        ...SAMPLE_DATA.assumptions.StartOfOperations,
        moduleId: SAMPLE_DATA.modules.Timing.id,
        value: "August 23, 2024",
        format: "Date",
        unit: "",
        editorComments: md`Assuming 12 month solar construction`,
        lastChangedBy: SAMPLE_DATA.users.JohnMarciano.id,
        lastChangedAt: new Date("2024-03-03T09:15:00Z"),
      },
      {
        ...SAMPLE_DATA.assumptions.SolarPVModules,
        moduleId: SAMPLE_DATA.modules.CapEx.id,
        value: 30,
        format: "Number",
        unit: "c / Wo",
        editorComments: md`30c / Wo module costs, separated out t...`,
        lastChangedBy: SAMPLE_DATA.users.MichaelMiller.id,
        lastChangedAt: new Date("2024-03-04T11:45:00Z"),
      },
      {
        ...SAMPLE_DATA.assumptions.SolarEPCCost,
        moduleId: SAMPLE_DATA.modules.CapEx.id,
        value: 724,
        format: "Number",
        unit: "$ / kWp",
        editorComments: md`Target $724 / kWp soar EPC as messag...`,
        lastChangedBy: SAMPLE_DATA.users.AvaSmith.id,
        lastChangedAt: new Date("2024-03-05T16:20:00Z"),
      },
    ],
    sources: Object.values(SAMPLE_DATA.sources).map((source, index) => ({
      ...source,
      icon: { _tag: "lib:tabler", id: "file-text" },
      mvpDocumentPreview: "GenericPDF",
      uploadedBy: Object.values(SAMPLE_DATA.users)[index % 3].id, // Cycle through the users
    })),
    strategies: [
      {
        ...SAMPLE_DATA.strategies.FinancialHealth,
        description: md`Assessing the overall financial health of the project`,
        icon: { _tag: "lib:tabler", id: "chart-line" },
        statusScaleMethod: "TrafficLights",
        checks: [
          {
            titleDisplay: "Credit Score Check",
            goal: "Score > 700",
            actual: "720",
            status: "Green",
            statusCriteria: [
              { statusDisplay: "Green", criteriaInstruction: md`Score > 700` },
              {
                statusDisplay: "Yellow",
                criteriaInstruction: md`650 < Score <= 700`,
              },
              { statusDisplay: "Red", criteriaInstruction: md`Score <= 650` },
            ],
          },
          {
            titleDisplay: "Debt-to-Income Ratio",
            goal: "< 0.5",
            actual: "0.44",
            status: "Yellow",
            statusCriteria: [
              { statusDisplay: "Green", criteriaInstruction: md`Ratio < 0.4` },
              {
                statusDisplay: "Yellow",
                criteriaInstruction: md`0.4 <= Ratio < 0.5`,
              },
              { statusDisplay: "Red", criteriaInstruction: md`Ratio >= 0.5` },
            ],
          },
        ],
      },
      {
        ...SAMPLE_DATA.strategies.BusinessViability,
        description: md`Evaluating the long-term viability of the business`,
        icon: { _tag: "lib:tabler", id: "building" },
        statusScaleMethod: "TrafficLights",
        checks: [
          {
            titleDisplay: "Years in Operation",
            goal: "> 3 years",
            actual: "5 years",
            status: "Green",
            statusCriteria: [
              { statusDisplay: "Green", criteriaInstruction: md`Years > 3` },
              {
                statusDisplay: "Yellow",
                criteriaInstruction: md`2 < Years <= 3`,
              },
              { statusDisplay: "Red", criteriaInstruction: md`Years <= 2` },
            ],
          },
          {
            titleDisplay: "Revenue Growth Rate",
            goal: "> 10% year-over-year",
            actual: "50% projected",
            status: "Green",
            statusCriteria: [
              { statusDisplay: "Green", criteriaInstruction: md`Growth > 10%` },
              {
                statusDisplay: "Yellow",
                criteriaInstruction: md`5% < Growth <= 10%`,
              },
              { statusDisplay: "Red", criteriaInstruction: md`Growth <= 5%` },
            ],
          },
        ],
      },
      {
        ...SAMPLE_DATA.strategies.CollateralAssessment,
        description: md`Evaluating the collateral provided for the loan`,
        icon: { _tag: "lib:tabler", id: "shield-check" },
        statusScaleMethod: "TrafficLights",
        checks: [
          {
            titleDisplay: "Collateral to Loan Ratio",
            goal: "> 1.25",
            actual: "1.33",
            status: "Green",
            statusCriteria: [
              { statusDisplay: "Green", criteriaInstruction: md`Ratio > 1.25` },
              {
                statusDisplay: "Yellow",
                criteriaInstruction: md`1 < Ratio <= 1.25`,
              },
              { statusDisplay: "Red", criteriaInstruction: md`Ratio <= 1` },
            ],
          },
        ],
      },
    ],
    strategySources: [
      {
        sourceId: SAMPLE_DATA.sources.FinancialClose.id,
        strategyId: SAMPLE_DATA.strategies.BusinessViability.id,
        linkedBy: SAMPLE_DATA.users.JohnMarciano.id,
        linkedAt: new Date("2024-03-06T13:00:00Z"),
      },
    ],
  };
});
