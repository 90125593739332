import { useAtomFromValue } from "@/lib/jotai/useAtomFromValue";
import { deepEqual } from "@tanstack/react-router";
import Fuse from "fuse.js";
import type { IFuseOptions } from "fuse.js";
import { atom, useAtom } from "jotai";
import { selectAtom } from "jotai/utils";
import { useMemo } from "react";

export type FuseSearchHookOptions<T> = Omit<
  IFuseOptions<T>,
  "getFn" | "sortFn" | "keys"
> & {
  keys: Array<NestedKeyOf<T> | { name: NestedKeyOf<T>; weight: number }>;
};

export function useFuseSearch<T>(
  config: {
    items: readonly T[] | (() => readonly T[]);
    search: string | (() => string);
  },
  options?: FuseSearchHookOptions<T>,
) {
  const itemsAtom = useAtomFromValue(
    typeof config.items === "function" ? config.items() : config.items,
    deepEqual,
  );
  const searchAtom = useAtomFromValue(
    typeof config.search === "function" ? config.search() : config.search,
  );

  const fuseAtom = useMemo(
    () => selectAtom(itemsAtom, (items) => new Fuse(items, options)),
    [itemsAtom, options],
  );

  const filteredAtom = useMemo(
    () =>
      atom((get) => {
        const searchValue = get(searchAtom);
        if (searchValue) {
          return get(fuseAtom)
            .search(searchValue)
            .map((result) => ({ ...result.item }));
        }
        return get(itemsAtom);
      }),
    [fuseAtom, searchAtom, itemsAtom],
  );

  const [filtered] = useAtom(filteredAtom);

  return filtered;
}

type NestedKeyOf<ObjectType> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends Array<
    infer ArrayType
  >
    ?
        | `${Key}.${number}`
        | `${Key}`
        | (ArrayType extends object
            ? `${Key}.${number}.${NestedKeyOf<ArrayType>}`
            : never)
    : ObjectType[Key] extends object
      ? `${Key}.${NestedKeyOf<ObjectType[Key]>}` | `${Key}`
      : `${Key}`;
}[keyof ObjectType & (string | number)];
