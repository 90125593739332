export const SAMPLE_PROJECT_CO = (
  <div $="bg-white mx-auto">
    {/* <h2 $="text-xl font-bold mb-1 text-gray-800">ProjectCo</h2> */}
    <h3 $="text-lg font-semibold mb-2 text-gray-700">... / Timing</h3>

    <div $="space-y-1 ml-2">
      <div $="text-gray-600 text-md font-semibold">Dates ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-gray-800">
          DATERANGE ( DATE ( 2023 , 1 , 31 ) , 360 , 'M' )
        </span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Calendar month ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">MONTH (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Calendar year ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">YEAR (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Days in month ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">DAY (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">
        Start of commercial ops ⋯
      </div>
      <div $=" flex flex-wrap items-center rounded p-1">
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯{" "}
        </span>
        <span $="text-black">= EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">, 0 )</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Project end of life ⋯</div>
      <div $=" flex flex-wrap items-center rounded p-1">
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯{" "}
        </span>
        <span $="text-black">= EOMONTH (</span>

        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50  "
          children="Periods per year"
        />
        <span $="text-black">*</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50 "
          children="Useful life"
        />
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Operations Active ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">AND (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">&gt;= EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">, 0 ) ,</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">&lt;= EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Useful life"
        />
        <span $="text-black">*</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Periods per year"
        />
        <span $="text-black">)</span>
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Distribution period ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">OR (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Calendar month"
        />
        <span $="text-black">= 3 ,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Calendar month"
        />
        <span $="text-black">= 6 ,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Calendar month"
        />
        <span $="text-black">= 9 ,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Calendar month"
        />
        <span $="text-black">= 12 ) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">
        Start of Operating Year ⋯
      </div>
      <div $="flex flex-wrap items-center rounded">
        <span $="tex-black">( MONTH (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">) = MONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">) ) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">End of Operating Yr ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">( MONTH (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">) = MONTH ( EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">, -1 ) ) ) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Operating Period ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">SUM (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Start of Operating Year"
        />
        <span $="text-black">:</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="End of Operating Yr"
        />
        <span $="text-black">) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Operating Year ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">SUM (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Start of Operating Year"
        />
        <span $="text-blue-600">:</span>
        <span
          $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1"
          children="Dates ⋯"
        />
        <span $="text-black">) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Start or End of Life ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">OR (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Start of commercial ops"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Project end of life"
        />
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">NTP Period ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">(</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">&lt;= EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Notice to proceed"
        />
        <span $="text-black">, 0 ) ) * -1</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">
        Mechanical completion period ⋯
      </div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">&lt;=</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="MC milestone date"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">
        Substantial completion period ⋯
      </div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-black">&lt;=</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="SC milestone date"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Post NTP Flag ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">SUM (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="NTP Period"
        />
        <span $="text-black">:</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Dates ..."
        />
        <span $="text-black">) &gt; 0</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Periods from NTP ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">SUM (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Post NTP Flag"
        />
        <span $="text-black">:</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Dates ..."
        />
        <span $="text-black">)</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Days to COD month ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">(</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Days to commercial ops"
        />
        <span $="text-black">- DAY (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">) ) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Start of commercial ops"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Days in COD month ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">( DAY ( EOMONTH (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Periods per year"
        />
        <span $="text-black">*</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Useful life"
        />
        <span $="text-black">) ) - DAY (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Commercial ops date"
        />
        <span $="text-black">) + 1 ) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Days in COD month"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">Escalation Year ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">MAX ( 0 ,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operating Year"
        />
        <span $="text-black">- 1 )</span>
      </div>
      <div $=" text-gray-600 text-md font-semibold">Project availability ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Days in month"
        />
        <span $="text-black">* DAY (</span>
        <span $="text-pink-500 bg-pink-50 border border-gray-300 rounded px-1 mx-1">
          Dates ⋯
        </span>
        <span $="text-blue-600">) *</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
      </div>
      <div $=" text-gray-600 text-md font-semibold">PPA/Tariff active ⋯</div>
      <div $="flex flex-wrap items-center rounded">
        <span $="text-black">AND (</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="PPA term in"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operating Year"
        />
        <span $="text-black">,</span>
        <span
          $="border border-gray-300 px-1 py-0 mx-1 rounded text-blue-600 bg-blue-50"
          children="Operations Active"
        />
        <span $="text-black">)</span>
      </div>
    </div>

    <h3 $="text-lg font-semibold mt-4 mb-2 text-gray-700 flex items-center gap-0 ">
      <span $="text-grey-100 text-sm tracking-widest">.../.../</span> Constants
    </h3>
    <h3 $="text-lg font-semibold mt-2 mb-2 text-gray-700 flex items-center gap-0 ">
      <span $="text-grey-100 text-sm tracking-widest">.../.../</span> Dates
    </h3>
    <h3 $="text-xl font-semibold mt-2 mb-2 text-gray-700 flex items-center gap-0 ">
      <span $="text-grey-100 text-md tracking-widest">.../</span> Operations
    </h3>
    {/* Add constants section here if needed */}
  </div>
);
