import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useCurrentOrg } from "@/contexts/current-org";
import { SAMPLE_DATA } from "@/contexts/sample-workspace-data";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { type OrgUserID, UserAPI } from "@phosphor/server";
import { Link } from "@tanstack/react-router";
import type React from "react";
import { forwardRef } from "react";

interface UserNameChipProps {
  userId: OrgUserID;
  /** e.g. role, email, etc. based on context */
  subtext?: string;
  /** e.g. expanded details shown on hover */
  detail?: string;
  onlyAvatar?: boolean;
}

export const UserNameChip: React.FC<UserNameChipProps> = ({
  userId,
  subtext,
  detail,
  onlyAvatar,
}) => {
  const { org, user } = useCurrentOrg();
  const isCurrentUser = userId === user.id;

  // Check if the userId is from sample data
  const sampleUser = Object.values(SAMPLE_DATA.users).find(
    (user) => user.id === userId,
  );

  const {
    data: userProfile,
    isLoading,
    error,
  } = useRpcQuery({
    queryKey: ["user-profile", userId],
    orgID: org.id,
    request: new UserAPI.GetUserProfile({
      userID: userId,
    }),
    enabled: !sampleUser, // Only query if it's not sample data
  });

  const displayData = sampleUser || userProfile;

  if (isLoading) {
    return (
      <span $="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs">
        Loading...
      </span>
    );
  }

  if (error || !displayData) {
    return (
      <span $="inline-block bg-red-200 rounded-full px-2 py-1 text-xs">
        Unknown User
      </span>
    );
  }

  const content = (
    <div $="flex gap-2 pl-1 pr-2 py-1">
      <UserAvatar
        $="w-8 h-8"
        profileName={displayData.profileName}
        photoURL={displayData.photoURL?.toString()}
      />
      {!onlyAvatar && (
        <div $="text-xs cursor-pointer">
          <div $="font-semibold">{displayData.profileName}</div>
          <div $="text-xxs text-neutral-500">
            {capitalize(subtext ?? displayData.role)}
          </div>
        </div>
      )}
    </div>
  );

  const hoverContent = (
    <div $="flex justify-between space-x-4">
      <UserAvatar
        $="flex-0"
        profileName={displayData.profileName}
        photoURL={displayData.photoURL?.toString()}
      />
      <div $="space-y-1 flex-grow">
        <h4 $="text-sm font-semibold">{displayData.profileName}</h4>
        <p $="text-sm text-gray-500">@{displayData.displayName}</p>
        <div $="flex items-center pt-2">
          <span $="text-neutral-500 text-xs">
            {capitalize(detail ?? `${displayData.role} of organization.`)}
          </span>
        </div>
        {isCurrentUser && (
          <Link
            to="/$org/settings/profile"
            params={{ org: org.urlKey }}
            $="text-xs text-blue-500 hover:underline mt-2 block"
          >
            Edit Profile
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <HoverCard>
      <HoverCardTrigger $>{content}</HoverCardTrigger>
      <HoverCardContent $="w-80">{hoverContent}</HoverCardContent>
    </HoverCard>
  );
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

interface UserAvatarProps
  extends React.ComponentPropsWithoutRef<typeof Avatar> {
  profileName: string;
  photoURL?: string;
}

export const UserAvatar = forwardRef<
  React.ElementRef<typeof Avatar>,
  UserAvatarProps
>(({ profileName, photoURL, ...props }, ref) => {
  return (
    <Avatar ref={ref} {...props}>
      <AvatarImage $ src={photoURL} alt={profileName} />
      <AvatarFallback $="select-none">{profileName.charAt(0)}</AvatarFallback>
    </Avatar>
  );
});

UserAvatar.displayName = "UserAvatar";
