import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  MOCK_FINANCIAL_MODELS,
  type MockFinancialModel,
} from "./MOCK_FINANCIAL_MODELS";

export const useGetModelMVPForDashboard = () =>
  useQuery({
    queryKey: ["CURRENT_MODEL"],
    async queryFn() {
      return MOCK_FINANCIAL_MODELS[0];
    },
  });

export const useGetSelectedModelMVPForInbox = () => {
  const queryClient = useQueryClient();
  const getSelectedModel = async (
    name: string,
  ): Promise<MockFinancialModel | undefined> => {
    const response = MOCK_FINANCIAL_MODELS.find(
      (financial) => financial.name === name,
    );
    return response;
  };
  return useMutation({
    mutationFn: getSelectedModel,
    onSuccess(data) {
      queryClient.setQueryData(["CURRENT_MODEL"], data);
    },
  });
};
