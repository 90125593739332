import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { SAMPLE_SOLAR } from "@/contexts/sample-workspace-data";
import type { WorkspaceAssumptionID } from "@phosphor/server";
import moment from "moment";
import type React from "react";
import { UserNameChip } from "./UserNameChip";

interface LinkedAssumptionChipProps {
  assumptionId: WorkspaceAssumptionID;
}

export const LinkedAssumptionChip: React.FC<LinkedAssumptionChipProps> = ({
  assumptionId,
}) => {
  const sampleAssumption = SAMPLE_SOLAR.assumptions.find(
    (assumption) => assumption.id === assumptionId,
  );

  const displayData = sampleAssumption ?? {
    displayLabel: "Unknown Assumption",
    value: "",
    format: "",
    unit: "",
    editorComments: "",
    lastChangedBy: undefined,
    lastChangedAt: undefined,
  };

  const module = SAMPLE_SOLAR.modules.find(
    (module) => module.id === sampleAssumption?.moduleId,
  );

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center text-purple-600 px-2 py-1 text-xs cursor-pointer underline">
          {displayData.displayLabel}
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-80">
        <div $="space-y-2">
          <h4 $="text-sm font-semibold">{displayData.displayLabel}</h4>
          <p $="text-xs text-gray-500">
            Module: {module?.displayName ?? "Unknown"}
          </p>
          <p $="text-xs">
            Value: {displayData.value} {displayData.unit}
          </p>
          <p $="text-xs">Format: {displayData.format}</p>
          <p $="text-xs">Comments: {displayData.editorComments}</p>
          {displayData.lastChangedBy && (
            <div $="text-xs">
              Last changed by:{" "}
              <UserNameChip userId={displayData.lastChangedBy} />
            </div>
          )}
          {displayData.lastChangedAt && (
            <p $="text-xs">
              Last changed at:{" "}
              {moment(displayData.lastChangedAt).format("MMMM DD, YYYY HH:mm")}
            </p>
          )}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
