import { DevError } from "@/lib/js/DevError";
import { call } from "@phosphor/prelude";
import { createStore } from "jotai";

/** @internal */
export const appStore = call(() => {
  const store = createStore();
  DevError.jotaiGetter = store.get.bind(store);
  return store;
});
