
import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/$org/_layout/inbox/_layout")({
  component: InboxLayout,
});

function InboxLayout() {
  return (
    <Outlet/>
  );
}