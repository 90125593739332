import { Dashboard } from "@/components/Dashboard";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Lu, Ta } from "@/components/ui/icons";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useGetSelectedModelMVPForInbox } from "@/hooks/use-mock-models";
import { cn } from "@/lib/utils";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import phosphorLogoVertSvg from "../../public/phosphor-logo-vert.svg?url";

export const Route = createFileRoute("/$org/_layout/inbox/_layout/")({
  component: InboxIndex,
});

function InboxIndex() {
  const activities = [
    {
      id: 1,
      title: "Updated formulas and description",
      description: "Dantley merged your proposal into Solar Model",
      user: "Dantley Mavis",
      model: "Solar Model Forecast",
      name: "Sponsor Returns", // Added name parameter
      time: "2 hours ago",
      bgColor: "bg-gray-100",
    },
    {
      id: 2,
      title: "Your Solar Model Proposal Merged",
      description: "Dantley merged your proposal into Solar Model",
      user: "Dantley Mavis",
      model: "Solar Model Forecast",
      name: "Sources of Funds", // Added name parameter
      time: "2 hours ago",
      bgColor: "bg-white",
    },
    {
      id: 3,
      title: "New Proposal Comment",
      description:
        "Jane mentioned you in a comment for her proposal to Unlevered IRR",
      user: "Jane Smith",
      model: "Solar Model Forecast",
      name: "Operating Metrics", // Added name parameter
      time: "5 hours ago",
      bgColor: "bg-white",
    },
    {
      id: 4,
      title: "Merge Request",
      description:
        "Connie wants you to review a merge request for the SBAM Model.",
      user: "Connie Shi",
      model: "Solar Model Forecast",
      name: "Financial Ratios", // Added name parameter
      time: "1 day ago",
      bgColor: "bg-white",
    },
    {
      id: 5,
      title: "Shimmer Session Summary",
      description:
        "Review the summary and output of your chat with Shimmer on 9/24",
      user: "Shimmer",
      model: "",
      name: "Revenue Projections", // Added name parameter
      time: "1 day ago",
      bgColor: "bg-white",
    },
  ];

  const [activeActivityId, setActiveActivityId] = useState<number | null>(null);
  const { mutate: getSelectedModel } = useGetSelectedModelMVPForInbox();

  const handleActivityClick = (activity: (typeof activities)[0]) => {
    setActiveActivityId(activity.id);
    if (activity.name) {
      getSelectedModel(activity.name);
    }
  };

  return (
    <div $="flex-1 h-full">
      <div $="grid grid-cols-3 h-full">
        {/* Activity Feed */}
        <div $="col-span-1 w-full mx-auto bg-white border-r border-gray-300">
          <div $="flex flex-row items-center justify-between space-y-0 pb-1 px-4 pt-4">
            <div $="text-2xl font-semibold">5 items</div>
            <div $="inline-flex bg-white p-0 rounded-full border border-gray-200 box-content">
              <Button
                variant="ghost"
                size="icon"
                $="w-full h-full px-3 py-1 flex items-center rounded-l-full"
              >
                <Ta.IconMailOpened $="h-5 w-5 stroke-[1.25]" />
                <span $="sr-only">Email</span>
              </Button>
              <div $="w-px bg-[#D4D4D8]"></div>
              <Button
                variant="ghost"
                size="icon"
                $=" w-full h-full px-3 py-1 flex items-center rounded-r-full"
              >
                <Ta.IconAdjustments $="h-5 w-5 stroke-[1.25]" />
                <span $="sr-only">More options</span>
              </Button>
            </div>
          </div>
          <div $="p-4">
            <ScrollArea $="h-auto">
              <RadioGroup
                $="gap-0 p-1"
                value={activeActivityId?.toString()}
                onValueChange={(value) => setActiveActivityId(Number(value))}
              >
                {activities.map((activity) => (
                  <div
                    key={activity.id}
                    $={cn(
                      "mb-2 rounded-lg p-3 cursor-pointer border",
                      activeActivityId === activity.id
                        ? "bg-[#BCB0DC]/20 shadow-sm border-[#BCB0DC]"
                        : "bg-white border-gray-200",
                    )}
                    onClick={() => handleActivityClick(activity)}
                    role="button"
                    tabIndex={0}
                  >
                    <div $="flex items-start justify-between mb-1">
                      <div $="flex items-center space-x-3">
                        <RadioGroupItem
                          value={activity.id.toString()}
                          id={`radio-${activity.id}`}
                          $="w-5 h-5 border-2 border-[#766293] text-[#766293] focus:ring-[#766293] focus:ring-offset-2"
                        />
                        <h3 $="text-base font-semibold">{activity.title}</h3>
                      </div>
                      <span $="text-xs/6 text-neutral-500 whitespace-nowrap">
                        {activity.time}
                      </span>
                    </div>
                    <p $="text-black mb-2 text-sm">{activity.description}</p>
                    <div $="flex items-center space-x-2 text-xs text-gray-500">
                      <Avatar $="h-5 w-5">
                        <AvatarFallback $="bg-gray-300 text-xs">
                          {activity.user[0]}
                        </AvatarFallback>
                      </Avatar>
                      <span $>{activity.user}</span>
                      {activity.model && (
                        <>
                          <span $>•</span>
                          <span $="flex items-center">
                            <Ta.IconTableShare $="h-4 w-4 mr-1" />
                            {activity.model}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </RadioGroup>
            </ScrollArea>
          </div>
        </div>

        {!activeActivityId && (
          <div $="col-span-2 w-full flex justify-center items-center">
            <div $="flex flex-col pb-[40%] items-center">
              <img $ src={phosphorLogoVertSvg} />
            </div>
          </div>
        )}
        {/* Project Details */}
        {activeActivityId && (
          <Card $="col-span-2 w-full mx-auto z-0 rounded-none border-none">
            <CardContent $="p-6">
              <div $="space-y-6">
                <div $>
                  <h1 $="text-2xl font-semibold mb-1">
                    Solar Infra Power Project
                  </h1>
                  <p $="text-xs text-gray-500 tracking-widest">
                    LAST UPDATED THU MAY 23 2024
                  </p>
                </div>

                <div $="space-y-6">
                  <div $="flex gap-7 items-center">
                    <h2 $="text-xs text-gray-500 tracking-widest">LEAD</h2>
                    <div $="flex items-center mt-1">
                      <Avatar $="h-8 w-8">
                        <AvatarImage
                          $
                          src="/placeholder.svg?height=32&width=32"
                        />
                        <AvatarFallback $>JM</AvatarFallback>
                      </Avatar>
                      <div $="ml-2">
                        <p $="text-xs font-semibold">John Marciano</p>
                        <p $="text-xs font-light text-gray-500">
                          Tax Equity Lead at Black Sabbath
                        </p>
                      </div>
                    </div>
                  </div>

                  <div $="border-b pb-5">
                    <h2 $="text-xs text-gray-500 tracking-widest uppercase">
                      Models, Databases, Memo&apos;s
                    </h2>
                    <div $="flex space-x-2 mt-2">
                      <div $="rounded-sm px-2 flex items-center gap-1 bg-[#8F849E]/15 p-1">
                        <Lu.Table2 $="h-4 w-4 mr-2" />
                        <span $="text-xs text-zinc-500 ">
                          Solar Model Forecast
                        </span>
                      </div>
                      <div $="rounded-sm px-2 flex items-center gap-1 bg-[#8F849E]/15 p-1">
                        <Lu.Table2 $="h-4 w-4 mr-1" />
                        <span $="text-xs text-zinc-500">CBAM Model 2024</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div $="space-y-2">
                  <p $="text-sm">
                    Wizard Capital intends to acquire a portfolio of{" "}
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Property Count: 10
                    </span>
                  </p>
                  <p $="text-sm">
                    multi-family properties comprising of{" "}
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Total Sqft: 250,000
                    </span>
                  </p>
                  <p $="text-sm">
                    from Rivendell Development, LLC, an Austin-based real estate
                    developer, for a total asset value of
                  </p>
                  <p $="text-sm">
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Acquisition Price: $100,000,000 m
                    </span>
                    .
                  </p>
                </div>

                <div $="space-y-2">
                  <p $="text-sm">
                    The expected project{" "}
                    <span $="bg-[#D5E9FC] text-primary px-1 rounded p-1">
                      unlevered return
                    </span>{" "}
                    is expected to be an{" "}
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Unlevered IRR: 15.25%
                    </span>{" "}
                    and a
                  </p>
                  <p $="text-sm">
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Unlevered MOIC: 4.87
                    </span>{" "}
                    x MOIC over an expected holding period of{" "}
                    <span $="bg-[#E9E9FE] text-primary px-1 rounded p-1">
                      Hold Yrs: 4
                    </span>
                    .
                  </p>
                </div>
                <Dashboard />
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
