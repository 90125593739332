import { type Atom, useAtomValue } from "jotai";
import { type Truthy, isTruthy } from "../js/isTruthy.js";

export function IfAtom<T>(props: {
  atom: Atom<T>;
  children: React.ReactNode | ((truthy: Truthy<Awaited<T>>) => React.ReactNode);
}) {
  const test = useAtomValue(props.atom);
  return (
    <>
      {isTruthy(test) &&
        (typeof props.children === "function"
          ? props.children(test)
          : props.children)}
    </>
  );
}
