import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { cn } from "@/lib/utils";
import {
  type OrgFolderID,
  type OrgID,
  type OrgURLKey,
  WorkspaceAPI,
  type WorkspaceID,
} from "@phosphor/server";
import { useNavigate } from "@tanstack/react-router";
import {
  type SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { CreateWorkspaceButton } from "./Globals/CreateWorkspaceButton";
import { UserNameChip } from "./UserNameChip";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Ta } from "./ui/icons";
import { Label } from "./ui/label";
import { Spinner } from "./ui/spinner";
import { Switch } from "./ui/switch";

interface WorkspaceListProps {
  orgID: OrgID;
  orgURLKey: OrgURLKey;
  folder?: OrgFolderID;
}

const columnHelper = createColumnHelper<WorkspaceAPI.WorkspaceWithUsers>();

export const WorkspaceList = ({
  orgID,
  orgURLKey,
  folder,
}: WorkspaceListProps) => {
  const navigate = useNavigate();
  const [includeArchived, setIncludeArchived] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "updatedAt", desc: true },
  ]);

  const {
    data: workspaces,
    isLoading,
    refetch,
  } = useRpcQuery({
    orgID,
    queryKey: ["getWorkspaces", folder, includeArchived],
    request: new WorkspaceAPI.GetWorkspaces({ folder, includeArchived }),
  });

  const archiveWorkspaceMutation = useRpcMutation({
    orgID,
    mutate: (input: WorkspaceAPI.ArchiveWorkspace) => input,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.error("Failed to archive workspace:", error);
    },
  });

  const unarchiveWorkspaceMutation = useRpcMutation({
    orgID,
    mutate: (input: WorkspaceAPI.UnarchiveWorkspace) => input,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.error("Failed to unarchive workspace:", error);
    },
  });

  const handleArchiveWorkspace = (workspaceId: WorkspaceID) => {
    archiveWorkspaceMutation.mutate(
      new WorkspaceAPI.ArchiveWorkspace({ workspaceId }),
    );
  };

  const handleUnarchiveWorkspace = (workspaceId: WorkspaceID) => {
    unarchiveWorkspaceMutation.mutate(
      new WorkspaceAPI.UnarchiveWorkspace({ workspaceId }),
    );
  };

  const columns = [
    columnHelper.accessor("workspaceDisplayName", {
      header: "Name",
      cell: (info) => (
        <div
          $="px-4 py-2 flex items-center text-black font-medium text-[14px] cursor-pointer"
          onClick={() => {
            if (!info.row.original.isArchived) {
              navigate({
                to: "/$org/workspace/$workspace",
                params: {
                  org: orgURLKey,
                  workspace: info.row.original.workspaceId,
                },
              });
            }
          }}
        >
          <Ta.IconFileStack
            className="w-7 h-7 mr-1"
            color="black"
            strokeWidth={1}
          />
          {info.getValue()}
          {info.row.original.isArchived && (
            <span $="ml-2 text-xs text-gray-500">(Archived)</span>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("users", {
      header: "Editors",
      cell: (info) => (
        <div $="flex -space-x-2">
          {info.getValue().map((a) => (
            <UserNameChip
              key={a.id + a.role}
              onlyAvatar
              userId={a.id}
              subtext={a.role}
              detail={`${a.role} of workspace`}
            />
          ))}
        </div>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      header: "Last Updated",
      cell: (info) => (
        <div $="px-4 py-2 text-black text-[14px] font-normal">
          {info.getValue()
            ? moment(info.getValue()).fromNow()
            : "Not available"}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: (info) => (
        <div $="px-4 py-2 flex items-center justify-end">
          <div $="flex flex-wrap gap-1">
            {info.row.original.mockTags.map((tag: string, index: number) => (
              <span
                key={index}
                $="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
              >
                {tag}
              </span>
            ))}
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger $ asChild>
              <Button $ variant="ghost" size="sm">
                <Ta.IconDotsVertical $="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent $>
              <CreateWorkspaceButton
                selectedFolder={folder}
                editing={{
                  workspaceId: info.row.original.workspaceId,
                  initialName: info.row.original.workspaceDisplayName,
                  tags: info.row.original.mockTags.join(","),
                }}
                button={
                  <DropdownMenuItem $ onSelect={(e) => e.preventDefault()}>
                    Update
                  </DropdownMenuItem>
                }
              />
              {info.row.original.isArchived ? (
                <DropdownMenuItem
                  $
                  onClick={() =>
                    handleUnarchiveWorkspace(info.row.original.workspaceId)
                  }
                >
                  Unarchive
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem
                  $
                  onClick={() =>
                    handleArchiveWorkspace(info.row.original.workspaceId)
                  }
                >
                  Archive
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
    }),
  ];

  const workspacesData = useMemo(
    () => (workspaces ? [...workspaces] : []),
    [workspaces],
  );
  const table = useReactTable({
    data: workspacesData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div $="w-full">
      <div $="flex justify-between items-center mb-4">
        <h2 $="text-2xl font-bold">Workspaces</h2>
        <div $="flex items-center space-x-2">
          <Switch
            $
            id="include-archived"
            checked={includeArchived}
            onCheckedChange={setIncludeArchived}
          />
          <Label $ htmlFor="include-archived">
            Include Archived
          </Label>
        </div>
      </div>
      <table $="min-w-full bg-background border border-[#D4D4D8]">
        <thead $>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              $="bg-neutral-50 border border-neutral-200"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  $={cn(
                    "px-4 py-3 text-sm font-semibold text-neutral-800 text-left cursor-pointer select-none",
                    header.column.getIsSorted() && "bg-neutral-100",
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div $="flex items-center justify-between">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {{
                      asc: <Ta.IconArrowUp $="w-4 h-4 ml-1" />,
                      desc: <Ta.IconArrowDown $="w-4 h-4 ml-1" />,
                    }[header.column.getIsSorted() as string] ?? (
                      <div $="w-4 h-4 ml-1" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody $>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              $={`px-4 py-2 border-b border-b-[#D4D4D8] ${
                row.original.isArchived ? "bg-gray-100" : ""
              }`}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} $="px-4 py-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && (
        <div $="w-full p-8 flex flex-col justify-center items-center">
          <Spinner $ />
        </div>
      )}
    </div>
  );
};
