import { AppFailureDisplay } from "@/components/AppFailureDisplay";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { useCurrentOrg } from "@/contexts/current-org";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import {
  GroceryListProposalAPI,
  type GroceryListProposalID,
  type ProposalStatus,
} from "@phosphor/server";
import type React from "react";
import { useState } from "react";
import { GLProposalComment } from "./GLProposalComment";
import { GLProposalCommentForm } from "./GLProposalCommentForm";

interface GroceryListProposalDetailsProps {
  proposalId: GroceryListProposalID;
}

export const GroceryListProposalDetails: React.FC<
  GroceryListProposalDetailsProps
> = ({ proposalId }) => {
  const { org } = useCurrentOrg();
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");

  const { data, isLoading, error, refetch } = useRpcQuery({
    queryKey: ["proposal-details", proposalId],
    orgID: org.id,
    request: new GroceryListProposalAPI.GetProposal({
      proposalId,
    }),
  });

  const updateProposalMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.UpdateProposal) => input,
    onSuccess: () => {
      toast({
        title: "Proposal Updated",
        description: "Your changes have been saved successfully.",
      });
      setIsEditing(false);
      refetch();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update the proposal. Please try again.",
        variant: "destructive",
      });
      console.error("Error updating proposal:", error);
    },
  });

  const { toggleProposal } = useGroceryList();

  const mergeMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.MergeProposal) => input,
    onSuccess: (result) => {
      toggleProposal(result.proposalId);
      toast({
        title: "Proposal Merged",
        description: "The proposal has been successfully merged.",
      });
      refetch();
    },
    onError: (error) => {
      console.error("Error merging proposal:", error);
      toast({
        title: "Error",
        description: "Failed to merge the proposal. Please try again.",
        variant: "destructive",
      });
    },
  });

  const approveMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.ApproveProposal) => input,
    onSuccess: () => {
      toast({
        title: "Proposal Approved",
        description: "The proposal has been approved.",
      });
      refetch();
    },
    onError: (error) => {
      console.error("Error approving proposal:", error);
      toast({
        title: "Error",
        description: "Failed to approve the proposal. Please try again.",
        variant: "destructive",
      });
    },
  });

  const rejectMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.RejectProposal) => input,
    onSuccess: () => {
      toast({
        title: "Proposal Rejected",
        description: "The proposal has been rejected.",
      });
      refetch();
    },
    onError: (error) => {
      console.error("Error rejecting proposal:", error);
      toast({
        title: "Error",
        description: "Failed to reject the proposal. Please try again.",
        variant: "destructive",
      });
    },
  });

  const openMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.OpenProposal) => input,
    onSuccess: () => {
      toast({
        title: "Proposal Opened",
        description: "The proposal has been opened for review.",
      });
      refetch();
    },
    onError: (error) => {
      console.error("Error opening proposal:", error);
      toast({
        title: "Error",
        description: "Failed to open the proposal. Please try again.",
        variant: "destructive",
      });
    },
  });

  if (isLoading) {
    return <div $="p-4">Loading proposal details...</div>;
  }

  if (error) {
    return <AppFailureDisplay $ error={error} />;
  }

  if (!data) {
    return <div $="p-4">No proposal data found.</div>;
  }

  const { proposal, comments } = data;

  const handleEditClick = () => {
    setEditTitle(proposal.title);
    setEditDescription(proposal.description);
    setIsEditing(true);
  };

  const handleSave = () => {
    updateProposalMutation.mutate(
      new GroceryListProposalAPI.UpdateProposal({
        proposalId,
        title: editTitle,
        description: editDescription,
      }),
    );
  };

  const handleMergeProposal = (proposalId: GroceryListProposalID) => {
    mergeMutation.mutate(
      new GroceryListProposalAPI.MergeProposal({
        proposalId,
      }),
    );
  };

  const handleApproveProposal = (proposalId: GroceryListProposalID) => {
    approveMutation.mutate(
      new GroceryListProposalAPI.ApproveProposal({
        proposalId,
      }),
    );
  };

  const handleRejectProposal = (proposalId: GroceryListProposalID) => {
    rejectMutation.mutate(
      new GroceryListProposalAPI.RejectProposal({
        proposalId,
      }),
    );
  };

  const handleOpenProposal = (proposalId: GroceryListProposalID) => {
    openMutation.mutate(
      new GroceryListProposalAPI.OpenProposal({
        proposalId,
      }),
    );
  };

  const handleDiscard = () => {
    setIsEditing(false);
  };

  const getStatusBadge = (status: ProposalStatus) => {
    const statusColors = {
      draft: "bg-gray-200 text-gray-800",
      open: "bg-blue-200 text-blue-800",
      approved: "bg-green-200 text-green-800",
      rejected: "bg-red-200 text-red-800",
      merged: "bg-purple-200 text-purple-800",
    } as const;

    return (
      <Badge
        $
        variant="outline"
        className={statusColors[status as keyof typeof statusColors]}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  return (
    <div $="bg-white dark:bg-gray-800 rounded-lg shadow p-4 mb-4 max-w-2xl">
      {isEditing ? (
        <div $="space-y-4">
          <Input
            $
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            placeholder="Proposal Title"
          />
          <Textarea
            $
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            placeholder="Proposal Description"
            rows={3}
          />
          <div $="flex justify-end space-x-2">
            <Button $ variant="outline" onClick={handleDiscard}>
              Discard
            </Button>
            <Button $ onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div $="flex items-center justify-between mb-2">
            <div $="flex items-center space-x-2">
              <h3 $="text-lg font-semibold">{proposal.title}</h3>
              {getStatusBadge(proposal.status)}
            </div>
            <Button $ variant="ghost" size="icon" onClick={handleEditClick}>
              <Ta.IconPencil $="w-4 h-4" />
            </Button>
          </div>
          <p $="text-sm text-gray-600 dark:text-gray-400 mb-4">
            {proposal.description}
          </p>
          <div $="flex justify-end space-x-2 mb-4">
            {proposal.status === "draft" && (
              <Button
                $
                variant="secondary"
                size="sm"
                onClick={() => handleOpenProposal(proposalId)}
                disabled={openMutation.isPending}
              >
                {openMutation.isPending ? "Opening..." : "Open for Review"}
              </Button>
            )}
            {proposal.status === "open" && (
              <>
                <Button
                  $
                  variant="outline"
                  size="sm"
                  onClick={() => handleRejectProposal(proposalId)}
                  disabled={rejectMutation.isPending}
                >
                  {rejectMutation.isPending ? "Rejecting..." : "Reject"}
                </Button>
                <Button
                  $
                  variant="secondary"
                  size="sm"
                  onClick={() => handleApproveProposal(proposalId)}
                  disabled={approveMutation.isPending}
                >
                  {approveMutation.isPending ? "Approving..." : "Approve"}
                </Button>
              </>
            )}
            {proposal.status === "approved" && (
              <Button
                $
                variant="secondary"
                size="sm"
                onClick={() => handleMergeProposal(proposalId)}
                disabled={mergeMutation.isPending}
              >
                {mergeMutation.isPending ? "Merging..." : "Merge Proposal"}
              </Button>
            )}
          </div>
        </>
      )}
      <div $="space-y-6">
        {comments.map((comment) => (
          <GLProposalComment
            key={comment.ts.getTime()}
            proposalId={proposalId}
            comment={comment}
            onRefetch={refetch}
          />
        ))}
      </div>

      {/* New comment form */}
      <div $="mt-6">
        <GLProposalCommentForm proposalId={proposalId} onRefetch={refetch} />
      </div>
    </div>
  );
};
