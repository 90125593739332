import { cn } from "@/lib/utils";
import * as React from "react";

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: "sm" | "md" | "lg";
};

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  ({ className, size = "md", ...props }, ref) => {
    const sizeClasses = {
      sm: "w-4 h-4 border",
      md: "w-8 h-8 border",
      lg: "w-12 h-12 border",
    };

    return (
      <div
        ref={ref}
        className={cn(
          "animate-spin rounded-full border-t-primary",
          "border-r-primary/30 border-b-primary/30 border-l-primary/30",
          sizeClasses[size],
          className,
        )}
        {...props}
      />
    );
  },
);

Spinner.displayName = "Spinner";

export { Spinner };
