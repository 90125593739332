import { Button } from "@/components/ui/button";
import { Lu } from "@/components/ui/icons";
import { useState } from "react";

interface MockExpandedSections {
  projectOperations: boolean;
  capExDuringOperations: boolean;
  revenue: boolean;
  rentalRevenue: boolean;
  otherRevenue: boolean;
}

export const MockImportLibraryItemSectioned = () => {
  const [expandedSections, setExpandedSections] =
    useState<MockExpandedSections>({
      projectOperations: true,
      capExDuringOperations: true,
      revenue: true,
      rentalRevenue: true,
      otherRevenue: false,
    });

  type SectionKey = keyof MockExpandedSections;

  const toggleSection = (section: SectionKey) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <div $>
      <Button
        variant="ghost"
        $="w-full justify-start p-2 font-semibold border border-grey-200 mb-2 bg-[#F1F5F9]"
        onClick={() => toggleSection("projectOperations")}
      >
        {expandedSections.projectOperations ? (
          <Lu.ChevronDown $="mr-2" />
        ) : (
          <Lu.ChevronRight $="mr-2" />
        )}
        Project Operations
      </Button>

      {expandedSections.projectOperations && (
        <div $>
          <div $>
            <Button
              variant="ghost"
              $="w-[93%] justify-start p-2 ml-12 font-semibold border border-grey-200 mb-2 bg-[#F1F5F9]"
              onClick={() => toggleSection("capExDuringOperations")}
            >
              {expandedSections.capExDuringOperations ? (
                <Lu.ChevronDown $="mr-2" />
              ) : (
                <Lu.ChevronRight $="mr-2" />
              )}
              <span $="font-semibold">CapEx During Operations</span>
            </Button>
            {expandedSections.capExDuringOperations && (
              <div $>
                <div $="flex justify-between items-center py-1 px-2 ml-24 border mb-1 border-grey-200">
                  <span $>other_capex</span>
                  <div $="flex space-x-1">
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                    >
                      kwh_per_panel
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                    >
                      panel_count
                    </Button>
                  </div>
                </div>
                <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1">
                  <span $>capex_rsv</span>
                  <div $="flex space-x-1">
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                    >
                      power_production
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                    >
                      power_rate
                    </Button>
                  </div>
                </div>
                <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1">
                  <span $>total_capex</span>
                  <div $="flex space-x-1">
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                    >
                      power_production
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                    >
                      power_rate
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div $="ml-12">
            <Button
              variant="ghost"
              $="w-full justify-start p-2 font-semibold border border-grey-200 mb-2 bg-[#F1F5F9]"
              onClick={() => toggleSection("revenue")}
            >
              {expandedSections.revenue ? (
                <Lu.ChevronDown $="mr-2" />
              ) : (
                <Lu.ChevronRight $="mr-2" />
              )}
              <span $="font-semibold">Revenue</span>
            </Button>
            {expandedSections.revenue && (
              <div $>
                <div $>
                  <Button
                    variant="ghost"
                    $="w-[93%] justify-start p-2 ml-12 font-semibold border border-grey-200 mb-2 bg-[#F1F5F9]"
                    onClick={() => toggleSection("rentalRevenue")}
                  >
                    {expandedSections.rentalRevenue ? (
                      <Lu.ChevronDown $="mr-2" />
                    ) : (
                      <Lu.ChevronRight $="mr-2" />
                    )}
                    <span $="font-semibold">Rental Revenue</span>
                  </Button>
                  {expandedSections.rentalRevenue && (
                    <div $>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>rent_gross_index</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            kwh_per_panel
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            panel_count
                          </Button>
                        </div>
                      </div>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>downtime_vacancy</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                          >
                            power_production
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            power_rate
                          </Button>
                        </div>
                      </div>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>total_rental_revenue</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                          >
                            power_production
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            power_rate
                          </Button>
                        </div>
                      </div>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>gross_potential_revenue</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                          >
                            power_production
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            power_rate
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div $>
                  <Button
                    variant="ghost"
                    $="w-[93%] justify-start p-2 ml-12 font-semibold border border-grey-200 mb-2 bg-[#F1F5F9]"
                    onClick={() => toggleSection("otherRevenue")}
                  >
                    {expandedSections.otherRevenue ? (
                      <Lu.ChevronDown $="mr-2" />
                    ) : (
                      <Lu.ChevronRight $="mr-2" />
                    )}
                    <span $="font-semibold">Other Revenue</span>
                  </Button>
                  {expandedSections.otherRevenue && (
                    <div $>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>utility_reimbursement</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            kwh_per_panel
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            panel_count
                          </Button>
                        </div>
                      </div>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>storage</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                          >
                            power_production
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            power_rate
                          </Button>
                        </div>
                      </div>
                      <div $="flex justify-between items-center py-1 px-2 ml-24 border border-grey-200 mb-1  ">
                        <span $>parking</span>
                        <div $="flex space-x-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-purple-100 text-purple-700 text-xs px-2 py-1"
                          >
                            power_production
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            $="bg-blue-100 text-blue-700 text-xs px-2 py-1"
                          >
                            power_rate
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
