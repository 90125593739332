type TemplateLike = { readonly raw: readonly string[] };
const INDENTATION_RE = /^\n( +)/;
export const unindentTemplate = (template: TemplateLike): TemplateLike => {
  const indentation = INDENTATION_RE.exec(template.raw[0])?.[1];
  return indentation
    ? { raw: template.raw.map((a) => a.replaceAll(`\n${indentation}`, "\n")) }
    : template;
};
export const unescapeTicksInTemplate = (
  template: TemplateLike,
): TemplateLike => {
  return { raw: template.raw.map((a) => a.replaceAll("\\`", "`")) };
};

/** unindented string template */
export const str = (
  template: TemplateStringsArray,
  ...substitutions: (number | string | false | null | undefined)[]
): string => {
  const subs = substitutions.map((a) => (a === 0 || a ? String(a) : ""));
  return String.raw(
    unescapeTicksInTemplate(unindentTemplate(template)),
    ...subs,
  );
};
