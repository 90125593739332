import { AppFailureDisplay } from "@/components/AppFailureDisplay";
import { GroceryListHistoryList } from "@/components/GroceryList/GroceryListHistoryList";
import { GroceryListProposalDetails } from "@/components/GroceryList/GroceryListProposalDetails";
import { ProposalSelector } from "@/components/GroceryList/ProposalSelector";
import { PickColorModal } from "@/components/PickColorModal";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import {
  type GroceryListItemDisplay,
  type GroceryListNavigationState,
  GroceryListProvider,
  useGroceryList,
} from "@/contexts/GroceryListContext";
import { useNotifications } from "@/hooks/notifications";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { useColorStyles } from "@/hooks/useColorStyles";
import { cn } from "@/lib/utils";
import { S } from "@phosphor/prelude";
import {
  type Color,
  GroceryListAPI,
  GroceryListChangeKind,
  GroceryListID,
  GroceryListProposalAPI,
  GroceryListProposalID,
} from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { proxy } from "valtio";
import { useCurrentOrg } from "../contexts/current-org";

const GroceryListProposalSearchSchema = S.Struct({
  proposals: S.Array(GroceryListProposalID).pipe(S.optional),
  unsaved: S.Array(GroceryListChangeKind).pipe(S.optional),
});

export const Route = createFileRoute(
  "/$org/_layout/admin/_layout/grocery-list/$grocerylist",
)({
  component: GroceryListPage,
  validateSearch: S.decodeUnknownSync(GroceryListProposalSearchSchema),
});

function GroceryListPage() {
  const { grocerylist } = Route.useParams();
  const navigate = Route.useNavigate();
  const { org } = useCurrentOrg();
  const notifications = useNotifications();

  const {
    data: groceryListData,
    isLoading,
    error,
    refetch,
  } = useRpcQuery({
    queryKey: ["grocery-list", grocerylist],
    orgID: org.id,
    request: new GroceryListAPI.GetGroceryList({
      groceryListID: GroceryListID.make(grocerylist),
    }),
  });

  const deleteGroceryListMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListAPI.DeleteGroceryList) => input,
    onSuccess: () => {
      navigate({ to: "/$org/admin", params: { org: org.urlKey } });
    },
  });

  const handleDelete = () => {
    notifications.confirm({
      title: "Delete Grocery List",
      description:
        "Are you sure you want to delete this grocery list? This action cannot be undone.",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      onConfirmed: () => {
        deleteGroceryListMutation.mutate(
          new GroceryListAPI.DeleteGroceryList({
            groceryListID: GroceryListID.make(grocerylist),
          }),
        );
      },
      onCancelled: () => {
        console.log("Deletion cancelled");
      },
    });
  };
  const { proposals, unsaved } = Route.useSearch();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const navigationState = useMemo(():
    | GroceryListNavigationState
    | undefined => {
    if (!groceryListData) return undefined;
    const state = proxy({
      savedGroceryList: groceryListData.groceryList,
      unsavedChanges: [...(unsaved ?? [])],
      selectedProposals: [...(proposals ?? [])],
      setUnsavedChanges: (unsavedChanges: GroceryListChangeKind[]) => {
        state.unsavedChanges = unsavedChanges;
        navigate({
          search: (prev) => ({
            ...prev,
            unsaved: unsavedChanges,
          }),
        });
      },
      setSelectedProposals: (selectedProposals: GroceryListProposalID[]) => {
        state.selectedProposals = selectedProposals;
        navigate({
          search: (prev) => ({ ...prev, proposals: selectedProposals }),
        });
      },
    });
    return state;
  }, [groceryListData, navigate]);
  useEffect(() => {
    navigationState?.setSelectedProposals([...(proposals ?? [])]);
  }, [proposals, navigationState]);
  useEffect(() => {
    navigationState?.setUnsavedChanges([...(unsaved ?? [])]);
  }, [unsaved, navigationState]);

  if (isLoading) {
    return <div $="p-12">Loading...</div>;
  }

  if (error) {
    return (
      <div $="p-12">
        <AppFailureDisplay $ error={error} />
      </div>
    );
  }

  if (!navigationState) {
    return <div $="p-12">Grocery list not found</div>;
  }

  return (
    <GroceryListProvider orgId={org.id} navigationState={navigationState}>
      <div $="w-full">
        <EditableGroceryList onDelete={handleDelete} onRefetch={refetch} />
      </div>
    </GroceryListProvider>
  );
}

function EditableGroceryList({
  onDelete,
  onRefetch,
}: {
  onDelete: () => void;
  onRefetch: () => void;
}) {
  const { org } = useCurrentOrg();
  const { groceryList, dev, clearChanges, toggleProposal, selectedProposals } =
    useGroceryList();
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  const [isProposalSelectorOpen, setIsProposalSelectorOpen] = useState(false);

  const styles = useColorStyles(groceryList.color.currentValue);

  const handleAddItem = () => {
    groceryList.itemList.addItem();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem();
    }
  };

  const handleDiscardChanges = () => {
    clearChanges();
  };

  const handleColorChange = (newColor: Color) => {
    groceryList.color.set(newColor);
    setIsColorModalOpen(false);
  };

  const createProposalMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.CreateProposal) => input,
    onSuccess: (result) => {
      console.log("Proposal created", result);
      toast({
        title: "Proposal Created",
        description: "Your changes have been proposed successfully.",
      });
      clearChanges();
      if (result.proposalId) {
        toggleProposal(result.proposalId);
      }
    },
  });

  const handleCreateProposal = () =>
    createProposalMutation.mutate(
      new GroceryListProposalAPI.CreateProposal({
        groceryListId: groceryList.id,
        title: "New proposal",
        description: "",
        initialContent: [{ _tag: "Changes", changes: dev.unsavedChanges }],
      }),
    );

  const saveChangesMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListAPI.SaveGroceryListChanges) => input,
    onSuccess: () => {
      toast({
        title: "Changes Saved",
        description: "Your changes have been saved successfully.",
      });
      clearChanges();
      onRefetch();
    },
    onError: (error) => {
      toast({
        title: "Error Saving Changes",
        description:
          "There was an error saving your changes. Please try again.",
        variant: "destructive",
      });
      console.error("Error saving changes:", error);
    },
  });

  const handleSaveChanges = () => {
    saveChangesMutation.mutate(
      new GroceryListAPI.SaveGroceryListChanges({
        groceryListID: groceryList.id,
        changes: dev.unsavedChanges,
      }),
    );
  };

  return (
    <div $="flex">
      <div $="w-1/2 pr-4">
        <div
          $="p-6 rounded-md border h-full overflow-auto"
          style={styles.container}
        >
          <div $="flex justify-between items-center mb-4 group">
            <div $="flex items-center space-x-2">
              <div
                $={cn(
                  "w-6 h-6 rounded-full cursor-pointer flex-none",
                  groceryList.color.isModified &&
                    "outline outline-blue-400 dark:outline-blue-500",
                )}
                style={styles.colorPreview}
                onClick={() => setIsColorModalOpen(true)}
              />
              <Input
                $={cn(
                  "text-3xl font-bold bg-transparent border-none focus:ring-0",
                  groceryList.displayName.isModified &&
                    "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
                )}
                value={groceryList.displayName.currentValue}
                onChange={(e) => groceryList.displayName.set(e.target.value)}
                style={styles.textPrimary}
              />
            </div>
            <div $="flex items-center space-x-2">
              <GroceryListHistoryList />
              <DropdownMenu>
                <DropdownMenuTrigger $ asChild>
                  <Button $ variant="ghost" size="icon">
                    <Ta.IconDotsVertical $="w-4 h-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent $>
                  <DropdownMenuItem
                    $="text-red-600 hover:text-red-700 hover:bg-red-100 dark:hover:bg-red-800 dark:hover:text-red-100"
                    onClick={onDelete}
                  >
                    <Ta.IconTrash $="w-4 h-4 mr-2" />
                    Delete List
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <Textarea
            $={cn(
              "text-sm p-2 mb-4 bg-transparent border-none focus:ring-0 resize-none",
              groceryList.description.isModified &&
                "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
            )}
            value={groceryList.description.currentValue}
            onChange={(e) => groceryList.description.set(e.target.value)}
            placeholder="Add a description for your model"
            rows={2}
            style={styles.textSecondary}
          />
          <div $="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 $="text-xl font-semibold mb-4" style={styles.textPrimary}>
              Items
            </h2>
            <ul $="space-y-2 mb-4">
              {groceryList.itemList.items.map((item) => (
                <EditableGroceryListItem key={item.id} item={item} />
              ))}
            </ul>
            <div $="flex space-x-2">
              <Input
                value={groceryList.itemList.newItemText}
                onChange={(e) =>
                  groceryList.itemList.setNewItemText(e.target.value)
                }
                onKeyPress={handleKeyPress}
                placeholder="New item"
                $="flex-grow"
              />
              <Button $ onClick={handleAddItem}>
                Add Item
              </Button>
            </div>
          </div>
          {dev.unsavedChanges.length > 0 && (
            <div $="mt-6 flex justify-end space-x-2">
              <Button $ variant="outline" onClick={handleDiscardChanges}>
                Discard Changes
              </Button>
              <Button $ variant="secondary" onClick={handleCreateProposal}>
                Propose Changes
              </Button>
              <Button
                $
                onClick={handleSaveChanges}
                disabled={saveChangesMutation.isPending}
              >
                {saveChangesMutation.isPending
                  ? "Saving..."
                  : `Save Changes (${dev.unsavedChanges.length})`}
              </Button>
            </div>
          )}
          {saveChangesMutation.error && (
            <AppFailureDisplay $ error={saveChangesMutation.error} />
          )}
          <PickColorModal
            isOpen={isColorModalOpen}
            initialColor={groceryList.color.currentValue}
            onChosen={handleColorChange}
            onDismiss={() => setIsColorModalOpen(false)}
          />
        </div>
      </div>
      <div $="w-1/2 pl-4">
        <div $="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div $="mt-4 flex justify-between items-center">
            <h3 $="text-lg font-semibold">Proposals</h3>
            <Dialog
              open={isProposalSelectorOpen}
              onOpenChange={setIsProposalSelectorOpen}
            >
              <DialogTrigger $ asChild>
                <Button $ variant="outline" size="sm">
                  <Ta.IconList $="w-4 h-4 mr-2" />
                  {selectedProposals.length > 0
                    ? `Selected Proposals (${selectedProposals.length})`
                    : "View Proposals"}
                </Button>
              </DialogTrigger>
              <DialogContent $="max-w-2xl max-h-[80vh]">
                <DialogHeader $>
                  <DialogTitle $>Select Proposals</DialogTitle>
                </DialogHeader>
                <div $="flex-grow overflow-y-auto">
                  <ProposalSelector />
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <div $="mt-4 space-y-4 max-h-[60vh] overflow-y-auto">
            {selectedProposals.length > 0 ? (
              selectedProposals.map((proposalId) => (
                <GroceryListProposalDetails
                  key={proposalId}
                  proposalId={proposalId}
                />
              ))
            ) : (
              <p $="text-gray-500 dark:text-gray-400">
                No proposals selected. Click "View Proposals" to select
                proposals.
              </p>
            )}
          </div>
          {/* <GroceryListDevUI /> */}
        </div>
      </div>
    </div>
  );
}

const EditableGroceryListItem: React.FC<{
  item: GroceryListItemDisplay;
}> = ({ item }) => {
  return (
    <li $="flex items-center space-x-2">
      <Input
        value={item.title}
        onChange={(e) => item.updateTitle(e.target.value)}
        $={cn(
          "flex-grow bg-transparent",
          item.isUpdated &&
            "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
          item.isAdded &&
            "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
          item.isRemoved &&
            "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
        )}
        disabled={item.isRemoved}
      />
      <Button
        $="p-2"
        variant="ghost"
        size="icon"
        disabled={item.isRemoved ? !item.canUndoRemove : false}
        onClick={() => (item.isRemoved ? item.undoRemove() : item.remove())}
      >
        {item.isRemoved ? (
          <Ta.IconArrowBack $="w-4 h-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300" />
        ) : (
          <Ta.IconTrash $="w-4 h-4 text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300" />
        )}
      </Button>
    </li>
  );
};
