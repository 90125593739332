import { AppFailureDisplay } from "@/components/AppFailureDisplay";
import { UserNameChip } from "@/components/UserNameChip";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Ta } from "@/components/ui/icons";
import { toast } from "@/components/ui/use-toast";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { useCurrentOrg } from "@/contexts/current-org";
import { useNotifications } from "@/hooks/notifications";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { cn } from "@/lib/utils";
import {
  GroceryListProposalAPI,
  type GroceryListProposalID,
  ProposalStatus,
} from "@phosphor/server";
import type React from "react";

interface ProposalSelectorProps {}

export const ProposalSelector: React.FC<ProposalSelectorProps> = ({}) => {
  const { groceryList, selectedProposals, toggleProposal } = useGroceryList();
  const {
    data: proposalsListData,
    isLoading,
    error,
    refetch,
  } = useRpcQuery({
    queryKey: ["grocery-list-proposals", groceryList.id],
    request: new GroceryListProposalAPI.ListProposals({
      groceryListId: groceryList.id,
      statuses: [
        ProposalStatus.make("open"),
        ProposalStatus.make("draft"),
        ProposalStatus.make("approved"),
        ProposalStatus.make("rejected"),
      ],
    }),
  });

  if (isLoading) {
    return <div $="p-4">Loading proposals...</div>;
  }

  if (error) {
    return (
      <div $="p-4 text-red-500">
        <AppFailureDisplay $ error={error} />
      </div>
    );
  }

  return (
    <div $="space-y-4">
      {proposalsListData?.proposals.map((proposal) => (
        <ProposalSelectorListItem
          key={proposal.id}
          proposal={proposal}
          isSelected={selectedProposals.includes(proposal.id)}
          onToggle={toggleProposal}
          onDelete={() => refetch()}
        />
      ))}
      {proposalsListData?.proposals.length === 0 && (
        <p $="text-gray-500 dark:text-gray-400">
          No open proposals for this grocery list.
        </p>
      )}
    </div>
  );
};

interface ProposalSelectorListItemProps {
  proposal: GroceryListProposalAPI.ProposalInfo;
  isSelected: boolean;
  onToggle: (id: GroceryListProposalID) => void;
  onDelete: () => void;
}

const ProposalSelectorListItem: React.FC<ProposalSelectorListItemProps> = ({
  proposal,
  isSelected,
  onToggle,
  onDelete,
}) => {
  const { org } = useCurrentOrg();
  const notifications = useNotifications();

  const deleteProposalMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.DeleteProposal) => input,
    onSuccess: () => {
      toast({
        title: "Proposal Deleted",
        description: "The proposal has been successfully deleted.",
      });
      onDelete();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete the proposal. Please try again.",
        variant: "destructive",
      });
      console.error("Error deleting proposal:", error);
    },
  });

  const handleDelete = () => {
    notifications.confirm({
      title: "Delete Proposal",
      description:
        "Are you sure you want to delete this proposal? This action cannot be undone.",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      onConfirmed: () => {
        deleteProposalMutation.mutate(
          new GroceryListProposalAPI.DeleteProposal({
            proposalId: proposal.id,
          }),
        );
      },
      onCancelled: () => {
        console.log("Deletion cancelled");
      },
    });
  };

  const getStatusBadge = (status: typeof proposal.status) => {
    const statusColors = {
      draft: "bg-gray-200 text-gray-800",
      open: "bg-blue-200 text-blue-800",
      approved: "bg-green-200 text-green-800",
      rejected: "bg-red-200 text-red-800",
      merged: "bg-purple-200 text-purple-800",
    } as const;

    return (
      <Badge
        $={statusColors[status as keyof typeof statusColors]}
        variant="outline"
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  return (
    <div
      key={proposal.id}
      $="flex items-center justify-between p-3 bg-gray-100 dark:bg-gray-700 rounded-lg"
    >
      <div $="flex flex-col">
        <div $="flex items-center space-x-2 mb-1">
          <span $="font-medium">{proposal.title}</span>
          {getStatusBadge(proposal.status)}
        </div>
        <div $="flex items-center text-sm text-gray-500">
          <UserNameChip userId={proposal.createdBy} />
          <span $="ml-2">{new Date(proposal.createdAt).toLocaleString()}</span>
        </div>
      </div>
      <div $="flex items-center space-x-2">
        <Button
          $
          size="sm"
          variant={isSelected ? "default" : "outline"}
          onClick={() => onToggle(proposal.id)}
          className={cn(
            "transition-colors",
            isSelected
              ? "bg-blue-500 hover:bg-blue-600 text-white"
              : "hover:bg-gray-200 dark:hover:bg-gray-600",
          )}
        >
          {isSelected ? "Selected" : "Select"}
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger $ asChild>
            <Button $ variant="ghost" size="icon">
              <Ta.IconDotsVertical $="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent $>
            <DropdownMenuItem
              $="text-red-600 hover:text-red-700 hover:bg-red-100 dark:hover:bg-red-800 dark:hover:text-red-100"
              onClick={handleDelete}
            >
              <Ta.IconTrash $="w-4 h-4 mr-2" />
              Delete Proposal
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
