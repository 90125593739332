export function tightJsonStringify(
  obj: unknown,
  replacer?:
    | ((this: unknown, key: string, value: unknown) => unknown)
    | undefined,
) {
  if (obj === undefined) return undefined;
  const str = JSON.stringify(obj, replacer, 2);
  if (typeof str !== "string") {
    console.error("Unexpected non-string JSON.stringify result", { str, obj });
    return undefined;
  }

  return str
    .replace(/^([{[])\n (\s+)/, "$1$2")
    .replace(/(\n[ ]+[{[])\n\s+/g, "$1 ")
    .replace(/\n\s*([\]}])/g, " $1");
}
