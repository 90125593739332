import { PickColorModal } from "@/components/PickColorModal";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useNotifications } from "@/hooks/notifications";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { getColorStyles, useColorStyles } from "@/hooks/useColorStyles";
import {
  type Color,
  GroceryListAPI,
  type GroceryListInfo,
} from "@phosphor/server";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCurrentOrg } from "../contexts/current-org";

import dashboardMockUrl from "../dev-doc-images/2024-09-12-dashboard-mock.png?url";
import aboutPageUrl from "../dev-doc-images/2024-09-18-about-page.png?url";
import databookPageUrl from "../dev-doc-images/databook-1-2024-10.png?url";

export const Route = createFileRoute("/$org/_layout/admin/_layout/")({
  component: AdminIndex,
});

function AdminIndex() {
  const { org } = useCurrentOrg();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { data: lists, refetch } = useRpcQuery({
    queryKey: ["grocery-lists", org.id],
    request: new GroceryListAPI.ListGroceryLists({ orgID: org.id }),
  });

  const deleteGroceryListMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListAPI.DeleteGroceryList) => input,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      toast({ error });
    },
  });

  const handleDeleteList = (list: GroceryListInfo) => {
    notifications.confirm({
      title: "Delete Grocery List",
      description: `Are you sure you want to delete "${list.displayName}"? This action cannot be undone.`,
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      onConfirmed: () => {
        deleteGroceryListMutation.mutate(
          new GroceryListAPI.DeleteGroceryList({
            groceryListID: list.id,
          }),
        );
      },
      onCancelled: () => {
        // Optionally handle cancellation
        console.log("Deletion cancelled");
      },
    });
  };

  return (
    <div $="p-4">
      <div $="flex justify-between items-baseline mt-8 mb-6">
        <h2 $="text-2xl font-bold">Grocery Lists</h2>
        <Button $ variant="ghost" onClick={() => setIsModalOpen(true)}>
          Create New List
          <Ta.IconPlus $="ml-2 h-4 w-4" />
        </Button>
      </div>
      <div $="space-y-2">
        {lists?.groceryLists.length === 0 && (
          <div $="text-gray-500">No lists found</div>
        )}
        {lists?.groceryLists.map((list) => {
          const styles = getColorStyles(list.color);
          return (
            <div
              $="flex justify-between items-center p-2 rounded transition-colors duration-200"
              key={list.id}
              style={styles.listItem}
            >
              <Link
                $="flex-grow"
                to="/$org/admin/grocery-list/$grocerylist"
                params={{
                  org: org.urlKey,
                  grocerylist: list.id,
                }}
              >
                <span $="font-semibold" style={styles.textPrimary}>
                  {list.displayName}
                </span>
              </Link>
              <DropdownMenu>
                <DropdownMenuTrigger $ asChild>
                  <Button $ variant="ghost" size="sm">
                    <Ta.IconDotsVertical $="w-4 h-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent $>
                  <DropdownMenuItem
                    $
                    onClick={() => {
                      navigate({
                        to: "/$org/admin/grocery-list/$grocerylist",
                        params: { org: org.urlKey, grocerylist: list.id },
                      });
                    }}
                  >
                    <Ta.IconEye $="w-4 h-4 mr-2" />
                    Open
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    $="text-red-600 hover:text-red-700 hover:bg-red-100"
                    onClick={() => handleDeleteList(list)}
                  >
                    <Ta.IconTrash $="w-4 h-4 mr-2" />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        })}
      </div>
      <CreateListModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={() => {
          setIsModalOpen(false);
          refetch();
        }}
      />
      <h2 $="text-2xl font-bold mb-4 mt-8">Demo Pages</h2>
      <div $="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link to="/dashboard" $="flex flex-col items-center">
          <img
            src={dashboardMockUrl}
            alt="Dashboard Mock"
            $="w-full aspect-video object-contain mb-2"
          />
          <span $="text-sm text-center text-gray-600 dark:text-gray-400">
            Dashboard Mock
          </span>
        </Link>
        <Link to="/about" $="flex flex-col items-center">
          <img
            src={aboutPageUrl}
            alt="About Page"
            $="w-full aspect-video object-contain mb-2"
          />
          <span $="text-sm text-center text-gray-600 dark:text-gray-400">
            About Page
          </span>
        </Link>
        <Link
          to="/$org/admin/workspace/$workspace/databook"
          params={{
            org: org.urlKey,
            workspace: "a2826d7a-8a20-4283-a378-efe34a4ac5b0",
          }}
          $="flex flex-col items-center"
        >
          <img
            src={databookPageUrl}
            alt="Demo Databook"
            $="w-full aspect-video object-contain mb-2"
          />
          <span $="text-sm text-center text-gray-600 dark:text-gray-400">
            Databook
          </span>
        </Link>
        {/* Add more demo pages here as needed */}
      </div>
    </div>
  );
}

type CreateListFormValues = {
  displayName: string;
  description: string;
  color: Color;
};

function CreateListModal({
  isOpen,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { org } = useCurrentOrg();
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);

  const form = useForm<CreateListFormValues>({
    defaultValues: {
      displayName: "",
      description: "",
      color: { chroma: 0.2, hue: 360 * Math.random() },
    },
  });

  const createListMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListAPI.CreateGroceryList) => input,
    onSuccess,
    onError: (error) => toast({ error }),
  });

  const onSubmit = (data: CreateListFormValues) => {
    createListMutation.mutate(
      new GroceryListAPI.CreateGroceryList({
        orgID: org.id,
        displayName: data.displayName,
        description: data.description,
        color: data.color,
      }),
    );
  };

  const handleColorChosen = (newColor: Color) => {
    form.setValue("color", newColor);
    setIsColorModalOpen(false);
  };

  const colorStyles = useColorStyles(form.watch("color"));

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent $>
        <DialogHeader $>
          <DialogTitle $>Create New Grocery List</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} $="space-y-4">
            <FormField
              control={form.control}
              name="displayName"
              rules={{ required: "List name is required" }}
              render={({ field }) => (
                <FormItem $>
                  <FormLabel $>List Name</FormLabel>
                  <FormControl $>
                    <Input $ placeholder="Enter list name" {...field} />
                  </FormControl>
                  <FormMessage $ />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem $>
                  <FormLabel $>Description</FormLabel>
                  <FormControl $>
                    <Textarea
                      $
                      placeholder="Enter list description"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Controller
              control={form.control}
              name="color"
              render={({ field }) => (
                <FormItem $>
                  <FormLabel $>Color</FormLabel>
                  <FormControl $>
                    <div $="flex items-center space-x-2">
                      <div
                        $="w-10 h-10 rounded-full cursor-pointer"
                        style={colorStyles.colorPreview}
                        onClick={() => setIsColorModalOpen(true)}
                      />
                      <Button
                        $
                        type="button"
                        variant="outline"
                        onClick={() => setIsColorModalOpen(true)}
                      >
                        Change Color
                      </Button>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <div $="flex justify-end gap-2">
              <Button $ type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button $ type="submit">
                Create List
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
      <PickColorModal
        isOpen={isColorModalOpen}
        initialColor={form.watch("color")}
        onChosen={handleColorChosen}
        onDismiss={() => setIsColorModalOpen(false)}
      />
    </Dialog>
  );
}
