import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast key={id} {...props}>
          <div $="grid gap-1">
            {title && <ToastTitle $>{title}</ToastTitle>}
            {description && (
              <ToastDescription $>{description}</ToastDescription>
            )}
          </div>
          {action}
          <ToastClose $ />
        </Toast>
      ))}
      <ToastViewport $ />
    </ToastProvider>
  );
}
