import { cn } from "@/lib/utils";
import React, { useState } from "react";
import { Button, type ButtonProps } from "./button";
import { Ta } from "./icons";

interface CopyToClipboardButtonProps extends ButtonProps {
  text: string;
}

export const CopyToClipboardButton = React.forwardRef<
  HTMLButtonElement,
  CopyToClipboardButtonProps
>(({ text, className, ...props }, ref) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Button
      ref={ref}
      className={cn("shrink-0", className)}
      variant="outline"
      onClick={handleCopy}
      {...props}
    >
      {isCopied ? "Copied!" : <Ta.IconCopy />}
    </Button>
  );
});

CopyToClipboardButton.displayName = "CopyToClipboardButton";
