import { AssumptionsTable } from "@/components/AssumptionsTable";
import { DocumentsTable } from "@/components/DocumentsTable";
import { OrgWorkspaceProjectSelectorSidebar } from "@/components/Globals/Sidebar";
import { Badge } from "@/components/ui/badge";
import { Ta } from "@/components/ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  ProvideDatabookContext,
  useDatabook,
} from "@/contexts/DatabookContext";
import { useSelectAtom } from "@/lib/jotai/useSelectAtom";
import { S } from "@phosphor/prelude";
import {
  WorkspaceChangeKind,
  WorkspaceID,
  WorkspaceProposalID,
} from "@phosphor/server";
import { TabsContent } from "@radix-ui/react-tabs";
import { createFileRoute } from "@tanstack/react-router";
import { InfoIcon } from "lucide-react";
import { useMemo } from "react";
import { LayoutWithSidebar } from "../components/LayoutWithSidebar";

const DatabookSearchSchema = S.Struct({
  proposals: S.Array(WorkspaceProposalID).pipe(S.optional),
  unsaved: S.Array(WorkspaceChangeKind).pipe(S.optional),
  documentSorting: S.Array(S.Struct({ id: S.String, desc: S.Boolean })).pipe(
    S.optional,
  ),
  assumptionSorting: S.Array(S.Struct({ id: S.String, desc: S.Boolean })).pipe(
    S.optional,
  ),
});

export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout/databook",
)({
  component: DatabookPageWrapper,
  validateSearch: S.decodeUnknownSync(DatabookSearchSchema),
});

// function WorkspaceDatabook() {
//   const [searchTerm, setSearchTerm] = useState("");
//   const mockData = [
//     { id: 1, name: "Revenue", type: "Number", value: "$1,000,000" },
//     { id: 2, name: "Expenses", type: "Number", value: "$750,000" },
//     { id: 3, name: "Profit Margin", type: "Percentage", value: "25%" },
//     { id: 4, name: "Customer Count", type: "Integer", value: "5,000" },
//     {
//       id: 5,
//       name: "Product Categories",
//       type: "List",
//       value: "Electronics, Clothing, Books",
//     },
//   ];

//   const filteredData = mockData.filter((item) =>
//     item.name.toLowerCase().includes(searchTerm.toLowerCase()),
//   );

//   return (
//     <div $="p-6 bg-white h-[100vh]">
//       <h2 $="text-2xl font-semibold mb-6">Databook</h2>
//       <div $="mb-4">
//         <Input
//           $="w-full max-w-md"
//           type="text"
//           placeholder="Search data..."
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//         />
//       </div>
//       <div $="overflow-x-auto">
//         <table $="min-w-full bg-white border border-gray-300">
//           <thead $>
//             <tr $="bg-gray-100">
//               <th $="py-2 px-4 border-b text-left">Name</th>
//               <th $="py-2 px-4 border-b text-left">Type</th>
//               <th $="py-2 px-4 border-b text-left">Value</th>
//               <th $="py-2 px-4 border-b text-left">Actions</th>
//             </tr>
//           </thead>
//           <tbody $>
//             {filteredData.map((item) => (
//               <tr key={item.id} $="hover:bg-gray-50">
//                 <td $="py-2 px-4 border-b">{item.name}</td>
//                 <td $="py-2 px-4 border-b">{item.type}</td>
//                 <td $="py-2 px-4 border-b">{item.value}</td>
//                 <td $="py-2 px-4 border-b">
//                   <button $="text-blue-500 hover:text-blue-700 mr-2">
//                     <Ta.IconEdit $="w-5 h-5" />
//                   </button>
//                   <button $="text-red-500 hover:text-red-700">
//                     <Ta.IconTrash $="w-5 h-5" />
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }
function DatabookPageWrapper() {
  const params = Route.useParams();
  const workspaceId = useMemo(
    () => WorkspaceID.make(params.workspace),
    [params.workspace],
  );
  return (
    <ProvideDatabookContext workspaceId={workspaceId}>
      <DatabookPage />
    </ProvideDatabookContext>
  );
}

function DatabookPage() {
  return (
    <LayoutWithSidebar sidebar={<OrgWorkspaceProjectSelectorSidebar />}>
      <DatabookPageTabs />
    </LayoutWithSidebar>
  );
}
function DatabookPageTabs() {
  const { databookAtom } = useDatabook();
  const workspaceName = useSelectAtom(databookAtom, (a) => a.data?.name);

  return (
    <Tabs defaultValue="model" $="flex justify-stretch items-stretch">
      <div $="p-4 bg-white">
        <div $="flex justify-between items-center">
          <h1 $="text-2xl font-bold mb-4">{workspaceName}</h1>
          <div $=" p-1.5 rounded-full inline-flex items-center">
            <span $="text-sm font-medium text-black mr-2">View by:</span>
            <TabsList $="bg-transparent inline-flex">
              <TabsTrigger
                value="model"
                $="data-[state=active]:bg-[#EFEDF3] data-[state=active]:text-[#766293] border-y border-l data-[state=active]:border-[#CAC2D6] px-4 py-1 text-sm font-medium text-gray-600 rounded-l-full"
              >
                <Ta.IconListDetails $="w-4 h-4 mr-1" />
                Assumptions
              </TabsTrigger>
              <TabsTrigger
                value="strategy"
                $="data-[state=active]:bg-[#EFEDF3] data-[state=active]:text-[#766293] border data-[state=active]:border-[#CAC2D6] data-[state=active]:border border-gray-300 px-4 py-1 text-sm font-medium text-gray-600"
              >
                <Ta.IconTicTac $="w-4 h-4 mr-1" />
                Strategies
              </TabsTrigger>
              <TabsTrigger
                value="document"
                $="data-[state=active]:bg-[#EFEDF3] data-[state=active]:text-[#766293 border-y border-r data-[state=active]:border-[#CAC2D6] px-4 py-1 text-sm font-medium text-gray-600 rounded-r-full"
              >
                <Ta.IconFileDescription $="w-4 h-4 mr-1" />
                Documents
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
        <TabsContent value="model" $>
          <AssumptionsTable route={Route.id} />

          {/* <h2 $="text-xl font-semibold mb-2 mt-4">Documents</h2>
          <DocumentsTable /> */}
        </TabsContent>
        <TabsContent value="strategy" $>
          <div $="rounded-md border overflow-hidden">
            <div $="flex items-center justify-between w-full p-4 bg-gray-50 border-b border-gray-200">
              <h3 $="text-lg font-semibold w-full">PPA Offtake Strategy</h3>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger $>
                    <Badge $="px-2 py-1 justify-center hover:bg-gray-200">
                      <InfoIcon $="w-4 h-4 mr-1" />3
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent $ id="">
                    <div $="space-y-2">
                      <div $="flex items-center">
                        <span $="w-3 h-3 rounded-full bg-red-500 mr-2"></span>
                        <span $>Red - Over budget by more than 10%</span>
                      </div>
                      <div $="flex items-center">
                        <span $="w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>
                        <span $>Yellow - Within 5-10% of budget</span>
                      </div>
                      <div $="flex items-center">
                        <span $="w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                        <span $>Green - Under budget or within 5%</span>
                      </div>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <Table $=" border-collapse border-spacing-0 text-sm text-left">
              <TableHeader $="text-xs whitespace-nowrap text-muted-foreground">
                <TableRow $>
                  <TableHead $="px-6 py-4 font-medium">Concern</TableHead>
                  <TableHead $="px-6 py-4 font-medium">Description</TableHead>
                  <TableHead $="px-6 py-4 font-medium">Answer</TableHead>
                  <TableHead $="px-6 py-4 font-medium">
                    Associated Documents
                  </TableHead>
                  <TableHead $="px-6 py-4 font-medium">Last Updated</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody $>
                <TableRow $="border-t">
                  <TableCell $="px-6 py-4">Credit Profile</TableCell>
                  <TableCell $="px-6 py-4">
                    Based on project schedule, adjusted t...
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <Badge $>Yes</Badge>
                    <div $="text-xs text-gray-500">
                      The project is on or ahead of schedule
                    </div>
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <div $="flex space-x-2">
                      <Badge variant="outline" $="bg-blue-950/10">
                        Financial Close
                      </Badge>
                      <Badge variant="outline" $="bg-blue-950/10">
                        Availability/Construction Start Date
                      </Badge>
                    </div>
                  </TableCell>
                  <TableCell $="px-6 py-4">1 day ago</TableCell>
                </TableRow>

                <TableRow $="border-t">
                  <TableCell $="px-6 py-4">Offtake</TableCell>
                  <TableCell $="px-6 py-4">
                    Based on project schedule, adjusted t...
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <Badge $ variant="destructive">
                      No
                    </Badge>
                    <div $="text-xs text-gray-500">
                      The project is behind schedule
                    </div>
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <Badge variant="outline" $="bg-blue-950/10">
                      Start of Operations
                    </Badge>
                  </TableCell>
                  <TableCell $="px-6 py-4">2 days ago</TableCell>
                </TableRow>

                <TableRow $="border-t">
                  <TableCell $="px-6 py-4">Termination</TableCell>
                  <TableCell $="px-6 py-4">
                    Based on project schedule, adjusted t...
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger $>
                          <Badge $ variant="destructive">
                            Red - Over budget by more than 10%
                          </Badge>
                        </TooltipTrigger>
                        <TooltipContent $>
                          <div $="space-y-2">
                            <div $="flex items-center">
                              <span $="w-3 h-3 rounded-full bg-red-500 mr-2"></span>
                              <span $>Red - Over budget by more than 10%</span>
                            </div>
                            <div $="flex items-center">
                              <span $="w-3 h-3 rounded-full bg-yellow-500 mr-2"></span>
                              <span $>Yellow - Within 5-10% of budget</span>
                            </div>
                            <div $="flex items-center">
                              <span $="w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                              <span $>Green - Under budget or within 5%</span>
                            </div>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell $="px-6 py-4">
                    <div $="flex space-x-2">
                      <Badge variant="outline" $="bg-blue-950/10">
                        Wind: COD
                      </Badge>
                      <Badge variant="outline" $="bg-blue-950/10">
                        PV: COD
                      </Badge>
                    </div>
                  </TableCell>
                  <TableCell $="px-6 py-4">08/09/2024</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </TabsContent>
        <TabsContent value="document" $>
          {/* <AssumptionsTable /> */}

          <h2 $="text-xl font-semibold mb-2 mt-4">Documents</h2>
          <DocumentsTable />
        </TabsContent>
      </div>
    </Tabs>
  );
}
