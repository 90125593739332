import { AppFailureDisplay } from "@/components/AppFailureDisplay";
import { UserNameChip } from "@/components/UserNameChip";
import { useWorkspaceNavigation } from "@/components/WorkspaceNavigationService";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import {
  type WorkspaceID,
  WorkspaceProposalAPI,
  type WorkspaceProposalID,
} from "@phosphor/server";
import { useAtom } from "jotai";
import type React from "react";
import { useCallback, useState } from "react";
import { Spinner } from "../ui/spinner";

const getSelectionColor = (index: number) => {
  // Predefined color combinations for Tailwind
  const colorSchemes = [
    {
      background: "bg-blue-50 dark:bg-blue-900/20",
      border: "border-blue-500",
      badge: "bg-blue-500 hover:bg-blue-600 text-white",
    },
    {
      background: "bg-emerald-50 dark:bg-emerald-900/20",
      border: "border-emerald-500",
      badge: "bg-emerald-500 hover:bg-emerald-600 text-white",
    },
    {
      background: "bg-purple-50 dark:bg-purple-900/20",
      border: "border-purple-500",
      badge: "bg-purple-500 hover:bg-purple-600 text-white",
    },
    {
      background: "bg-amber-50 dark:bg-amber-900/20",
      border: "border-amber-500",
      badge: "bg-amber-500 hover:bg-amber-600 text-white",
    },
    {
      background: "bg-rose-50 dark:bg-rose-900/20",
      border: "border-rose-500",
      badge: "bg-rose-500 hover:bg-rose-600 text-white",
    },
  ];

  return colorSchemes[index % colorSchemes.length];
};

interface WorkspaceProposalListButtonProps {
  button: React.ReactNode;
  workspaceId: WorkspaceID;
}

const ProposalItem: React.FC<{
  proposal: WorkspaceProposalAPI.ProposalInfo;
  isSelected: boolean;
  selectionIndex: number;
  onToggle: (id: WorkspaceProposalID) => void;
  isCompareMode: boolean;
}> = ({ proposal, isSelected, selectionIndex, onToggle, isCompareMode }) => {
  const colors = isSelected
    ? getSelectionColor(selectionIndex)
    : {
        background:
          "bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700",
        border: "border-transparent",
        badge:
          "bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600",
      };

  return (
    <div
      $={`flex items-center justify-between p-3 ${colors.background} ${colors.border} rounded-lg border transition-colors cursor-pointer`}
      onClick={() => onToggle(proposal.id)}
    >
      <div $="flex flex-col flex-grow">
        <div $="flex items-center space-x-2 mb-1">
          <span $="font-medium">{proposal.title}</span>
          <Badge $ variant="outline">
            ID: {proposal.id}
          </Badge>
        </div>
        <p $="text-sm text-gray-600 dark:text-gray-400 mb-2">
          {proposal.description}
        </p>
        <div $="flex items-center text-sm text-gray-500 space-x-4">
          <div $="flex items-center">
            <UserNameChip userId={proposal.createdBy} subtext="Created" />
          </div>
          <span $="text-xs">
            Updated: {new Date(proposal.updatedAt).toLocaleDateString()}
          </span>
        </div>
      </div>
      <div $="flex items-center ml-4">
        <Badge
          $={`transition-colors ${colors.badge}`}
          variant={isSelected ? "default" : "outline"}
        >
          {isSelected ? (
            isCompareMode ? (
              <span $="flex items-center gap-1">
                <span $>Selected</span>
                <span $="text-xs px-1.5 py-0.5 rounded-full bg-white/20">
                  {selectionIndex + 1}
                </span>
              </span>
            ) : (
              "Current"
            )
          ) : (
            "Select"
          )}
        </Badge>
      </div>
    </div>
  );
};

const useProposalSelection = (initialMode = false) => {
  const [isCompareMode, setIsCompareMode] = useState(initialMode);
  const { selectedProposalsAtom, setSelectedProposals } =
    useWorkspaceNavigation();
  const [selectedProposals] = useAtom(selectedProposalsAtom);

  const toggleMode = useCallback(() => {
    setIsCompareMode((prev) => {
      const newMode = !prev;
      // When switching to single mode, keep only the first selected proposal
      if (!newMode && selectedProposals.length > 1) {
        setSelectedProposals((prev) => [prev[0]]);
      }
      return newMode;
    });
  }, [selectedProposals, setSelectedProposals]);

  const toggleProposal = useCallback(
    (proposalId: WorkspaceProposalID) => {
      setSelectedProposals((prev) => {
        if (isCompareMode) {
          return prev.includes(proposalId)
            ? prev.filter((id) => id !== proposalId)
            : [...prev, proposalId];
        }
        return prev.includes(proposalId) ? [] : [proposalId];
      });
    },
    [isCompareMode, setSelectedProposals],
  );

  return {
    isCompareMode,
    toggleMode,
    toggleProposal,
    selectedProposals,
  };
};

const ModeToggle: React.FC<{
  isCompareMode: boolean;
  onToggle: () => void;
}> = ({ isCompareMode, onToggle }) => (
  <div $="flex items-center space-x-2">
    <Label
      $="text-sm text-gray-600 dark:text-gray-400 flex items-center gap-2"
      htmlFor="compare-mode"
    >
      Compare
    </Label>
    <Switch
      $
      id="compare-mode"
      checked={isCompareMode}
      onCheckedChange={onToggle}
    />
  </div>
);

export const WorkspaceProposalListButton: React.FC<
  WorkspaceProposalListButtonProps
> = ({ button, workspaceId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isCompareMode, toggleMode, toggleProposal, selectedProposals } =
    useProposalSelection();

  const { data, isLoading, error } = useRpcQuery({
    queryKey: [workspaceId, "proposals"],
    request: new WorkspaceProposalAPI.ListProposals({
      workspaceId,
    }),
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger $ asChild>
        {button}
      </DialogTrigger>
      <DialogContent $="p-0 gap-0 bg-background max-w-2xl w-full max-h-[90svh] flex flex-col justify-stretch">
        <DialogHeader $="p-5 flex-none">
          <div $="flex justify-between items-center mb-4">
            <DialogTitle $="text-xl font-semibold dark:text-gray-100">
              Select Proposals
            </DialogTitle>
          </div>
          <p $="text-sm text-gray-500 flex justify-between items-center">
            <span $="flex-grow">
              {isCompareMode
                ? "Select multiple proposals to compare"
                : "Select a single proposal to view"}
            </span>
            <ModeToggle isCompareMode={isCompareMode} onToggle={toggleMode} />
          </p>
        </DialogHeader>
        <div $="space-y-3 flex-grow flex-shrink overflow-y-auto p-3">
          {isLoading ? (
            <div $="p-4 text-center text-gray-500">
              <Spinner $ />
            </div>
          ) : error ? (
            <AppFailureDisplay $ error={error} />
          ) : data?.proposals.length === 0 ? (
            <p $="text-center text-gray-500 dark:text-gray-400 p-4">
              No proposals available for this workspace.
            </p>
          ) : (
            data?.proposals.map((proposal) => {
              const selectionIndex = selectedProposals.indexOf(proposal.id);
              return (
                <ProposalItem
                  key={proposal.id}
                  proposal={proposal}
                  isSelected={selectionIndex !== -1}
                  selectionIndex={selectionIndex}
                  onToggle={toggleProposal}
                  isCompareMode={isCompareMode}
                />
              );
            })
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
