import { SAMPLE_PROJECT_CO } from "@/components/library-items/SAMPLE_PROJECT_CO";
import { MvpBuiltinLibraryItemID } from "@phosphor/server";

export const BUILTIN_LIBRARY_ITEMS: {
  key: string;
  /** e.g. "SOLAR_PROJECT" */
  builtinID: MvpBuiltinLibraryItemID;
  /** e.g. "Solar Project" */
  name: string;
  searchKeywords: string;
  previewRender: React.FC;
  description?: string;
}[] = [
  ...[
    { name: "A. CRE Timing" },
    { name: "A. CRE Operations" },
    { name: "A. CRE Unlevered Summary" },
    { name: "A. CRE Permanent Debt" },
    { name: "A. CRE GP-LP" },
    { name: "A. CRE Sources/Uses" },
    { name: "Debt Sizing" },
    { name: "CBAM Timing" },
  ].map((a) => ({
    key: a.name,
    builtinID: MvpBuiltinLibraryItemID.make("CRE"),
    name: a.name,
    searchKeywords: "",
    previewRender: () => <>{SAMPLE_PROJECT_CO}</>,
  })),
  {
    key: "Solar Project Timing",
    builtinID: MvpBuiltinLibraryItemID.make("SOLAR_PROJECT"),
    name: "Solar Project Timing",
    searchKeywords: "solar panels renewable energy",
    previewRender: () => <>{SAMPLE_PROJECT_CO}</>,
  },
  {
    key: "Project Operations New",
    builtinID: MvpBuiltinLibraryItemID.make("SAMPLE_1"),
    name: "Project Operations New",
    searchKeywords: "sample 1",
    previewRender: () => <>{SAMPLE_PROJECT_CO}</>,
  },
  {
    key: "SAMPLE_2",
    builtinID: MvpBuiltinLibraryItemID.make("SAMPLE_2"),
    name: "Solar Project",
    searchKeywords: "solar panels renewable energy",
    previewRender: () => <>{SAMPLE_PROJECT_CO}</>,
  },
];
