import { atom } from "jotai";
import React from "react";
import { IfAtom } from "../jotai/IfAtom";
import { cn } from "../utils";

export const IsDevAtom = atom(false);
export const IfDev = React.memo(
  ({
    noContainer,
    className,
    dismissKey,
    ...props
  }: {
    noContainer?: boolean;
    dismissKey: string;
  } & React.HTMLProps<HTMLDivElement>) => {
    return (
      <IfAtom atom={IsDevAtom}>
        {noContainer ? (
          props.children
        ) : (
          <div
            data-dismiss-key={dismissKey}
            className={cn(
              "p-2 border border-purple-400 bg-purple-100 rounded-md text-purple-900",
              className,
            )}
            {...props}
          />
        )}
      </IfAtom>
    );
  },
);
IfDev.displayName = "IfDev";
