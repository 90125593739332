import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCurrentUser } from "@/contexts/CurrentUserContext";
import { useCurrentOrg } from "@/contexts/current-org";
import { cn } from "@/lib/utils";
import { Link, useNavigate } from "@tanstack/react-router";
import Logo from "../../public/Logo.svg?url";
import { CreateWorkspaceButton } from "./Globals/CreateWorkspaceButton";
import { Button } from "./ui/button";
import { Ta } from "./ui/icons";

export const OrgRootNavbar = () => {
  // Why is there a currentOrg context?
  const { org } = useCurrentOrg();
  const { clearAllData } = useCurrentUser();

  return (
    <header
      $={cn(
        "sticky top-0 z-10",
        "flex flex-none justify-between items-center",
        "min-w-[800px] h-[80px] px-4 md:px-6",
        "bg-[#FAF9FB] border-b border-[#D4D4D8]",
      )}
    >
      <div $="flex items-center border border-[#D4D4D8] rounded-sm bg-background px-3 h-12">
        <Link $="shadow-none border-r pr-2 border-[#D4D4D8]" to="/">
          <img $ src={Logo} />
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger $="flex items-center pl-3 font-medium text-black text-base">
            {org.displayName}
            <Ta.IconChevronDown $="w-[16px] h-[16px] ml-[12px] mr-2" />
          </DropdownMenuTrigger>
          <DropdownMenuContent $="w-[225px] m-2">
            <Link to="/$org" params={{ org: org.urlKey }} $>
              <DropdownMenuItem $="flex items-center font-normal">
                <Ta.IconHome $="w-[18px] h-[18px] mr-4" />
                Home
              </DropdownMenuItem>
            </Link>
            <Link to="/$org/settings/profile" params={{ org: org.urlKey }} $>
              <DropdownMenuItem $="flex items-center font-normal">
                <Ta.IconUser $="w-[18px] h-[18px] mr-4" />
                User Profile
              </DropdownMenuItem>
            </Link>
            <Link $>
              <DropdownMenuItem $="flex items-center font-normal">
                <Ta.IconSettings $="w-[18px] h-[18px] mr-4" />
                Settings
              </DropdownMenuItem>
            </Link>
            {/* <Link $>
              <DropdownMenuItem $="flex items-center font-normal">
                <Ta.IconUsers $="w-[18px] h-[18px] mr-4" />
                Team
              </DropdownMenuItem>
            </Link> */}
            <Link $ to="/$org/admin" params={{ org: org.urlKey }}>
              <DropdownMenuItem $="flex items-center font-normal">
                <Ta.IconShieldLock $="w-[18px] h-[18px] mr-4" />
                Admin
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSwitchOrgsItem />
            <DropdownMenuSeparator $ />
            <Link $>
              <DropdownMenuItem
                $="flex items-center font-normal"
                onClick={() => clearAllData()}
              >
                <Ta.IconLogout $="w-[18px] h-[18px] mr-4" />
                Logout
              </DropdownMenuItem>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div $="flex">
        <Button $="bg-background flex items-center gap-[4px] text-[#202734] font-medium text-xs hover:bg-background hover:text-[#202734] border border-[#D4D4D8] h-[33px] rounded-full">
          <Ta.IconBooks $="w-[20px] h-[20px] stroke-[1.25]" />
          Open Library
        </Button>
        <CreateWorkspaceButton
          selectedFolder={undefined}
          button={
            <Button $="bg-background flex items-center gap-[4px] text-[#202734] font-medium text-xs hover:bg-background hover:text-[#202734] border border-[#D4D4D8] ml-[14px] h-[33px] rounded-full">
              <Ta.IconBrowserPlus $="w-[20px] h-[20px] stroke-[1.25]" />
              New Workspace
            </Button>
          }
        />
      </div>
    </header>
  );
};

const DropdownMenuSwitchOrgsItem = () => {
  const { currentUser } = useCurrentUser();
  const { org: currentOrg } = useCurrentOrg();
  const navigate = useNavigate();

  const otherOrgs = currentUser.users.filter(
    (user) => user.orgInfo.id !== currentOrg.id,
  );

  return (
    <>
      <DropdownMenuLabel $="flex items-center">
        Switch Organization
      </DropdownMenuLabel>
      {otherOrgs.map((user) => (
        <DropdownMenuItem
          key={user.orgInfo.id}
          $="flex items-center font-normal"
          onClick={() =>
            navigate({
              to: "/$org",
              params: { org: user.orgInfo.urlKey },
            })
          }
        >
          <Avatar $="w-6 h-6 mr-2">
            <AvatarImage
              $
              src={user.orgInfo.logoUrl?.toString()}
              alt={user.orgInfo.displayName}
            />
            <AvatarFallback $>
              {user.orgInfo.displayName.slice(0, 2).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          {user.orgInfo.displayName}
        </DropdownMenuItem>
      ))}
      {otherOrgs.length > 0 && <DropdownMenuSeparator $ />}
      <Link to="/create-organization" $>
        <DropdownMenuItem $="flex items-center font-normal">
          <Avatar $="w-6 h-6 mr-2">
            <AvatarFallback $>
              <Ta.IconPlus $="w-4 h-4" />
            </AvatarFallback>
          </Avatar>
          Create New Organization
        </DropdownMenuItem>
      </Link>
    </>
  );
};
