import { LinkedAssumptionChip } from "@/components/LinkedAssumptionChip";
import { LinkedPartyChip } from "@/components/LinkedPartyChip";
import { LinkedSourceChip } from "@/components/LinkedSourceChip";
import { LinkedStrategyChip } from "@/components/LinkedStrategyChip";
import { UserNameChip } from "@/components/UserNameChip";
import { Ta } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { whenStr } from "@phosphor/prelude";
import type {
  MarkdownText,
  OrgUserID,
  WorkspaceAssumptionID,
  WorkspacePartyID,
  WorkspaceSourceID,
  WorkspaceStrategyID,
} from "@phosphor/server";
import type { MVPWorkspaceCompacted, WorkspaceID } from "@phosphor/server";
import { deepEqual } from "@tanstack/react-router";
import { createColumnHelper } from "@tanstack/react-table";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { selectAtom } from "jotai/utils";
import moment from "moment";
import type React from "react";
import { createContext, forwardRef, useContext, useMemo } from "react";
import { SAMPLE_SOLAR } from "./sample-workspace-data";

// rows used with column helpers

export type AssumptionRow = {
  id: WorkspaceAssumptionID;
  name: string;

  // FUTURE: string | number; unit: string
  value: string;
  linkedSources: WorkspaceSourceID[];
  comments: MarkdownText;
  lastUpdated: string;
  lastUpdatedBy: OrgUserID | undefined;
  moduleId: string;
};

type DocumentRow = {
  id: WorkspaceSourceID;
  // icon: IconSpecifier;
  title: MarkdownText;
  linkedAssumptions: WorkspaceAssumptionID[];
  comments: MarkdownText;
  agreementPartys: WorkspacePartyID[];
  uploadedBy: OrgUserID | undefined;
  linkedStrategies: WorkspaceStrategyID[];
};

// Column helpers
const assumptionColumnHelper = createColumnHelper<AssumptionRow>();
const documentCoumnHelper = createColumnHelper<DocumentRow>();

// Header component for assumption columns
const ColumnTableHeading = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    Icon: typeof Ta.IconPoint;
  }
>(({ Icon, className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex gap-1 items-center text-xs whitespace-nowrap",
      className,
    )}
    {...props}
  >
    <Icon $="w-4 h-4" />
    {children}
  </div>
));

ColumnTableHeading.displayName = "ColumnTableHeading";

// Column definitions for assumptions
export const assumptionColumns = [
  // assumptionColumnHelper.display({
  //   id: "actions",
  //   cell: (props) => <AssumptionRowActions row={props.row} />,
  // }),
  assumptionColumnHelper.accessor("name", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconPoint}>
        Attributes
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  assumptionColumnHelper.accessor("value", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconGridScan}>
        Value
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  assumptionColumnHelper.accessor("linkedSources", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconFileSymlink}>
        Linked Source
      </ColumnTableHeading>
    ),
    cell: (info) => (
      <div $="flex flex-wrap gap-1">
        {info.getValue().map((sourceId) => (
          <LinkedSourceChip key={sourceId} sourceId={sourceId} />
        ))}
      </div>
    ),
  }),
  assumptionColumnHelper.accessor("comments", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconMessage2}>
        Comments
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  assumptionColumnHelper.accessor("lastUpdated", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconCalendarEvent}>
        Last Updated
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  assumptionColumnHelper.accessor("lastUpdatedBy", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconUserCircle}>
        Changed By
      </ColumnTableHeading>
    ),
    cell: (info) => {
      const userId = info.getValue();
      if (!userId) return null;
      return <UserNameChip userId={userId} />;
    },
  }),
];

// Column definitions for documents
export const documentColumns = [
  documentCoumnHelper.accessor("title", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconFile}>
        Source document
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  documentCoumnHelper.accessor("linkedAssumptions", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconLink}>
        Attributes
      </ColumnTableHeading>
    ),
    cell: (info) => (
      <div $="flex flex-wrap gap-1">
        {info.getValue().map((assumptionId) => (
          <LinkedAssumptionChip
            key={assumptionId}
            assumptionId={assumptionId}
          />
        ))}
      </div>
    ),
  }),
  documentCoumnHelper.accessor("comments", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconMessage2}>
        Comments
      </ColumnTableHeading>
    ),
    cell: (info) => info.getValue(),
  }),
  documentCoumnHelper.accessor("agreementPartys", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconUsers}>
        Agreement parties
      </ColumnTableHeading>
    ),
    cell: (info) => (
      <div $="flex flex-wrap gap-1">
        {info.getValue().map((partyId) => (
          <LinkedPartyChip key={partyId} partyId={partyId} />
        ))}
      </div>
    ),
  }),
  documentCoumnHelper.accessor("uploadedBy", {
    minSize: 240,
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconUpload}>
        Upload by
      </ColumnTableHeading>
    ),
    cell: (info) => {
      const userId = info.getValue();
      if (!userId) return null;
      return <UserNameChip userId={userId} />;
    },
  }),
  documentCoumnHelper.accessor("linkedStrategies", {
    header: () => (
      <ColumnTableHeading $ Icon={Ta.IconChartBar}>
        Strategy
      </ColumnTableHeading>
    ),
    cell: (info) => {
      const strategies = info.getValue();

      return (
        <div $="flex flex-wrap gap-1">
          {strategies.map((strategyId) => (
            <LinkedStrategyChip key={strategyId} strategyId={strategyId} />
          ))}
        </div>
      );
    },
  }),
];

// Jotai state management (React-agnostic)
const createDatabookState = (workspaceId: WorkspaceID) => {
  // Base atoms
  const databookAtom = atomWithQuery((get) => ({
    queryKey: ["databook", workspaceId],
    queryFn: async (): Promise<MVPWorkspaceCompacted> => {
      return SAMPLE_SOLAR;
    },
  }));

  const dataAtom = selectAtom(databookAtom, ({ data }) => data, deepEqual);
  const assumptionRowsAtom = atom((get) => {
    const data = get(dataAtom);
    if (!data) return [];

    const assumptions = data.assumptions.map(
      (assumption): AssumptionRow => ({
        id: assumption.id,
        moduleId: assumption.moduleId,
        name: assumption.displayLabel ?? "",
        value:
          assumption.format === "Date"
            ? moment(assumption.value).format("MMMM DD, YYYY")
            : String(assumption.value),
        linkedSources: data.assumptionSources
          .filter((a) => a.assumptionId === assumption.id)
          .map((a) => a.sourceId),
        comments: assumption.editorComments,
        lastUpdated: whenStr(assumption.lastChangedAt, formatDate),
        lastUpdatedBy: assumption.lastChangedBy,
      }),
    );

    const groupedAssumptions = Object.groupBy(
      assumptions,
      (assumption) => assumption.moduleId,
    );

    return data.modules
      .map(({ id, displayName }) => ({
        moduleId: id,
        title: displayName,
        assumptionRows: groupedAssumptions[id] ?? [],
      }))
      .filter((a) => a.assumptionRows.length > 0);
  });

  const documentRowsAtom = atom((get) => {
    const data = get(dataAtom);
    if (!data) return [];
    return data.sources.map(
      (source): DocumentRow => ({
        id: source.id,
        title: source.displayTitle,
        linkedAssumptions: data.assumptionSources
          .filter((a) => a.sourceId === source.id)
          .map((a) => a.assumptionId),
        comments: source.uploaderComments,
        agreementPartys: data.agreementPartySources
          .filter((a) => a.sourceId === source.id)
          .map((a) => a.partyId),
        uploadedBy: source.uploadedBy,
        linkedStrategies: data.strategySources
          .filter((a) => a.sourceId === source.id)
          .map((a) => a.strategyId),
      }),
    );
  });

  return {
    databookAtom,
    assumptionColumns,
    assumptionRowsAtom,
    documentColumns,
    documentRowsAtom,
  };
};

const formatDate = (date: moment.MomentInput) => {
  const momentDate = moment(date);
  const now = moment();
  if (now.diff(momentDate, "days") <= 3) {
    return momentDate.fromNow();
  }
  return momentDate.format("MM/DD/YYYY");
};

// React-specific code
type DatabookContextType = ReturnType<typeof createDatabookState>;

const DatabookContext = createContext<DatabookContextType | null>(null);

export const ProvideDatabookContext: React.FC<{
  children: React.ReactNode;
  workspaceId: WorkspaceID;
}> = ({ children, workspaceId }) => {
  const databookState = useMemo(
    () => createDatabookState(workspaceId),
    [workspaceId],
  );
  return (
    <DatabookContext.Provider value={databookState}>
      {children}
    </DatabookContext.Provider>
  );
};

export const useDatabook = () => {
  const context = useContext(DatabookContext);
  if (!context) {
    throw new Error(
      "useDatabookContext must be used within a ProvideDatabookContext",
    );
  }
  return context;
};
