import { AccountLogin } from "@/components/AccountLogin";
import { OrgRootSidebar } from "@/components/Globals/Sidebar";
import { OrgRootNavbar } from "@/components/OrgRootNavbar";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useCurrentUser } from "@/contexts/CurrentUserContext";
import { OrgURLKey } from "@phosphor/server";
import {
  Outlet,
  createFileRoute,
  useLocation,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useMemo, useState } from "react";
import {
  LayoutWithNavbar,
  LayoutWithSidebar,
} from "../components/LayoutWithSidebar";
import { CurrentOrgContext } from "../contexts/current-org";

export const Route = createFileRoute("/$org/_layout")({
  component: LayoutComponent,
  params: {
    parse: ({ org }) => ({ org: OrgURLKey.make(org) }),
    stringify: ({ org }) => ({ org: String(org) }),
  },
});

function AccessMessage({
  orgName,
  isLoggedIn,
}: {
  orgName: string;
  isLoggedIn: boolean;
}) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();

  return (
    <Card $="max-w-md mx-auto mt-8">
      <CardHeader $>
        <CardTitle $>
          {isLoggedIn ? `No Access to ${orgName}` : `Log into ${orgName}`}
        </CardTitle>
        <CardDescription $>
          {isLoggedIn
            ? "You don't have permission to access this organization."
            : "Please log in to access this organization."}
        </CardDescription>
      </CardHeader>
      <CardContent $="space-y-4">
        {isLoggedIn && (
          <p $>
            Please contact the organization administrator for assistance or log
            in with a different account.
          </p>
        )}
        {showLoginForm ? (
          <AccountLogin canSignup={false} />
        ) : (
          <div $="flex justify-between">
            <Button $ onClick={() => setShowLoginForm(true)}>
              {isLoggedIn ? "Login as Another Account" : "Login"}
            </Button>
            {isLoggedIn && (
              <Button $ variant="outline" onClick={() => navigate({ to: "/" })}>
                Return Home
              </Button>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function LayoutComponent() {
  const { org } = useParams({ from: "/$org" });
  const { currentUser } = useCurrentUser();
  const { pathname, searchStr } = useLocation();
  const navigate = useNavigate();

  const orgContext = useMemo(() => {
    const orgUser = currentUser.users.find(
      (user) => user.orgInfo.urlKey === org || user.orgInfo.id === org,
    );

    if (orgUser && orgUser.orgInfo.urlKey !== org) {
      // Replace only the org part in the current path
      const newPath = pathname.replace(`/${org}`, `/${orgUser.orgInfo.urlKey}`);
      setTimeout(() => {
        navigate({
          to: newPath + searchStr,
          replace: true,
        });
      }, 50);
    }

    return orgUser ? { org: orgUser.orgInfo, user: orgUser.userInfo } : null;
  }, [currentUser, org, navigate, pathname, searchStr]);

  if (!orgContext) {
    return <AccessMessage orgName={org} isLoggedIn={!!currentUser.account} />;
  }

  const isWorkspaceRoute = pathname.includes("/workspace/");
  return (
    <CurrentOrgContext.Provider value={orgContext}>
      {isWorkspaceRoute ? (
        <Outlet />
      ) : (
        <LayoutWithNavbar navbar={<OrgRootNavbar />}>
          <LayoutWithSidebar sidebar={<OrgRootSidebar />}>
            <Outlet />
          </LayoutWithSidebar>
        </LayoutWithNavbar>
      )}
    </CurrentOrgContext.Provider>
  );
}
