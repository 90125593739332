import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { toast } from "@/components/ui/use-toast";
import { useCurrentOrg } from "@/contexts/current-org";
import { useLoadedWorkspace } from "@/contexts/current-workspace";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import { cn } from "@/lib/utils";
import { WorkspaceAPI } from "@phosphor/server";
import {
  Link,
  type LinkProps,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import Logo from "../../public/Logo.svg?url";
import { AiShimmerButton } from "./Globals/AiShimmerButton";
import { UserAvatar } from "./UserNameChip";
import { LibraryItemChooser } from "./library-items/LibraryItemChooser";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { Ta } from "./ui/icons";
import { TabIconSvgs } from "./ui/tab-icon-svgs";
import { CreateWorkspaceProposalButton } from "./workspace/CreateWorkspaceProposalButton";
import { InviteWorkspaceCollaboratorsButton } from "./workspace/InviteWorkspaceCollaboratorsButton";
import { WorkspaceProposalListButton } from "./workspace/WorkspaceProposalListButton";

export const OrgWorkspaceNavbar = () => {
  const { org, user } = useCurrentOrg();
  const { workspaceDisplayName, workspaceId } = useLoadedWorkspace();
  const navigate = useNavigate();
  const { location } = useRouterState();
  const overview = location.pathname.includes("/workspace/");
  const modelBtn = location.pathname.includes("/model");
  const databookBtn = location.pathname.includes("/databook");
  const isOverview = overview && !databookBtn && !modelBtn;

  const saveChangesMutation = useRpcMutation({
    mutate: (input: WorkspaceAPI.SaveWorkspaceChanges) => input,
    onSuccess: () => {
      toast({
        title: "Changes Saved",
        description: "Your workspace changes have been saved successfully.",
      });
    },
    onError: (error) => toast({ error }),
  });

  const handleSaveChanges = () => {
    saveChangesMutation.mutate(
      new WorkspaceAPI.SaveWorkspaceChanges({
        workspaceId,
        changes: [], // TODO: Replace with actual unsaved changes
      }),
    );
  };

  return (
    <div $="sticky z-20 min-w-[800px] top-0 bg-[#FAF9FB]">
      <div $="px-4 md:px-6 w-full h-[80px] border border-b-[#D4D4D8] flex items-center justify-between">
        <div $="flex items-center">
          <div $="flex items-center border border-[#D4D4D8] bg-background rounded-sm px-3 h-12 w-full">
            <Link $ to="/">
              <img $="border-r border-r-[#D4D4D8] pr-2" src={Logo} alt="Logo" />
            </Link>
            <Link
              $="pl-3 pr-[5px] text-black"
              to="/$org/workspaces"
              params={{ org: org.urlKey }}
            >
              <Ta.IconArrowLeft $="w-4 h-4" />
            </Link>
            <h3 $="text-black text-[14px] font-medium leading-tight flex items-center pr-[11px]">
              {workspaceDisplayName}
            </h3>
          </div>
        </div>
        <div $="flex gap-1">
          <NavBarButtonLink
            $
            search
            to="/$org/workspace/$workspace"
            params={{ org: org.urlKey, workspace: workspaceId }}
            icon={<TabIconSvgs.TabGenius $ />}
            label="OVERVIEW"
            isActive={isOverview}
          />
          <NavBarButtonLink
            $
            search
            to="/$org/workspace/$workspace/model"
            params={{ org: org.urlKey, workspace: workspaceId }}
            icon={<TabIconSvgs.TabModel $ />}
            label="MODEL"
            isActive={modelBtn}
          />
          <NavBarButtonLink
            $
            search
            to="/$org/workspace/$workspace/databook"
            params={{ org: org.urlKey, workspace: workspaceId }}
            icon={<TabIconSvgs.TabDatabook $ />}
            label="DATABOOK"
            isActive={databookBtn}
          />
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger $>
            <UserAvatar
              $
              profileName={user.profileName}
              photoURL={user.photoURL?.toString()}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent $="m-2">
            <DropdownMenuLabel $>{user.displayName}</DropdownMenuLabel>
            <DropdownMenuSeparator $={""} />
            <DropdownMenuItem
              $
              onClick={() =>
                navigate({
                  to: "/$org/settings/profile",
                  params: { org: org.urlKey },
                  replace: true,
                })
              }
            >
              Profile
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div $="flex justify-between items-center border-b border-[#D4D4D8] h-[60px] px-6">
        <div $="h-[32px] flex gap-4 relative">
          <WorkspaceProposalListButton
            workspaceId={workspaceId}
            button={
              <Button
                $="px-3 h-full bg-background flex items-center justify-center gap-[4px] text-black font-semibold text-[12px] rounded-full border border-[#D4D4D8]"
                variant="outline"
              >
                <Ta.IconGitMerge $="w-4 h-4 -ml-1" /> Base Case{" "}
                <Ta.IconChevronDown $="w-4 h-4 -mr-1" />
              </Button>
            }
          />
          <div $="flex border border-[#D4D4D8] rounded-full">
            <CreateWorkspaceProposalButton
              workspaceId={workspaceId}
              button={
                <Button
                  $="pl-3 pr-2 gap-1 bg-background text-black font-semibold h-auto flex items-center hover:bg-background border-r border-r-[#D4D4D8] border-l border-l-transparent rounded-l-full"
                  variant="ghost"
                >
                  <Ta.IconArrowBearRight $="flex-none w-4 h-4 -ml-1" />
                  <span $="text-[12px] flex-grow">Branch</span>
                </Button>
              }
            />
            <Button
              $="pl-2 pr-3 gap-1 bg-background text-black font-semibold h-auto flex items-center hover:bg-background border-r border-r-transparent rounded-r-full"
              variant="ghost"
              onClick={handleSaveChanges}
            >
              <Ta.IconDownload $="w-4 h-4" />
              <span $="text-[12px] flex-none">Save</span>
            </Button>
          </div>
        </div>
        <div $="flex gap-2 h-[32px]">
          <Dialog>
            <DialogTrigger $ asChild>
              <Link $="px-3 h-full bg-background flex items-center justify-center gap-[4px] text-black font-semibold text-[12px] rounded-full border border-[#D4D4D8] p-0">
                <Ta.IconBooks $="-ml-1 w-5 h-5 stroke-[1.25]" />
                Library
              </Link>
            </DialogTrigger>
            <DialogContent $="bg-background p-0 rounded-3xl overflow-clip">
              <LibraryItemChooser
                className="w-full min-w-[80vw] h-[80svh]"
                onImportLibraryItem={(id) => {
                  alert(`Importing library item ${id}`);
                }}
                onImportBuiltinItem={(builtin) => {
                  alert(`Importing builtin item ${builtin}`);
                }}
              />
            </DialogContent>
          </Dialog>
          <InviteWorkspaceCollaboratorsButton
            workspaceId={workspaceId}
            button={
              <Button
                $="px-3 h-full bg-background flex items-center justify-center gap-[4px] text-black font-semibold text-[12px] rounded-full border border-[#D4D4D8]"
                variant="outline"
              >
                <Ta.IconUserPlus $="-ml-1 w-4 h-4 stroke-[1.25]" />
                Collaborators
              </Button>
            }
          />
          <AiShimmerButton />
        </div>
      </div>
    </div>
  );
};

type NavBarButtonLinkProps = LinkProps & {
  $: true;
  /** If provided, will override iconSrc */
  icon?: React.ReactNode;
  iconSrc?: string;
  label: string;
  isActive: boolean;
};

const NavBarButtonLink = ({
  icon,
  iconSrc,
  label,
  isActive,
  ...props
}: NavBarButtonLinkProps) => (
  <Link
    {...props}
    className={cn(
      "flex items-center rounded border",
      "h-12 px-4 gap-2",
      "text-xs font-medium uppercase leading-none tracking-wide",
      isActive
        ? "bg-[#EFEDF3] border-[#CAC2D6] text-[#62527A] shadow-sm"
        : "bg-transparent border-transparent text-neutral-500 hover:bg-[#EFEDF3]",
    )}
  >
    {icon
      ? icon
      : iconSrc && <img $="w-[32px] h-[32px]" src={iconSrc} alt="" />}
    {label}
  </Link>
);
