import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { type AssumptionRow, useDatabook } from "@/contexts/DatabookContext";
import { useRouteSearch } from "@/hooks/useRouteSearch";
import type { $IntentionalAny } from "@/lib/js/IntentionalAny.type";
import {
  type ColumnDef,
  type SortingState,
  type Updater,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";

export function AssumptionsTable(props: {
  route:
    | "/$org/_layout/admin/_layout/workspace/$workspace/databook"
    | "/$org/_layout/workspace/$workspace/_layout/databook";
}) {
  const { search, setSearch } = useRouteSearch(props.route);
  const { assumptionRowsAtom, assumptionColumns } = useDatabook();

  const groupedAssumptionRows = useAtomValue(assumptionRowsAtom);

  const assumptionSorting = useMemo(
    () => [...(search.assumptionSorting ?? [])],
    [search.assumptionSorting],
  );

  const onSortingChange = useCallback(
    (updater: Updater<SortingState>) => {
      setSearch({
        assumptionSorting:
          typeof updater === "function" ? updater(assumptionSorting) : updater,
      });
    },
    [setSearch, assumptionSorting],
  );

  return (
    <div $="space-y-8">
      {groupedAssumptionRows.map((group) => (
        <div
          $="rounded-md border bg-white overflow-hidden"
          key={group.moduleId}
        >
          <h3 $="text-lg font-semibold p-4 bg-gray-50 border-b border-gray-200">
            {group.title}
          </h3>
          <AssumptionTableGroup
            assumptionRows={group.assumptionRows}
            columns={assumptionColumns}
            sorting={assumptionSorting}
            onSortingChange={onSortingChange}
          />
        </div>
      ))}
    </div>
  );
}

function AssumptionTableGroup({
  assumptionRows,
  columns,
  sorting,
  onSortingChange,
}: {
  assumptionRows: AssumptionRow[];
  columns: ColumnDef<AssumptionRow, $IntentionalAny>[];
  sorting: SortingState;
  onSortingChange: (update: Updater<SortingState>) => void;
}) {
  const table = useReactTable({
    data: assumptionRows,
    columns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Table $>
      <TableHeader $>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow $ key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead $ key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody $>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            $
            key={row.id}
            data-state={row.getIsSelected() && "selected"}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell $ key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
