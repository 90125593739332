import { WorkspaceList } from "@/components/WorkspaceList";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { FolderAPI, type OrgFolderID } from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useCurrentOrg } from "../contexts/current-org";

export const Route = createFileRoute("/$org/_layout/workspaces")({
  component: Workspaces,
});

function Workspaces() {
  const { org } = useCurrentOrg();
  const [selectedFolder, setSelectedFolder] = useState<
    OrgFolderID | undefined
  >();
  const { data: folders } = useRpcQuery({
    queryKey: ["getFolders", org.id],
    request: new FolderAPI.GetOrgFolders({
      org: org.id,
    }),
  });

  useEffect(() => {
    if (folders && folders[0]?.folder_id) {
      setSelectedFolder(folders[0]?.folder_id);
    }
  }, [folders]);

  return (
    <div $="h-full min-w-[600px] w-[100%] mx-auto p-4 bg-white">
      <WorkspaceList
        folder={selectedFolder}
        orgID={org.id}
        orgURLKey={org.urlKey}
      />
    </div>
  );
}
