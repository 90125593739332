import { Suggested } from "@/components/Suggested";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/$org/_layout/explore")({
  component: DataExplorer,
});

import { useState } from "react";

function DataExplorer() {
  const [query, setQuery] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  return (
    <div $="w-full h-full bg-white p-4 space-y-4">
      <header $="flex justify-center bg-white">
        <div $="flex items-center space-x-3">
          <h2 $="text-sm font-medium text-[#766293] tracking-widest">SEARCH</h2>
          <div $="relative flex items-center">
            <Input
              type="text"
              placeholder="Type in a query name"
              $="pl-10 pr-10 w-80 h-10"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setShow(false);
              }}
            />
            <Ta.IconSearch $="absolute left-3 w-5 h-5 text-gray-500" />
          </div>
          <Ta.IconAdjustments
            $="w-5 h-5 cursor-pointer"
            strokeWidth={1.5}
            color="#483663"
          />
        </div>
      </header>
      {query || show ? (
        <div $="w-[100%] overflow-x-scroll">
          <div $="w-full">
            <table $="w-[1500px] bg-white border border-gray-300">
              <thead $>
                <tr $="bg-gray-100">
                  <th $="border-r p-2 text-left w-[150px] font-semibold text-xs"></th>
                  <th $="border-r p-2 text-left w-[200px] font-semibold text-xs">
                    Workspaces
                  </th>
                  <th $="border-r p-2 text-left w-[200px] font-semibold text-xs">
                    Address
                  </th>
                  <th $="border-r p-2 text-left w-[100px] font-semibold text-xs">
                    SqFt
                  </th>
                  <th
                    $="border-r p-2 text-left w-[180px] font-semibold text-xs"
                    colSpan={3}
                  >
                    Rent Assumption per sqft
                  </th>
                  <th
                    $="border-r p-2 text-left w-[180px] font-semibold text-xs"
                    colSpan={3}
                  >
                    Loan Interest Rate %
                  </th>
                  <th
                    $="p-2 text-left w-[180px] font-semibold text-xs"
                    colSpan={3}
                  >
                    PE Target Return %
                  </th>
                </tr>
              </thead>
              <tbody $>
                <tr $="border-b">
                  <td $="border-r p-2 font-semibold text-xs">Property A</td>
                  <td $="border-r p-2">
                    <span $="inline-block px-2 py-1 text-xs text-black bg-yellow-300 rounded">
                      M&A Shared model
                    </span>
                  </td>
                  <td $="border-r p-2 text-xs">937 Tilman Road</td>
                  <td $="border-r p-2 text-xs">20,0000</td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px] text-xs">
                    300
                  </td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px]"></td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px]"></td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="p-2 w-[60px]"></td>
                </tr>
                <tr $="border-b">
                  <td $="border-r p-2 font-semibold text-xs">Property B</td>
                  <td $="border-r p-2">
                    <div $="space-y-2">
                      <span $="inline-block px-2 py-1 text-xs text-black bg-yellow-300 rounded">
                        M&A Shared model
                      </span>
                      <span $="inline-block px-2 py-1 text-xs text-black bg-blue-200 rounded">
                        Internal PE model
                      </span>
                    </div>
                  </td>
                  <td $="border-r p-2 text-xs">123 Executive Drive</td>
                  <td $="border-r p-2 text-xs">10,0000</td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px] text-xs">
                    200
                  </td>
                  <td $="border-r p-2 bg-[#BADCFA] text-left w-[60px] text-xs">
                    220
                  </td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px]"></td>
                  <td $="border-r p-2 bg-[#BADCFA] text-left w-[60px] text-xs">
                    7%
                  </td>
                  <td $="border-r p-2 w-[60px]"></td>
                  <td $="border-r p-2 bg-[#FCE6B1] text-left w-[60px]"></td>
                  <td $="border-r p-2 bg-[#BADCFA] text-left w-[60px] text-xs">
                    10.8%
                  </td>
                  <td $="p-2 w-[60px]"></td>
                </tr>
                <tr $>
                  <td $="border-r p-2 font-semibold text-xs">Property C</td>
                  <td $="border-r p-2">
                    <div $="space-y-2">
                      <span $="inline-block px-2 py-1 text-xs text-black bg-yellow-300 rounded">
                        M&A Shared model
                      </span>
                      <span $="inline-block px-2 py-1 text-xs text-black bg-blue-200 rounded">
                        Internal PE model
                      </span>
                      <span $="inline-block px-2 py-1 text-xs text-black bg-green-200 rounded">
                        Lender Shared model
                      </span>
                    </div>
                  </td>
                  <td $="border-r p-2 text-xs">111 Kearny Street</td>
                  <td $="border-r p-2 text-xs">7,0000</td>
                  <td $="border-r p-2 text-xs text-left w-[60px] bg-gradient-to-b from-[#FCE6B1] to-white">
                    150
                  </td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#BADCFA] to white text-left w-[60px]"></td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#C1F4CD] to white text-left w-[60px]"></td>
                  <td $="border-r p-2 text-left w-[60px] bg-gradient-to-b from-[#FCE6B1] to-white"></td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#BADCFA] to white text-left w-[60px] text-xs">
                    7%
                  </td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#C1F4CD] to white text-left w-[60px] text-xs">
                    6.5%
                  </td>
                  <td $="border-r p-2 text-left w-[60px] bg-gradient-to-b from-[#FCE6B1] to-white"></td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#BADCFA] to white text-left w-[60px]"></td>
                  <td $="border-r p-2 bg-gradient-to-b from-[#C1F4CD] to white text-left w-[60px] text-xs">
                    8%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          <Suggested setIsActive={setShow} />
        </>
      )}
    </div>
  );
}
