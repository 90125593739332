import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Lu, Ta } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";
import {
  type ChangeEvent,
  type KeyboardEvent,
  useEffect,
  useState,
} from "react";
import shimmerAnalyst from "/../public/shimmer_analyst.svg?url";
import logo from "../../../public/Logo.svg?url";
import avatar from "../../../public/avatar.svg?url";
import oliver from "../../../public/oliver.jpeg?url";

AiShimmerButton.displayName = "AiShimmerButton";
export function AiShimmerButton({ className = "" }) {
  const [isShimmerOpen, setShimmerIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [showSecondary, setShowSecondry] = useState<boolean>(false);
  const currentURL = `${window.location.protocol}//${window.location.host}`;
  const { pathname } = useLocation();

  // Handler for input change
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // Update state with the new value
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setShow(true); // Set show to true when Enter is pressed
    }
  };

  useEffect(() => {
    if (pathname.includes("workspace")) {
      setShowSecondry(true);
    } else {
      setShowSecondry(false);
    }
  }, [pathname]);

  // useEffect(() => {
  //   if (inputValue !== "") {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  // }, [inputValue]);

  return (
    <>
      <Dialog open={isShimmerOpen} onOpenChange={setShimmerIsOpen}>
        <DialogTrigger $ asChild>
          <button
            $={cn(
              "p-2 border bg-white border-gray-300 rounded",
              "flex gap-1 items-center justify-between",
              className,
            )}
            type="button"
          >
            <img $ src={shimmerAnalyst} alt="" />
            <span $="text-xs text-neutral-400 font-normal">⌘K</span>
          </button>
        </DialogTrigger>
        <DialogContent $="overflow-x-scroll no-scrollbar p-0">
          <DialogHeader $>
            <DialogTitle $></DialogTitle>
            <DialogDescription $>
              {showSecondary ? (
                <>
                  <div
                    $={`relative w-[640px] mx-auto mt-[-6px] ${
                      show && "min-h-[525px]"
                    }`}
                  >
                    <div $="flex justify-between mb-5">
                      <div $="w-full flex row gap-1 px-4 py-3 bg-[#FAF9FB] border-b border-gray-300">
                        <img $ src={logo} />
                        <img $ src={shimmerAnalyst} />
                      </div>
                    </div>
                    {show && (
                      <>
                        <div $="flex items-center mt-4 mb-4 px-4">
                          <img
                            src={oliver}
                            alt="Profile"
                            $="w-10 h-10 rounded-full mr-4"
                          />
                          <h1 $="text-small bg-[#F4F4F5] p-[8px] rounded-lg text-black ">
                            Based on this workspace, I’d like you to create a
                            web application for a solar blue book
                          </h1>
                        </div>
                        <div $="flex px-4">
                          <img src={avatar} alt="Profile" $="w-10 h-10 mr-4" />
                          <div $=" p-4 bg-white rounded-lg shadow">
                            <p $="mb-2 text-sm text-gray-800">
                              No problem. Here's a url to Solar Blue Book.
                              Please note data is public in this link.
                            </p>
                            <Link
                              to={`${currentURL}/jkco/solarbluebook`}
                              $="flex items-center text-sm text-blue-600 hover:underline"
                            >
                              {`${currentURL}/jkco/solarbluebook`}
                              <Lu.ArrowRight className="w-4 h-4 ml-1" />
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div $="sticky bottom-[-21px] left-0 -md mt-3">
                    <div $="w-100 mx-auto p-4 flex gap-2">
                      <input
                        type="text"
                        value={inputValue} // Bind the input value to the state
                        onChange={handleInputChange} // Update state on input change
                        onKeyDown={handleKeyDown}
                        $={`${
                          show ? "w-[80%]" : "w-[100%]"
                        } p-2 border border-[#202734] rounded-lg bg-background shadow-[0px_1px_4px_1px_rgba(0,0,0,0.08)]`}
                        placeholder="Follow up with another prompt"
                      />

                      {show && (
                        <div $="w-[20%] h-100 border border-[#6B5889] p-1 border-dashed border-1 rounded-sm flex flex-col justify-center items-center">
                          <div $="flex gap-2 mb-[2px]">
                            <Ta.IconFileUpload
                              $="w-[16px] h-[16px] stroke-1"
                              color="#6B5889"
                            />
                            <Ta.IconPhotoPlus
                              $="w-[16px] h-[16px] stroke-1"
                              color="#6B5889"
                            />
                          </div>
                          <p $="font-medium text-[8px] text-[#6B5889] uppercase leading-3 tracking-wide">
                            Attach or drop Files
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div $="relative w-[640px] mx-auto mt-[-6px] min-h-[525px]">
                    <div $="flex justify-between mb-5">
                      <div $="w-full flex row gap-1 px-4 py-3 bg-[#FAF9FB] border-b border-gray-300">
                        <img $ src={logo} />
                        <img $ src={shimmerAnalyst} />
                      </div>
                    </div>
                    {show && (
                      <>
                        <div $="flex items-center mt-4 mb-4 px-4">
                          <img
                            src={oliver}
                            alt="Profile"
                            $="w-10 h-10 rounded-full mr-4"
                          />
                          <h1 $="text-small bg-[#F4F4F5] p-[8px] rounded-lg text-black ">
                            Create a walk of changes between the closing model,
                            and scenarios for new panels and interest rate
                          </h1>
                        </div>
                        <div $="flex px-4">
                          <img src={avatar} alt="Profile" $="w-10 h-10 mr-4" />
                          <div $="bg-background p-4 rounded-lg border border-gray-300 w-[100%]">
                            <h2 $="text-sm font-medium mb-3 text-black">
                              Here's an updated analysis
                            </h2>
                            <div $="grid grid-cols-3 gap-4">
                              <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
                                <h3 $="text-xs font-semibold mb-2 text-black">
                                  Closing model on acquisition date
                                </h3>
                                <ul $="text-xs text-neutral-600">
                                  <li $>Pre Tax IRR: 7.1%</li>
                                  <li $>Tax Equity IRR: 6.2%</li>
                                  <li $>Total Investment: 8.3%</li>
                                  <li $>Developer Profit: 10.5%</li>
                                </ul>
                              </div>
                              <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
                                <h3 $="text-xs font-semibold mb-2 text-black">
                                  Base case
                                </h3>
                                <ul $="text-xs text-neutral-600">
                                  <li $>Pre Tax IRR: 7.3%</li>
                                  <li $>Tax Equity IRR: 6.5%</li>
                                  <li $>Total Investment: 8.6%</li>
                                  <li $>Developer Profit: 10.8%</li>
                                </ul>
                              </div>
                              <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
                                <h3 $="text-xs font-semibold mb-2 text-black">
                                  Scenario: New Solar Panels
                                </h3>
                                <ul $="text-xs text-neutral-600">
                                  <li $>Assumes installed cost reduction</li>
                                  <li $>MW Cost: 1.5</li>
                                  <li $>WKP (P50): 8.0</li>
                                  <li $>Degradation: 1.0%</li>
                                  <li $>DSCR (Avg): 1.47</li>
                                </ul>
                              </div>
                              <div $="bg-background p-4 rounded-lg shadow-sm col-span-3 border border-gray-200">
                                <h3 $="text-xs font-semibold mb-2 text-black">
                                  Sensitivity: Interest Rate (5% Base Metric)
                                </h3>
                                <ul $="text-xs text-neutral-600">
                                  <li $>Changed S&EA's Bids: 5.0%</li>
                                  <li $>
                                    Sensitivity on: IRR, Developer Profit
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div $="sticky bottom-[-21px] left-0 -md mt-3">
                    <div $="w-100 mx-auto p-4 flex gap-2">
                      <input
                        type="text"
                        value={inputValue} // Bind the input value to the state
                        onChange={handleInputChange} // Update state on input change
                        onKeyDown={handleKeyDown}
                        $="w-[80%] p-2 border border-[#202734] rounded-lg bg-background shadow-[0px_1px_4px_1px_rgba(0,0,0,0.08)]"
                        placeholder="Follow up with another prompt"
                      />

                      {/* {show && ( */}
                      <div $="w-[20%] h-100 border border-[#6B5889] p-1 border-dashed border-1 rounded-sm flex flex-col justify-center items-center">
                        <div $="flex gap-2 mb-[2px]">
                          <Ta.IconFileUpload
                            $="w-[16px] h-[16px] stroke-1"
                            color="#6B5889"
                          />
                          <Ta.IconPhotoPlus
                            $="w-[16px] h-[16px] stroke-1"
                            color="#6B5889"
                          />
                        </div>
                        <p $="font-medium text-[8px] text-[#6B5889] uppercase leading-3 tracking-wide">
                          Attach or drop Files
                        </p>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </>
              )}
              {/* <div $="relative w-[640px] mx-auto mt-[-6px] min-h-[525px]"> */}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
}
