import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { useCurrentOrg } from "@/contexts/current-org";
import { useStorageValue } from "@/hooks/useStorageValue";
import { S } from "@phosphor/prelude";
import type { WorkspaceID } from "@phosphor/server";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export const SetupCurrentWorkspaceDialog = ({
  workspaceId,
  button,
}: { workspaceId: WorkspaceID; button?: React.ReactNode }) => {
  const [isSetup, setIsSetup] = useStorageValue(
    `workspace/${workspaceId}/setup`,
    localStorage,
    () => ({ isSetup: S.Boolean }),
  );
  const hasBeenSetUpAlready = isSetup?.isSetup === true;
  const [isOpen, setIsOpen] = useState(!hasBeenSetUpAlready);
  const { org } = useCurrentOrg();
  const navigate = useNavigate();
  const markAsSetup = () => {
    setIsSetup({ isSetup: true });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) markAsSetup();
        setIsOpen(open);
      }}
    >
      <DialogTrigger asChild $>
        {button}
      </DialogTrigger>
      <DialogContent $="bg-background">
        <DialogHeader $>
          <DialogTitle $="text-[#0F172A] text-[18px] font-semibold">
            Setup your workspace
          </DialogTitle>
          <DialogDescription $>
            <div $="mt-4">
              <p $="text-black text-base font-normal">
                To get started create a new model or import a model from your
                library.
              </p>
            </div>
            <div $="flex justify-end mt-6">
              <Button $="bg-white text-[#0F172A] hover:bg-white hover:text-[#0F172A] flex justify-center border border-[#D4D4D8] rounded-full ">
                <Ta.IconBooks $="w-[20px] h-[20px]" />
                Import Modules
              </Button>
              <Button
                $="ml-2 bg-[#0F172A] text-white rounded-full flex justify-center hover:bg-[#0F172A] hover:text-white"
                onClick={() => {
                  markAsSetup();
                  return navigate({
                    to: "/$org/workspace/$workspace/model",
                    params: { org: org.urlKey, workspace: workspaceId },
                  });
                }}
              >
                Create New Model
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
