import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { withPosthog } from "./lib/dev/posthog";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

const { releaseTag, relatedSentry } = BUILD_DEPLOY_INFO;
const { sentryDSN } = BUILD_EDITOR_ENV;

console.warn("TODO: Sentry trace propagation for API calls");

// Create a new router instance
const router = createRouter({ routeTree });

if (relatedSentry && sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    release: releaseTag,
    integrations: [
      Sentry.browserTracingIntegration(),
      // https://docs.sentry.io/platforms/javascript/guides/react/features/tanstack-router/
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      // See https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
      // Consider: Revisit this section of the docs and double check settings...
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: [relatedSentry.editorWebui.applicationKey],

        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: "drop-error-if-contains-third-party-frames",
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      // /^https:\/\/yourserver\.io\/api/
    ],
    // FUTURE: Consider enabling session replay if we want to use it more than PostHog session replay
    // integrations: [Sentry.replayIntegration()],
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const sessionReplayURL = withPosthog((ph) => ph.get_session_replay_url());
  if (sessionReplayURL) {
    // Add session replay URL to Sentry
    Sentry.addBreadcrumb({
      message: "Posthog Recording URL",
      level: "info",
      data: { url: sessionReplayURL },
    });
  }
}

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient();
// Render the app
const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </StrictMode>,
);
