import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

import { AccountAPI, LoginAPI, type LoginHandoffKey } from "@phosphor/server";
import { useRouter } from "@tanstack/react-router";
import { Duration } from "effect";
import { useState } from "react";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { useNotifications } from "../hooks/notifications";
import { useRpcMutation, useRpcQuery } from "../hooks/use-rpc-hooks";
import { useCheckEffect } from "../hooks/useCheckEffect.js";

interface AccountLoginProps {
  canSignup: boolean;
}

export const AccountLogin = ({ canSignup }: AccountLoginProps) => {
  const router = useRouter();
  const { setAccount } = useCurrentUser();
  const notifications = useNotifications();
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [currentHandoff, setCurrentHandoff] =
    useState<LoginAPI.LoginHandoffCreated | null>(null);

  const createAccountWithPassword = useRpcMutation({
    mutate({ email, password }: { email: string; password: string }) {
      return new AccountAPI.CreateAccountWithPasswordLogin({
        displayName: email.split("@")[0].replace(/\./g, " "),
        username: email,
        securityEmail: email,
        password: password,
      });
    },
    onSuccess(data) {
      setAccount({
        id: data.id,
        displayHint: data.displayHint,
        accessToken: data.accessToken,
      });
    },
  });

  const handleUsernamePasswordSignup = (e: React.FormEvent) => {
    e.preventDefault();
    createAccountWithPassword.mutate({
      email: signUpEmail,
      password: signUpPassword,
    });
  };

  const { data: workOSLoginData } = useRpcQuery({
    queryKey: ["workos-login-handoff"],
    refetchInterval: Duration.seconds(30).pipe(Duration.toMillis),
    request: new LoginAPI.CreateLoginHandoff({
      expiresAfter: Duration.seconds(60),
    }),
  });

  const checkWorkOSLogin = useRpcMutation({
    mutate: (handoffKey: LoginHandoffKey) =>
      new LoginAPI.CheckLoginHandoff({ handoffKey }),
  });

  useCheckEffect(() => {
    if (currentHandoff) {
      const handoffKey = currentHandoff.handoffKey;
      return () => {
        checkWorkOSLogin.mutate(handoffKey, {
          onSuccess(data) {
            if (data._tag === "HandoffCompleted") {
              console.log("WorkOS login handoff completed", data);
              setCurrentHandoff(null);
              router.invalidate().finally(() => {
                setAccount({
                  id: data.accountID,
                  displayHint: data.accountLoginDisplayHint,
                  accessToken: data.accessToken,
                });
              });
            } else if (data._tag === "HandoffExpiredOrMissing") {
              setCurrentHandoff(null);
            }
          },
          onError(error) {
            console.error("Error checking WorkOS login", error);
          },
        });
      };
    }
  }, [checkWorkOSLogin.mutate, currentHandoff, setAccount]);

  const loginWithEmail = useRpcMutation({
    mutate({
      signInEmail,
      signInPassword,
    }: {
      signInEmail: string;
      signInPassword: string;
    }) {
      return new AccountAPI.LoginWithEmail({
        email: signInEmail,
        password: signInPassword,
      });
    },
    onSuccess(data) {
      setAccount({
        id: data.id,
        displayHint: data.displayHint,
        accessToken: data.accessToken,
      });
    },
  });

  const handleEmailSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    loginWithEmail.mutate({ signInEmail, signInPassword });
  };

  const renderLoginForm = () => (
    <Card $>
      <CardHeader $>
        <CardTitle $>Login</CardTitle>
        <CardDescription $>
          Enter your email and password to login your account.
        </CardDescription>
      </CardHeader>
      <CardContent $="space-y-2">
        <div $="space-y-1">
          <Label $ htmlFor="signin-email">
            Email
          </Label>
          <Input
            $
            id="signin-email"
            type="email"
            value={signInEmail}
            placeholder="example@example.com"
            onChange={(e) => setSignInEmail(e.target.value)}
          />
        </div>
        <div $="space-y-1">
          <Label $ htmlFor="signin-password">
            Password
          </Label>
          <Input
            $
            id="signin-password"
            type="password"
            value={signInPassword}
            placeholder="**********"
            onChange={(e) => setSignInPassword(e.target.value)}
          />
        </div>
      </CardContent>
      <CardFooter $="flex justify-between">
        <Button $ onClick={handleEmailSignIn}>
          Login
        </Button>
      </CardFooter>
    </Card>
  );

  const renderSignupForm = () => (
    <Card $>
      <CardHeader $>
        <CardTitle $>Signup</CardTitle>
        <CardDescription $>
          Enter your email and password to create an account
        </CardDescription>
      </CardHeader>
      <CardContent $="space-y-2">
        <div $="space-y-1">
          <Label $ htmlFor="signup-email">
            Email
          </Label>
          <Input
            $
            id="signup-email"
            type="email"
            value={signUpEmail}
            placeholder="example@example.com"
            onChange={(e) => setSignUpEmail(e.target.value)}
          />
        </div>
        <div $="space-y-1">
          <Label $ htmlFor="signup-password">
            Password
          </Label>
          <Input
            $
            id="signup-password"
            type="password"
            value={signUpPassword}
            placeholder="*********"
            onChange={(e) => setSignUpPassword(e.target.value)}
          />
        </div>
      </CardContent>
      <CardFooter $>
        <Button $ onClick={handleUsernamePasswordSignup}>
          Create Account
        </Button>
      </CardFooter>
    </Card>
  );

  return (
    <div $="space-y-4 flex flex-col items-center">
      {canSignup ? (
        <Tabs defaultValue="login" $="w-[400px]">
          <TabsList $="grid w-full grid-cols-2">
            <TabsTrigger $ value="login">
              Login
            </TabsTrigger>
            <TabsTrigger $ value="signup">
              Signup
            </TabsTrigger>
          </TabsList>
          <TabsContent $ value="login">
            {renderLoginForm()}
          </TabsContent>
          <TabsContent $ value="signup">
            {renderSignupForm()}
          </TabsContent>
        </Tabs>
      ) : (
        renderLoginForm()
      )}
      {workOSLoginData && (
        <a
          $="text-black text-center font-medium cursor-pointer mt-4 transition-opacity duration-300 ease-in-out opacity-100 p-4"
          href={workOSLoginData.loginURL.toString()}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setCurrentHandoff(workOSLoginData)}
        >
          Staff Login
        </a>
      )}
    </div>
  );
};
