import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { SAMPLE_DATA, SAMPLE_SOLAR } from "@/contexts/sample-workspace-data";
import type { WorkspacePartyID } from "@phosphor/server";
import type React from "react";
import { LinkedSourceChip } from "./LinkedSourceChip";

interface LinkedPartyChipProps {
  partyId: WorkspacePartyID;
}

export const LinkedPartyChip: React.FC<LinkedPartyChipProps> = ({
  partyId,
}) => {
  const sampleParty = Object.values(SAMPLE_DATA.parties).find(
    (party) => party.id === partyId,
  );

  const displayData = sampleParty ?? {
    displayName: "Unknown Party",
  };

  // Find all sources associated with this party
  const associatedSources = SAMPLE_SOLAR.agreementPartySources
    .filter((aps) => aps.partyId === partyId)
    .map((aps) => ({
      sourceId: aps.sourceId,
      comment: aps.comment,
    }));

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center text-green-600 px-2 py-1 text-xs cursor-pointer underline">
          {displayData.displayName}
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-80">
        <div $="space-y-2">
          <h4 $="text-sm font-semibold">{displayData.displayName}</h4>
          {associatedSources.length > 0 && (
            <div $="space-y-1">
              <p $="text-xs font-medium">Associated Documents:</p>
              {associatedSources.map((source) => (
                <div $ key={source.sourceId}>
                  <LinkedSourceChip sourceId={source.sourceId} />
                  <p $="text-xs ml-4 mt-1">{source.comment}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
