import { S } from "@phosphor/prelude";
import { AppRequestFailures, OrgFolderID, OrgID, OrgUserID } from "../common";
import {
  LibraryItemID,
  MvpBuiltinLibraryItemID,
  WorkspaceChangesetID,
} from "../workspace-common";

// Library Item Info
export class LibraryItemInfo extends S.Class<LibraryItemInfo>(
  "LibraryItemInfo",
)({
  id: LibraryItemID,
  orgId: OrgID,
  name: S.String,
  description: S.String,
  createdBy: OrgUserID,
  createdAt: S.DateFromNumber,
  updatedAt: S.DateFromNumber,
}) {}

// Library Item creation
export class CreateLibraryItem extends S.TaggedRequest<CreateLibraryItem>()(
  "CreateLibraryItem",
  {
    payload: {
      orgId: OrgID,
      name: S.String,
      description: S.String,
      changesetIds: S.Array(WorkspaceChangesetID),
      /** e.g. "SOLAR_PROJECT", this could create a new changeset for the sole purpose of importing an MVP libary item stub */
      mvpBuiltinLibraryItemID: MvpBuiltinLibraryItemID.pipe(S.optional),
    },
    success: LibraryItemInfo,
    failure: AppRequestFailures,
  },
) {}

// Library Item retrieval
export class LibraryItemsRetrieved extends S.Class<LibraryItemsRetrieved>(
  "LibraryItemsRetrieved",
)({
  items: S.Array(LibraryItemInfo),
}) {}

export class GetLibraryItems extends S.TaggedRequest<GetLibraryItems>()(
  "GetLibraryItems",
  {
    payload: {
      orgId: OrgID,
    },
    success: LibraryItemsRetrieved,
    failure: AppRequestFailures,
  },
) {}

// Single Library Item retrieval
export class LibraryItemWithChangesets extends S.Class<LibraryItemWithChangesets>(
  "LibraryItemWithChangesets",
)({
  item: LibraryItemInfo,
  changesetIds: S.Array(WorkspaceChangesetID),
}) {}

export class GetLibraryItem extends S.TaggedRequest<GetLibraryItem>()(
  "GetLibraryItem",
  {
    payload: {
      orgId: OrgID,
      itemId: LibraryItemID,
    },
    success: LibraryItemWithChangesets,
    failure: AppRequestFailures,
  },
) {}

// Library Item update
export class UpdateLibraryItem extends S.TaggedRequest<UpdateLibraryItem>()(
  "UpdateLibraryItem",
  {
    payload: {
      orgId: OrgID,
      itemId: LibraryItemID,
      name: S.String.pipe(S.optional),
      description: S.String.pipe(S.optional),
      changesetIds: S.Array(WorkspaceChangesetID).pipe(S.optional),
    },
    success: LibraryItemWithChangesets,
    failure: AppRequestFailures,
  },
) {}

// Library Item deletion
export class DeleteLibraryItem extends S.TaggedRequest<DeleteLibraryItem>()(
  "DeleteLibraryItem",
  {
    payload: {
      orgId: OrgID,
      itemId: LibraryItemID,
    },
    success: S.Void,
    failure: AppRequestFailures,
  },
) {}

// Create Workspace from Library Items
export class WorkspaceCreatedFromLibraryItems extends S.Class<WorkspaceCreatedFromLibraryItems>(
  "WorkspaceCreatedFromLibraryItems",
)({
  workspaceId: S.UUID,
  workspaceDisplayName: S.String,
}) {}

export class CreateWorkspaceFromLibraryItems extends S.TaggedRequest<CreateWorkspaceFromLibraryItems>()(
  "CreateWorkspaceFromLibraryItems",
  {
    payload: {
      orgId: OrgID,
      libraryItemIds: S.Array(LibraryItemID),
      workspaceName: S.String,
      folder: OrgFolderID.pipe(S.optional),
    },
    success: WorkspaceCreatedFromLibraryItems,
    failure: AppRequestFailures,
  },
) {}
