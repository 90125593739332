import { Ta } from "@/components/ui/icons";
import { openInDevEditor } from "@/lib/dev/openInDevEditor";
import { ErrorBoundary } from "@sentry/react";

export const AppErrorBoundary = ({
  children,
}: { children: React.ReactNode }) => {
  // TODO: Link up to dev mode
  const isDev = process.env.NODE_ENV !== "production";
  return (
    <ErrorBoundary
      showDialog
      fallback={({ error, resetError, componentStack }) => {
        return (
          <div $="p-4 mx-auto max-w-3xl w-full">
            <div $="rounded-lg border border-red-200 bg-red-50 p-4">
              <div $="flex items-center gap-3">
                <div $="rounded-full bg-red-100 p-2">
                  <Ta.IconAlertCircle $="w-5 h-5 text-red-600" />
                </div>
                <h2 $="text-lg font-semibold text-red-900">
                  An unexpected error occurred
                </h2>
              </div>

              {isDev && (
                <>
                  {error instanceof Error ? (
                    <>
                      <p $="mt-4 text-sm text-red-700 font-mono">
                        {error.message}
                      </p>
                      {error.stack && (
                        <pre $="mt-2 p-3 text-xs bg-red-100 rounded overflow-auto">
                          {error.stack}
                        </pre>
                      )}
                    </>
                  ) : (
                    <>
                      <p $="mt-4 text-sm text-red-700 font-mono">
                        {String(error)}
                      </p>
                    </>
                  )}

                  <br $ />
                  <details $="mt-2">
                    <summary $="text-sm font-semibold">Component Stack</summary>
                    <pre $="mt-2 p-3 text-xs bg-red-100 rounded overflow-auto">
                      {...componentStack
                        .split(/\n/g)
                        .map((a) => a.replace(/http:\/\/[^/]+/, "").trim())
                        .map((a) => {
                          const isDep = /@fs/.test(a);
                          const [_, path, line] = a.split(" ");
                          const open = () =>
                            openInDevEditor(
                              line
                                .replace(/^\((?:\/@fs|\/)?|\)$/g, "")
                                .replace(/\?v=\w+([:\d]+)$/, "$1")
                                .replace(/^([^\/])/, "src/$1")
                                .trim(),
                            );
                          return (
                            <button
                              $={`block ${isDep ? "text-black/40" : "font-bold"}`}
                              type="button"
                              onClick={open}
                            >
                              {path} {line}
                            </button>
                          );
                        })}
                    </pre>
                  </details>
                </>
              )}

              {!isDev && (
                <p $="mt-2 text-sm text-red-700">
                  Please try refreshing the page. If the problem persists,
                  contact support for assistance.
                </p>
              )}

              <button $="mt-4" onClick={() => resetError()}>
                Reset
              </button>
            </div>
          </div>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
