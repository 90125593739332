import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useDatabook } from "@/contexts/DatabookContext";
import { useRouteSearch } from "@/hooks/useRouteSearch";
import {
  type SortingState,
  type Updater,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";

export function DocumentsTable() {
  const { search, setSearch } = useRouteSearch(
    // "/$org/_layout/admin/_layout/workspace/$workspace/databook",
    "/$org/_layout/workspace/$workspace/_layout/databook",
  );
  const { documentRowsAtom, documentColumns } = useDatabook();

  const documentRows = useAtomValue(documentRowsAtom);

  const documentSorting = useMemo(
    () => [...(search.documentSorting ?? [])],
    [search.documentSorting],
  );

  const table = useReactTable({
    data: documentRows,
    columns: documentColumns,
    state: { sorting: documentSorting },
    onSortingChange: useCallback(
      (updater: Updater<SortingState>) => {
        setSearch({
          documentSorting:
            typeof updater === "function" ? updater(documentSorting) : updater,
        });
      },
      [setSearch, documentSorting],
    ),
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div $="rounded-md border overflow-hidden">
      <Table $>
        <TableHeader $="bg-gray-50 border-b border-gray-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow $ key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead $ key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody $>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              $
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell $ key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
