import type { OrgInfo, OrgUserInfo } from "@phosphor/server";
import { createContext, useContext } from "react";

interface CurrentOrgContextType {
  org: OrgInfo;
  user: OrgUserInfo;
}

export const CurrentOrgContext = createContext<CurrentOrgContextType | null>(
  null,
);

export const useCurrentOrg = (): CurrentOrgContextType => {
  const context = useContext(CurrentOrgContext);
  if (!context) {
    throw new Error(
      "useCurrentOrg must be used within the $org/ route (provided by the $org._layout.tsx file)",
    );
  }
  return context;
};

export const useCurrentOrgOptional = (): CurrentOrgContextType | null => {
  return useContext(CurrentOrgContext);
};
