import { Button } from "../ui/button";

interface ImportLibraryItemHeaderProps {
  title: string;
  description: string;
  lastSavedDate: string;
  onDelete: () => void;
  onImport?: () => void | null;
}

export const ImportLibraryItemHeader: React.FC<
  ImportLibraryItemHeaderProps
> = ({ title, description, lastSavedDate, onDelete, onImport }) => {
  return (
    <div $="mb-6">
      <div $="flex justify-between items-center mb-4">
        <h2 $="text-2xl font-semibold">{title}</h2>
        <div $="flex space-x-2">
          <Button $="text-red-500" variant="outline" onClick={onDelete}>
            Delete
          </Button>
          {onImport && (
            <Button $ variant="default" onClick={onImport}>
              Import
            </Button>
          )}
        </div>
      </div>
      <p $="text-sm text-gray-600 mb-1">{description}</p>
      <p $="text-xs text-gray-400">Last Saved: {lastSavedDate}</p>
    </div>
  );
};
