import { useStore } from "jotai";
import React from "react";
import { tightJavaScriptify } from "../js/tightJavaScriptify";
import { cn } from "../utils";

export const DevPrint = React.memo(
  ({
    value,
    className,
    ...props
  }: {
    value: any;
  } & React.HTMLProps<HTMLPreElement>) => {
    const store = useStore();
    return (
      <pre {...props} className={cn(className, "p-1 whitespace-pre-wrap")}>
        {tightJavaScriptify(value, store.get)}
      </pre>
    );
  },
);
DevPrint.displayName = "DevPrint";
