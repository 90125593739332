import { AppFailureCard } from "@/components/AppFailureDisplay";
import { BUILTIN_LIBRARY_ITEMS } from "@/components/library-items/BUILTIN_LIBRARY_ITEMS";
import { ImportLibraryItemHeader } from "@/components/library-items/ImportLibraryItemHeader";
import { MockImportLibraryItemSectioned } from "@/components/library-items/MockImportLibraryItemSectioned";
import { SAMPLE_PROJECT_CO } from "@/components/library-items/SAMPLE_PROJECT_CO";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Spinner } from "@/components/ui/spinner";
import { toast } from "@/components/ui/use-toast";
import { useCurrentOrg } from "@/contexts/current-org";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import {
  type FuseSearchHookOptions,
  useFuseSearch,
} from "@/hooks/useFuseSearch";
import { cn } from "@/lib/utils";
import {
  LibraryItemAPI,
  type LibraryItemID,
  type MvpBuiltinLibraryItemID,
} from "@phosphor/server";
import { useMemo, useState } from "react";
import phosphorLogoVertSvg from "../../../public/phosphor-logo-vert.svg?url";

export function LibraryItemChooser(props: {
  className?: string;
  onImportLibraryItem?: (libraryItemId: LibraryItemID) => void;
  onImportBuiltinItem?: (builtinItemKey: MvpBuiltinLibraryItemID) => void;
}) {
  const { org } = useCurrentOrg();
  const [selectedItemId, setSelectedItemId] =
    useState<SelectedLibraryItem | null>(null);
  const [formulaSearchTerm, setFormulaSearchTerm] = useState("");

  const {
    data: libraryItems,
    isLoading,
    error,
    refetch,
  } = useRpcQuery({
    queryKey: ["library-items", org.id],
    orgID: org.id,
    request: new LibraryItemAPI.GetLibraryItems({
      orgId: org.id,
    }),
  });
  const { mutate: createLibraryItem } = useRpcMutation({
    mutate: (value: LibraryItemAPI.CreateLibraryItem) => value,
    onSuccess: () => void refetch(),
    orgID: org.id,
  });
  const { mutate: deleteLibraryItem } = useRpcMutation({
    mutate: (value: LibraryItemAPI.DeleteLibraryItem) => value,
    onSuccess: () => void refetch(),
    orgID: org.id,
  });

  const handleDelete = () => {
    if (selectedItemId?.ITEM) {
      const itemID = selectedItemId.ITEM;
      deleteLibraryItem(
        new LibraryItemAPI.DeleteLibraryItem({
          orgId: org.id,
          itemId: itemID,
        }),
        {
          onSuccess: () => {
            toast({
              title: "Library Item Deleted",
              description: `Successfully deleted library item: ${itemID}`,
            });
            setSelectedItemId(null);
          },
          onError: (error) => {
            toast({
              title: "Error Deleting Library Item",
              variant: "destructive",
              error,
            });
          },
        },
      );
    }
  };

  const handleImport = () => {
    if (selectedItemId?.ITEM) {
      props.onImportLibraryItem?.(selectedItemId.ITEM);
    }
    if (selectedItemId?.BUILTIN) {
      const find = BUILTIN_LIBRARY_ITEMS.find(
        (a) => a.key === selectedItemId.BUILTIN,
      );
      if (find) props.onImportBuiltinItem?.(find.builtinID);
    }
  };

  const handleCreateLibraryItem = (options: CreateLibraryItemOptions) => {
    createLibraryItem(
      new LibraryItemAPI.CreateLibraryItem({
        orgId: org.id,
        name: options.title,
        description:
          options.description ||
          `Imported library item: ${options.mvpBuiltinLibraryItemID}`,
        mvpBuiltinLibraryItemID: options.mvpBuiltinLibraryItemID,
        changesetIds: [],
      }),
      {
        onSuccess: () => {
          toast({
            title: "Library Item Created",
            description: `Successfully created library item: ${name}`,
          });
        },
        onError: (error) => {
          toast({
            title: "Error Creating Library Item",
            variant: "destructive",
            error,
          });
        },
      },
    );
  };
  const items = libraryItems?.items || [];
  const selectedItem = useMemo(() => {
    const id = selectedItemId?.ITEM;
    return id && items.find((item) => item.id === id);
  }, [items, selectedItemId?.ITEM]);
  const selectedBuiltinItem = useMemo(() => {
    const id = selectedItemId?.BUILTIN;
    return id && BUILTIN_LIBRARY_ITEMS.find((item) => item.key === id);
  }, [selectedItemId?.BUILTIN]);

  if (isLoading) {
    return (
      <div $="p-8 flex flex-col items-center justify-center">
        <Spinner $ />
      </div>
    );
  }

  if (error) {
    return <AppFailureCard $ error={error} />;
  }

  return (
    <div $={cn("flex", props.className)}>
      {/* Left Sidebar */}
      <div $="w-64 flex-none border-r border-gray-200 bg-white">
        <ImportLibraryItemList
          items={items}
          selectedItemId={selectedItemId}
          onSelectItem={setSelectedItemId}
          onCreateItem={handleCreateLibraryItem}
        />
      </div>

      <div
        $={cn(
          "p-4 w-full overflow-y-auto",
          selectedBuiltinItem || selectedItem ? "bg-white" : "",
        )}
      >
        {/* header */}
        {selectedBuiltinItem && (
          <ImportLibraryItemHeader
            title={selectedBuiltinItem.name}
            description={selectedBuiltinItem.description || ""}
            onImport={props.onImportBuiltinItem && handleImport}
            onDelete={handleDelete}
            lastSavedDate={new Date(
              Date.now() - 1000 * 60 * 60 * 24 * (7 * Math.random()),
            ).toLocaleString()}
          />
        )}
        {selectedItem && (
          <ImportLibraryItemHeader
            title={selectedItem.name || ""}
            description={selectedItem.description || ""}
            lastSavedDate={selectedItem.updatedAt.toLocaleString()}
            onDelete={handleDelete}
            onImport={props.onImportLibraryItem && handleImport}
          />
        )}
        {/* search (common) */}
        {!selectedBuiltinItem && !selectedItem ? (
          <div $="col-span-2 h-full w-full flex justify-center items-center">
            <div $="flex flex-col pb-[40%] items-center">
              <img $ src={phosphorLogoVertSvg} />
            </div>
          </div>
        ) : (
          <div $="relative mb-4">
            <Input
              type="text"
              placeholder="Search Formula Library"
              value={formulaSearchTerm}
              onChange={(e) => setFormulaSearchTerm(e.target.value)}
              $="w-64 pl-8"
            />
            <Ta.IconSearch $="w-4 h-4 text-gray-500 absolute left-2 top-1/2 transform -translate-y-1/2" />
          </div>
        )}
        {/* preview */}
        {selectedBuiltinItem && <selectedBuiltinItem.previewRender />}
        {selectedItem && (
          <>
            <MockImportLibraryItemSectioned />
            {SAMPLE_PROJECT_CO}
          </>
        )}
      </div>
    </div>
  );
}

type CreateLibraryItemOptions = {
  title: string;
  description: string;
  /** e.g. "SOLAR_PROJECT", this will be translated into an initial WorkspaceChangeKind */
  mvpBuiltinLibraryItemID: MvpBuiltinLibraryItemID;
};

type SelectedLibraryItem = {
  BUILTIN?: string;
  ITEM?: LibraryItemID;
};

interface ImportLibraryItemListProps {
  items: readonly LibraryItemAPI.LibraryItemInfo[];
  selectedItemId: SelectedLibraryItem | null;
  onSelectItem: (selection: SelectedLibraryItem) => void;
  onCreateItem: (options: CreateLibraryItemOptions) => void;
}

const FUSE_LIBRARY_ITEM_OPTIONS: FuseSearchHookOptions<LibraryItemAPI.LibraryItemInfo> =
  {
    keys: [
      { name: "name" as const, weight: 0.7 },
      { name: "description" as const, weight: 0.3 },
    ],
  };
const FUSE_LIBRARY_ITEM_SAMPLE_OPTIONS: FuseSearchHookOptions<
  (typeof BUILTIN_LIBRARY_ITEMS)[number]
> = {
  keys: [
    { name: "name" as const, weight: 0.7 },
    { name: "searchKeywords" as const, weight: 0.3 },
  ],
};

const libraryItemButtonClass = (selected: boolean) =>
  cn(
    `w-full justify-start rounded-none hover:bg-[#DBEAFE] hover:text-blue-500 border-b border-grey-200 border-l-0 border-r-0 border-t-0 font-normal`,
    selected ? "bg-[#DBEAFE] text-blue-500" : "bg-transparent text-primary",
  );

const ImportLibraryItemList: React.FC<ImportLibraryItemListProps> = ({
  items,
  selectedItemId,
  onSelectItem,
  onCreateItem,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredItems = useFuseSearch(
    { items, search: searchTerm },
    FUSE_LIBRARY_ITEM_OPTIONS,
  );
  const filteredBuiltinItems = useFuseSearch(
    { items: BUILTIN_LIBRARY_ITEMS, search: searchTerm },
    FUSE_LIBRARY_ITEM_SAMPLE_OPTIONS,
  );

  return (
    <>
      <div $="flex justify-stretch items-center p-4">
        <h2 $="text-left text-xl font-semibold flex-grow">Model Library</h2>
        <CreateNewItemButton onCreateItem={onCreateItem}>
          <Button $ variant="ghost" size="icon">
            <Ta.IconPlus $="w-4 h-4" />
          </Button>
        </CreateNewItemButton>
      </div>
      <div $="px-4 space-y-4 mb-4">
        <div $="relative">
          <Input
            type="text"
            placeholder="Search Library Items"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            $="w-full pl-8"
          />
          <Ta.IconSearch $="w-4 h-4 text-gray-500 absolute left-2 top-1/2 transform -translate-y-1/2" />
        </div>
      </div>
      {filteredItems.map((item) => (
        <Button
          $={libraryItemButtonClass(selectedItemId?.ITEM === item.id)}
          key={item.id}
          onClick={() => onSelectItem({ ITEM: item.id })}
        >
          {item.name}
        </Button>
      ))}
      {filteredBuiltinItems.map((item) => (
        <Button
          $={libraryItemButtonClass(selectedItemId?.BUILTIN === item.key)}
          key={item.key}
          onClick={() => onSelectItem({ BUILTIN: item.key })}
        >
          {item.name}
        </Button>
      ))}
    </>
  );
};

const CreateNewItemButton = (props: {
  onCreateItem: (options: CreateLibraryItemOptions) => void;
  children: React.ReactNode;
}) => {
  const [newItemName, setNewItemName] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");
  const [newItemMvpBuiltinKey, setNewItemMvpBuiltinKey] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCreateItem = () => {
    const builtin =
      newItemMvpBuiltinKey &&
      BUILTIN_LIBRARY_ITEMS.find((a) => a.key === newItemMvpBuiltinKey);
    if (newItemName.trim() && builtin) {
      props.onCreateItem({
        title: newItemName.trim(),
        description: newItemDescription.trim(),
        mvpBuiltinLibraryItemID: builtin.builtinID,
      });
      setNewItemName("");
      setNewItemDescription("");
      setNewItemMvpBuiltinKey("");
      setIsDialogOpen(false);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger $ asChild>
        {props.children}
      </DialogTrigger>
      <DialogContent $>
        <DialogHeader $>
          <DialogTitle $>Create New Library Item</DialogTitle>
          <DialogDescription $>
            Create a new library item from an MVP import name.
          </DialogDescription>
        </DialogHeader>
        <div $="space-y-4">
          <div $>
            <Label $ htmlFor="newItemName">
              Name
            </Label>
            <Input
              id="newItemName"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              placeholder="Enter library item name"
              $
            />
          </div>
          <div $>
            <Label $ htmlFor="newItemDescription">
              Description
            </Label>
            <Input
              id="newItemDescription"
              value={newItemDescription}
              onChange={(e) => setNewItemDescription(e.target.value)}
              placeholder="Enter library item description"
              $
            />
          </div>
          <div $>
            <Label $ htmlFor="newItemMvpImportName">
              Based on MVP Import Builtin
            </Label>
            <Select
              value={newItemMvpBuiltinKey}
              onValueChange={setNewItemMvpBuiltinKey}
            >
              <SelectTrigger $="w-full" id="newItemMvpImportName">
                <SelectValue $ placeholder="Select MVP Builtin Library Item" />
              </SelectTrigger>
              <SelectContent $>
                {BUILTIN_LIBRARY_ITEMS.toSorted(
                  ({ builtinID: a }, { builtinID: b }) => a.localeCompare(b),
                ).map((a) => (
                  <SelectItem $ value={a.key} key={a.key}>
                    {a.builtinID} / {a.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button onClick={handleCreateItem} $="w-full">
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
