import { Button } from "@/components/ui/button";
import { Ta } from "@/components/ui/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import React, { Suspense, useEffect } from "react";
import { IfDev, IsDevAtom } from "./IfDev";
import { WhoAmI } from "./WhoAmI";
import { addDataLocListeners } from "./addDataLocListeners";
import { openInDevEditor } from "./openInDevEditor";

const TanstackRouterDevtools = React.lazy(() =>
  // Lazy load in development
  import("@tanstack/router-devtools").then((res) => ({
    default: res.TanStackRouterDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);
const TanstackQueryDevtools = React.lazy(() =>
  // Lazy load in development
  import("@tanstack/react-query-devtools").then((res) => ({
    default: res.ReactQueryDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);
const TanstackTableDevtools = React.lazy(() =>
  // Lazy load in development
  import("@tanstack/react-table-devtools").then((res) => ({
    default: res.ReactTableDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);

export const withAppDevtools = (element: React.ReactElement) => {
  // Render nothing in production
  if (process.env.NODE_ENV === "production") return element;

  // const ReactDevtools = getDevtoolsElement();
  return (
    <>
      {element}
      <AppDevtools />
    </>
  );
};

export const withTableDevtools = (element: React.ReactElement, table: any) => {
  // Render nothing in production
  if (process.env.NODE_ENV === "production") return element;

  // const ReactDevtools = getDevtoolsElement();
  return (
    <>
      {element}
      <Suspense>
        <TanstackTableDevtools table={table} />
      </Suspense>
    </>
  );
};

const AppDevtools = () => {
  useEffect(
    () =>
      addDataLocListeners(document.body, ({ file, line, column }) =>
        openInDevEditor(`${file}:${line}:${column}`),
      ),
    [],
  );
  // const ReactDevtools = getDevtoolsElement();
  return (
    <>
      <IfDev $ noContainer dismissKey="router-devtools">
        <Suspense>
          <TanstackRouterDevtools />
        </Suspense>
      </IfDev>
      <IfDev $ noContainer dismissKey="query-devtools">
        <Suspense>
          <TanstackQueryDevtools />
        </Suspense>
      </IfDev>
      <div $="fixed bottom-10 left-2 bg-slate-50">
        <Popover>
          <PopoverTrigger $>
            <Ta.IconUser />
          </PopoverTrigger>
          <PopoverContent $="w-[400px]">
            <WhoAmI />
            <DevSettings />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};

const DevSettings = () => {
  const [value] = useAtom(IsDevAtom);
  const navigate = useNavigate();
  const toggleDev = () =>
    navigate({ search: (prev) => ({ ...prev, dev: !value }) });
  return (
    <div $="flex items-center gap-2">
      <Button $ variant="ghost" onClick={toggleDev}>
        Dev Mode {value ? "✅" : "❌"}
      </Button>
    </div>
  );
};
