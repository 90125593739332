import {
  type RegisteredRouter,
  type RouteIds,
  getRouteApi,
  useNavigate,
} from "@tanstack/react-router";
import { useCallback } from "react";

/** @internal */
export function useRouteSearch<
  T extends RouteIds<RegisteredRouter["routeTree"]>,
>(routeId: T) {
  const routeApi = getRouteApi<T>(routeId);
  const navigate = useNavigate();
  const search = routeApi.useSearch();

  const setSearch = useCallback(
    (partialFilters: Partial<typeof search>) =>
      navigate({
        search: (prev) => cleanEmptyParams({ ...prev, ...partialFilters }),
      }),
    [navigate],
  );
  const resetSearch = useCallback(() => navigate({ search: {} }), [navigate]);

  return { search, setSearch, resetSearch };
}

const cleanEmptyParams = <T extends Record<string, unknown>>(search: T) => {
  const newSearch = { ...search };
  for (const key of Object.keys(newSearch)) {
    const value = newSearch[key];
    if (
      value === undefined ||
      value === "" ||
      (typeof value === "number" && isNaN(value))
    )
      delete newSearch[key];
  }

  // if (search.pageIndex === DEFAULT_PAGE_INDEX) delete newSearch.pageIndex
  // if (search.pageSize === DEFAULT_PAGE_SIZE) delete newSearch.pageSize

  return newSearch;
};
