import type {
  WorkspaceChangeKind,
  WorkspaceProposalID,
} from "@phosphor/server";
import type { Atom } from "jotai";
import { createContext, useContext } from "react";

export interface WorkspaceNavigationService {
  // Proposal Selection
  setSelectedProposals: (
    setAction: (prev: readonly WorkspaceProposalID[]) => WorkspaceProposalID[],
  ) => void;
  selectedProposalsAtom: Atom<readonly WorkspaceProposalID[]>;

  // Unsaved Changes
  setUnsavedChanges: (
    setAction: (prev: readonly WorkspaceChangeKind[]) => WorkspaceChangeKind[],
  ) => void;
  unsavedChangesAtom: Atom<readonly WorkspaceChangeKind[]>;

  // Unsaved Changes Title
  setUnsavedTitle: (title: string | undefined) => void;
  unsavedTitleAtom: Atom<string | undefined>;
}

export const WorkspaceNavigationContext =
  createContext<WorkspaceNavigationService | null>(null);

export const useWorkspaceNavigation = () => {
  const context = useContext(WorkspaceNavigationContext);
  if (context === null) {
    throw new Error(
      "useWorkspaceNavigation must be used within a WorkspaceNavigationContext provider",
    );
  }
  return context;
};
