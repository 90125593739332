import { Ta } from "@/components/ui/icons";
import avatar from "../../public/avatar.svg?url";
import oliver from "../../public/oliver.jpeg?url";
import shimmerAnalyst from "../../public/shimmer_analyst.svg?url";

export const Shimmer = () => {
  return (
    <div $=" bg-background w-100 mx-auto shadow-md rounded-lg mt-4 border border-zinc-300 overflow-hidden">
      <div $="sticky bottom-6 px-4 py-3 border-b bg-[#FAF9FB] border-gray-300 flex items-center justify-between cursor-pointer w-auto shadow-sm">
        <img $ src={shimmerAnalyst} alt="" />
        <span $="text-xs text-neutral-400 font-normal">
          Last updated 12:00 PM
        </span>
      </div>

      <div $="flex items-center mt-4 mb-4 px-4">
        <img src={oliver} alt="Profile" $="w-10 h-10 rounded-full mr-4" />
        <h1 $="text-small bg-[#F4F4F5] p-[8px] rounded-lg text-black ">
          Create a walk of changes between the closing model, and scenarios for
          new panels and interest rate
        </h1>
      </div>
      <div $="flex my-4 px-4">
        <img src={avatar} alt="Profile" $="w-10 h-10 mr-4" />
        <div $="bg-background p-4 rounded-lg border border-[#D4D4D8] w-[100%]">
          <h2 $="text-sm font-medium mb-3 text-black">
            Here&apos;s an updated analysis
          </h2>
          <div $="grid grid-cols-3 gap-4">
            <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
              <h3 $="text-xs font-semibold mb-2 text-black">
                Closing model on acquisition date
              </h3>
              <ul $="text-xs text-neutral-600">
                <li $>Pre Tax IRR: 7.1%</li>
                <li $>Tax Equity IRR: 6.2%</li>
                <li $>Total Investment: 8.3%</li>
                <li $>Developer Profit: 10.5%</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
              <h3 $="text-xs font-semibold mb-2 text-black">Base case</h3>
              <ul $="text-xs text-neutral-600">
                <li $>Pre Tax IRR: 7.3%</li>
                <li $>Tax Equity IRR: 6.5%</li>
                <li $>Total Investment: 8.6%</li>
                <li $>Developer Profit: 10.8%</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow-sm border border-gray-200">
              <h3 $="text-xs font-semibold mb-2 text-black">
                Scenario: New Solar Panels
              </h3>
              <ul $="text-xs text-neutral-600">
                <li $>Assumes installed cost reduction</li>
                <li $>MW Cost: 1.5</li>
                <li $>WKP (P50): 8.0</li>
                <li $>Degradation: 1.0%</li>
                <li $>DSCR (Avg): 1.47</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow-sm col-span-3 border border-gray-200">
              <h3 $="text-xs font-semibold mb-2 text-black">
                Sensitivity: Interest Rate (5% Base Metric)
              </h3>
              <ul $="text-xs text-neutral-600">
                <li $>Changed S&EA&apos;s Bids: 5.0%</li>
                <li $>Sensitivity on: IRR, Developer Profit</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div $="w-100 mx-auto p-4 flex gap-2">
        <input
          type="text"
          $="w-[80%] p-2 border border-gray-300 rounded-lg bg-background "
          placeholder="Follow up with another prompt"
        />
        <div $="w-[20%] h-100 border border-[#6B5889] p-1 border-dashed border-1 rounded-sm flex flex-col justify-center items-center">
          <div $="flex gap-2 mb-[2px] px-4">
            <Ta.IconFileUpload $="w-[16px] h-[16px] stroke-1" color="#6B5889" />
            <Ta.IconPhotoPlus $="w-[16px] h-[16px] stroke-1" color="#6B5889" />
          </div>
          <p $="font-medium text-[8px] text-[#6B5889] uppercase leading-3 tracking-wide">
            Attach or drop Files
          </p>
        </div>
      </div>
    </div>
  );
};
