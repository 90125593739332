import { Ta } from "@/components/ui/icons";
import { useCurrentOrg } from "@/contexts/current-org";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { FolderAPI, type OrgFolderID } from "@phosphor/server";
import { useEffect, useState } from "react";
import { CreateWorkspaceButton } from "./Globals/CreateWorkspaceButton";
import { WorkspaceList } from "./WorkspaceList";
import { Badge } from "./ui/badge";

export const Home = () => {
  const { org } = useCurrentOrg();
  const [selectedFolder, setSelectedFolder] = useState<
    OrgFolderID | undefined
  >();

  const { data: folders } = useRpcQuery({
    queryKey: ["getFolders", org.id],
    request: new FolderAPI.GetOrgFolders({
      org: org.id,
    }),
  });

  useEffect(() => {
    if (folders && folders[0]?.folder_id) {
      setSelectedFolder(folders[0]?.folder_id);
    }
  }, [folders]);

  const showFolders = folders && folders.length > 1;

  return (
    <>
      <div $="min-w-[600px] max-w-[1440px] mx-auto">
        <div $="flex justify-between p-5 gap-4">
          <QuickAccessSection />
          <ActivitySection />
        </div>
        <div $="flex flex-col gap-2 items-center mt-4">
          <CreateWorkspaceButton
            selectedFolder={selectedFolder}
            button={
              <button $="block text-[#766293F5] text-[14px] tracking-widest font-medium cursor-pointer">
                WORKSPACES <span $>(Create New)</span>
              </button>
            }
          />
          {showFolders && (
            <div $="flex gap-2">
              {folders?.map((folder) => (
                <Badge
                  $="cursor-pointer"
                  key={folder.folder_id}
                  variant={
                    selectedFolder === folder.folder_id ? "default" : "outline"
                  }
                  onClick={() => setSelectedFolder(folder.folder_id)}
                >
                  {folder.folder_display_name}
                </Badge>
              ))}
            </div>
          )}
        </div>
        <div $="p-5">
          <WorkspaceList
            folder={selectedFolder}
            orgID={org.id}
            orgURLKey={org.urlKey}
          />
        </div>
      </div>
    </>
  );
};

const QuickAccessSection = () => (
  <div $="flex-grow h-[316px] border border-[#D4D4D8] rounded-sm">
    <div $="h-[36px] border-b border-[#D4D4D8] flex items-center">
      <p $="text-[#766293F5] text-[14px] tracking-widest font-medium pl-3">
        QUICK ACCESS
      </p>
    </div>
    <div $="h-[88%] bg-background flex items-center pl-[42px] pr-2 text-base font-normal text-black">
      <div $="border border-[#766D87] rounded-full p-[8px] mr-[26px]">
        <Ta.IconPin $="w-[40px] h-[40px]" color="#766D87" />
      </div>
      <p $="text-base font-normal text-black min-w-[180px]">
        As you join several workspaces, you can pin those your frequent for
        quick access.
      </p>
    </div>
  </div>
);

const ActivitySection = () => (
  <div $="flex-grow h-[316px] border border-[#D4D4D8] rounded-sm">
    <div $="h-[36px] border-b border-[#D4D4D8] flex items-center justify-between">
      <p $="text-[#766293F5] text-[14px] tracking-widest font-medium pl-3">
        ACTIVITY
      </p>
      <p $="text-[#766293F5] text-[12px] pr-3">No new updates</p>
    </div>
    <div $="h-[88%] bg-background flex items-center pl-[42px] pr-2 text-base font-normal text-black">
      <div $="border border-[#766D87] rounded-full p-[8px] mr-[26px]">
        <Ta.IconActivity $="w-[40px] h-[40px]" color="#766D87" />
      </div>
      <p $="min-w-[180px] text-base font-normal text-black">
        Any updates you or collaborates make to Workspaces you participate will
        show in your activity feed.
      </p>
    </div>
  </div>
);
