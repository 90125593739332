import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { SAMPLE_SOLAR } from "@/contexts/sample-workspace-data";
import type { WorkspaceSourceID } from "@phosphor/server";
import type React from "react";
import { LinkedStrategyChip } from "./LinkedStrategyChip";

interface LinkedSourceChipProps {
  sourceId: WorkspaceSourceID;
}

export const LinkedSourceChip: React.FC<LinkedSourceChipProps> = ({
  sourceId,
}) => {
  const sampleSource = SAMPLE_SOLAR.sources.find(
    (source) => source.id === sourceId,
  );

  const displayData = sampleSource ?? {
    displayTitle: "Not found",
    fileName: "",
    uploaderComments: "",
  };

  const linkedStrategies = SAMPLE_SOLAR.strategySources
    .filter((ss) => ss.sourceId === sourceId)
    .map((ss) => ss.strategyId);

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center text-blue-600 px-2 py-1 text-xs cursor-pointer underline">
          {displayData.displayTitle}
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-80">
        <div $="space-y-2">
          <h4 $="text-sm font-semibold">{displayData.displayTitle}</h4>
          <p $="text-xs text-gray-500">File: {displayData.fileName}</p>
          <p $="text-xs">{displayData.uploaderComments}</p>
          {linkedStrategies.length > 0 && (
            <div $="space-y-1">
              <p $="text-xs font-medium">Linked Strategies:</p>
              <div $="flex flex-wrap gap-1">
                {linkedStrategies.map((strategyId) => (
                  <LinkedStrategyChip
                    key={strategyId}
                    strategyId={strategyId}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
