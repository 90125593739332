import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useCurrentOrg } from "@/contexts/current-org";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import { type WorkspaceID, WorkspaceInviteAPI } from "@phosphor/server";
import { useState } from "react";
import { AppFailureMessage } from "../AppFailureDisplay";
import { CopyToClipboardButton } from "../ui/copy-to-clipboard-button";

export const InviteWorkspaceCollaboratorsButton = ({
  workspaceId,
  button,
}: { workspaceId: WorkspaceID; button: React.ReactNode }) => {
  const { org } = useCurrentOrg();
  const [isOpen, setIsOpen] = useState(false);
  const [inviteLink, setInviteLink] = useState<string | null>(null);
  const initialUrl = `${window.location.protocol}//${window.location.host}`;
  const { toast } = useToast();
  const createWorkspaceInvite = useRpcMutation({
    mutate: (input: WorkspaceInviteAPI.CreateInvite) => input,
    orgID: org.id,
    onSuccess: (data) => {
      if (data) {
        const newInviteLink = `${initialUrl}/invite/${data}`;
        setInviteLink(newInviteLink);
        navigator.clipboard.writeText(newInviteLink);
        toast({ title: "Invite link created, copied to clipboard" });
      }
    },
  });

  const handleCreateInvite = () => {
    createWorkspaceInvite.mutate(
      new WorkspaceInviteAPI.CreateInvite({
        workspace_id: workspaceId,
      }),
    );
  };
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger $ asChild>
        {button}
      </DialogTrigger>
      <DialogContent $="sm:max-w-[425px]">
        <DialogHeader $>
          <DialogTitle $>Create Invite Link</DialogTitle>
          <DialogDescription $>
            Generate an invite link for this workspace.
          </DialogDescription>
        </DialogHeader>
        <div $="grid gap-4 py-4">
          <Button
            $
            onClick={handleCreateInvite}
            disabled={createWorkspaceInvite.isPending}
          >
            {createWorkspaceInvite.isPending
              ? "Creating..."
              : "Create Invite Link"}
          </Button>
          {createWorkspaceInvite.isError && (
            <AppFailureMessage $ error={createWorkspaceInvite.error} />
          )}
        </div>
        {createWorkspaceInvite.isSuccess && inviteLink && (
          <div $="mt-2 flex items-center gap-2">
            <Input $="flex-grow" value={inviteLink} readOnly />
            <CopyToClipboardButton $ text={inviteLink} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
