import { type Atom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { useMemo } from "react";

export function useSelectAtom<In, Out>(
  atomIn: Atom<In>,
  map: (value: In) => Out,
  equals: (a: Out, b: Out) => boolean = Object.is,
): Out {
  // biome-ignore lint/correctness/useExhaustiveDependencies: ...
  return useAtomValue(useMemo(() => selectAtom(atomIn, map, equals), [atomIn]));
}
