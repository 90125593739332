import { LayoutWithNavbar } from "@/components/LayoutWithSidebar";
import { OrgWorkspaceNavbar } from "@/components/OrgWorkspaceNavbar";
import {
  WorkspaceNavigationContext,
  type WorkspaceNavigationService,
} from "@/components/WorkspaceNavigationService";
import { ProvideLoadedWorkspace } from "@/contexts/current-workspace";
import { DevPrint } from "@/lib/dev/DevState";
import { IfDev } from "@/lib/dev/IfDev";
import { useAtomFromValue } from "@/lib/jotai/useAtomFromValue";
import { S } from "@phosphor/prelude";
import {
  WorkspaceChangeKind,
  WorkspaceID,
  WorkspaceProposalID,
} from "@phosphor/server";
import { Outlet, createFileRoute, deepEqual } from "@tanstack/react-router";
import { useCallback, useMemo } from "react";

const WorkspaceSearchSchema = S.Struct({
  proposals: S.Array(WorkspaceProposalID).pipe(S.optional),
  /** unsaved changes */
  unsaved: S.Array(WorkspaceChangeKind).pipe(S.optional),
  /** title for these unsaved changes */
  untitle: S.String.pipe(S.optional),
});
type WorkspaceSearchSchema = S.Schema.Type<typeof WorkspaceSearchSchema>;

export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout",
)({
  component: WorkspaceLayout,
  validateSearch: S.decodeUnknownSync(WorkspaceSearchSchema),
  params: {
    parse: ({ workspace }) => ({ workspace: WorkspaceID.make(workspace) }),
    stringify: ({ workspace }) => ({ workspace: String(workspace) }),
  },
  onError(err) {
    return (
      <div $="p-4">
        Invalid Workspace
        <IfDev $ dismissKey="workspace-layout-dev-tools">
          <DevPrint $ value={err} />
        </IfDev>
      </div>
    );
  },
});

// Kinda ugly, but this is an okay enough way to keep the nav state in sync
function useWorkspaceNavService(): WorkspaceNavigationService {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  // Proposals
  const selectedProposalsAtom = useAtomFromValue(
    search.proposals ?? [],
    deepEqual,
  );
  const setSelectedProposals = useCallback(
    (
      setAction: (
        prev: readonly WorkspaceProposalID[],
      ) => WorkspaceProposalID[],
    ) => {
      navigate({
        search: (prev) => ({
          ...prev,
          proposals: setAction(
            ("proposals" in prev ? prev.proposals : null) ?? [],
          ),
        }),
      });
    },
    [navigate],
  );

  // Unsaved Changes
  const unsavedChangesAtom = useAtomFromValue(search.unsaved ?? [], deepEqual);
  const setUnsavedChanges = useCallback(
    (
      setAction: (
        prev: readonly WorkspaceChangeKind[],
      ) => WorkspaceChangeKind[],
    ) => {
      navigate({
        search: (prev) => ({
          ...prev,
          unsaved: setAction(("unsaved" in prev ? prev.unsaved : null) ?? []),
        }),
      });
    },
    [navigate],
  );

  // Unsaved Title
  const unsavedTitleAtom = useAtomFromValue(search.untitle);
  const setUnsavedTitle = useCallback(
    (title: string | undefined) => {
      navigate({
        search: (prev) => ({
          ...prev,
          untitle: title,
        }),
      });
    },
    [navigate],
  );

  return useMemo(
    () => ({
      selectedProposalsAtom,
      setSelectedProposals,
      unsavedChangesAtom,
      setUnsavedChanges,
      unsavedTitleAtom,
      setUnsavedTitle,
    }),
    [
      selectedProposalsAtom,
      setSelectedProposals,
      unsavedChangesAtom,
      setUnsavedChanges,
      unsavedTitleAtom,
      setUnsavedTitle,
    ],
  );
}

function WorkspaceLayout() {
  const workspaceId = Route.useParams().workspace;
  const workspaceNavService = useWorkspaceNavService();

  return (
    <ProvideLoadedWorkspace workspaceId={workspaceId}>
      <WorkspaceNavigationContext.Provider value={workspaceNavService}>
        <_Layout />
      </WorkspaceNavigationContext.Provider>
    </ProvideLoadedWorkspace>
  );
}

const _Layout = () => {
  return (
    <LayoutWithNavbar navbar={<OrgWorkspaceNavbar />}>
      <Outlet />
    </LayoutWithNavbar>
  );
};
