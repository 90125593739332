import { withPosthog } from "@/lib/dev/posthog";
import { Schema } from "@effect/schema";
import {
  AccountAPI,
  AccountID,
  JWTAccessToken,
  OrgID,
  OrgInfo,
  OrgUserID,
  OrgUserInfo,
} from "@phosphor/server";
import { Effect } from "effect";
import React, { createContext, useContext, useEffect } from "react";
import { getClientWithToken } from "../hooks/use-rpc-hooks";
import { useStorageValue } from "../hooks/useStorageValue";

export const StoredOrgUser = Schema.Struct({
  accessToken: JWTAccessToken,
  orgInfo: OrgInfo,
  userInfo: OrgUserInfo,
});
export type StoredOrgUser = Schema.Schema.Type<typeof StoredOrgUser>;

const StoredAccountInfo = Schema.Struct({
  id: AccountID,
  displayHint: Schema.String,
  accessToken: JWTAccessToken,
});

type AccountInfo = Schema.Schema.Type<typeof StoredAccountInfo>;

const StoredCurrentUserStateFields = {
  account: StoredAccountInfo.pipe(Schema.optional),
  users: StoredOrgUser.pipe(Schema.Array),
  activeOrgUsers: Schema.Record({ key: OrgID, value: OrgUserID }),
};
type StoredCurrentUserState = Schema.Struct.Type<
  typeof StoredCurrentUserStateFields
>;

interface CurrentUserContextType {
  currentUser: StoredCurrentUserState;
  setAccount: (account: AccountInfo | null) => Promise<void>;
  addOrgUser: (orgUser: StoredOrgUser) => void;
  removeOrgUser: (userId: OrgUserID, orgId: OrgID) => void;
  clearAllData: () => void;
  setActiveOrgUser: (orgId: OrgID, userId: OrgUserID) => void;
  refreshAll: () => Promise<void>;
}

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(
  undefined,
);

// Found in the entry-inline.js file as well to monitor if the user is logged in
const LOCAL_STORAGE_KEY = "ApplicationStore";

const refreshUserState = async (
  account: AccountInfo,
): Promise<StoredCurrentUserState> => {
  const accountClient = getClientWithToken(account.accessToken);

  // Fetch all users associated with the account
  const listUsersRequest = new AccountAPI.ListAccountUsers({});
  const listUsersResult = await Effect.runPromise(
    accountClient(listUsersRequest),
  );

  // Create user tokens and update user information
  const updatedUsers = await Promise.all(
    listUsersResult.users.map(async (user) => {
      try {
        // Create a user token
        const createTokenRequest = new AccountAPI.CreateUserToken({
          accountId: account.id,
          userId: user.info.id,
          orgId: user.org.id,
        });
        const tokenResult = await Effect.runPromise(
          accountClient(createTokenRequest),
        );

        // Use the new token to fetch updated user info
        const userClient = getClientWithToken(tokenResult.accessToken);
        const whoAmIRequest = new AccountAPI.WhoAmI({});
        const whoAmIResult = await Effect.runPromise(userClient(whoAmIRequest));

        if (whoAmIResult.user) {
          return {
            accessToken: tokenResult.accessToken,
            orgInfo: whoAmIResult.user.org,
            userInfo: whoAmIResult.user.info,
          };
        }
        return null;
      } catch (error) {
        console.error(
          `Failed to refresh user data for ${user.info.id}:`,
          error,
        );
        return null;
      }
    }),
  );

  const filteredUsers = updatedUsers.filter(
    (user): user is StoredOrgUser => user !== null,
  );

  return {
    account,
    users: filteredUsers,
    activeOrgUsers: Object.fromEntries(
      filteredUsers.map((user) => [user.orgInfo.id, user.userInfo.id]),
    ),
  };
};

// TODO: Use a tanstack query with caching so we aren't double calling this due to our homemade useCheckEffect...
// biome-ignore lint/complexity/noBannedTypes: not an issue
const _ProvideCurrentUser: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useStorageValue(
    LOCAL_STORAGE_KEY,
    localStorage,
    () => StoredCurrentUserStateFields,
  );

  const setAccount = async (account: AccountInfo | null) => {
    if (account) {
      const refreshedState = await refreshUserState(account);
      setCurrentUser(refreshedState);
    } else {
      setCurrentUser({
        account: undefined,
        users: [],
        activeOrgUsers: {},
      });
    }
  };

  const addOrgUser = (orgUser: StoredOrgUser) => {
    setCurrentUser((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        users: [
          ...prev.users.filter(
            (ou) =>
              ou.userInfo.id !== orgUser.userInfo.id ||
              ou.orgInfo.id !== orgUser.orgInfo.id,
          ),
          orgUser,
        ],
        activeOrgUsers: {
          ...prev.activeOrgUsers,
          [orgUser.orgInfo.id]: orgUser.userInfo.id,
        },
      };
    });
  };

  const removeOrgUser = (userId: OrgUserID, orgId: OrgID) => {
    setCurrentUser((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        users: prev.users.filter(
          (ou) => ou.userInfo.id !== userId || ou.orgInfo.id !== orgId,
        ),
        activeOrgUsers: {
          ...prev.activeOrgUsers,
          [orgId]:
            prev.activeOrgUsers[orgId] !== userId
              ? prev.activeOrgUsers[orgId]
              : undefined,
        },
      };
    });
  };

  const clearAllData = () => {
    setCurrentUser({
      account: undefined,
      users: [],
      activeOrgUsers: {},
    });
    localStorage.removeItem("ApplicationStore");
  };

  const setActiveOrgUser = (orgId: OrgID, userId: OrgUserID) => {
    setCurrentUser((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        activeOrgUsers: { ...prev.activeOrgUsers, [orgId]: userId },
      };
    });
  };

  const refreshAll = async () => {
    if (!currentUser?.account) return;
    const refreshedState = await refreshUserState(currentUser.account);
    setCurrentUser(refreshedState);
  };

  // TODO: Enable some kind of user refreshing with valtio.
  // Unfortunately, currently, this will trigger a re-render of a bunch of things below...
  // So, that should be fixed first, before enabling this again.
  // // Keep cached user information and org information up to date
  // // TODO: Don't call this twice... Consider using a tanstack query with caching
  // useCheckEffect(() => refreshAll, [currentUser?.account?.id], {
  //   interval: 10000,
  // });

  const id = currentUser?.account?.id;
  const displayHint = currentUser?.account?.displayHint;
  useEffect(() => {
    withPosthog((ph) => {
      ph.identify(id, { displayHint });
    });
  }, [id, displayHint]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: currentUser ?? {
          account: undefined,
          users: [],
          activeOrgUsers: {},
        },
        setAccount,
        addOrgUser,
        removeOrgUser,
        clearAllData,
        setActiveOrgUser,
        refreshAll,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const ProvideCurrentUser = React.memo(_ProvideCurrentUser);
ProvideCurrentUser.displayName = "ProvideCurrentUser";

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error("useCurrentUser must be used within a ProvideCurrentUser");
  }
  return context;
};
