import { Ta } from "@/components/ui/icons";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/$org/_layout/myActivity")({
  component: MyActivity,
});

function MyActivity() {
  return (
    <div $="w-full h-full p-4 bg-white">
      <div $="mb-4">
        <h2 $="text-2xl font-semibold tracking-wider text-gray-800">
          My Activity
        </h2>
      </div>
      <div $="">
        <table $="min-w-full table-auto text-left">
          <thead $>
            <tr $="bg-gray-50 border border-gray-200">
              <th $="px-4 py-3 text-sm font-semibold text-neutral-800">
                Activity
              </th>
              <th $="px-4 py-3 text-sm font-semibold  text-neutral-800">
                Last Updated
              </th>
            </tr>
          </thead>
          <tbody $="border border-gray-200">
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Jane
                    </a>
                    <span $> is working on </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch TUV
                    </a>
                    <span $> of Model W</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Thu May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Dave
                    </a>
                    <span $> merged </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch XYZ
                    </a>
                    <span $> into Model Y</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Thu May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Alice
                    </a>
                    <span $> created new </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch HIJ
                    </a>
                    <span $> of Model K</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="  font-semibold text-[#766293]">
                      Morgan
                    </a>
                    <span $> merged </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch ABC
                    </a>
                    <span $> into Model D</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Yanna
                    </a>
                    <span $> is working on </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch LMNO
                    </a>
                    <span $> of Model P</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Jane
                    </a>
                    <span $> is working on </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch TUV
                    </a>
                    <span $> of Model W</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Thu May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Dave
                    </a>
                    <span $> merged </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch XYZ
                    </a>
                    <span $> into Model Y</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Thu May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Alice
                    </a>
                    <span $> created new </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch HIJ
                    </a>
                    <span $> of Model K</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="  font-semibold text-[#766293]">
                      Morgan
                    </a>
                    <span $> merged </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch ABC
                    </a>
                    <span $> into Model D</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
            <tr $="border-b border-gray-200">
              <td $="px-4 py-3 text-sm text-black">
                <div $="flex items-center">
                  <Ta.IconArrowBearRight $="w-5 h-5 text-black mr-2" />
                  <span $>
                    <a href="#" $="font-semibold text-[#766293]">
                      Yanna
                    </a>
                    <span $> is working on </span>
                    <a href="#" $="text-black bg-gray-100 px-1">
                      branch LMNO
                    </a>
                    <span $> of Model P</span>
                  </span>
                </div>
              </td>
              <td $="px-4 py-3 text-xs text-gray-500">
                Wed May 23 2024 1:44 pm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
