import { Button } from "@/components/ui/button";
import { Lu, Ta } from "@/components/ui/icons";
import { useCurrentOrg } from "@/contexts/current-org";
import { cn } from "@/lib/utils";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { AiShimmerButton } from "./AiShimmerButton";

const SideBarButton = ({
  item,
  icon,
  selectedItem,
  onClick,
  children,
}: {
  item: string;
  icon: React.ReactNode;
  selectedItem: string;
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <Button
    $="w-full justify-between tracking-widest font-medium uppercase"
    variant={selectedItem === item ? "selected" : "ghost"}
    onClick={onClick}
  >
    <div $="flex items-center leading-6 tracking-[.08em]">
      {icon}
      {children}
    </div>
  </Button>
);
SideBarButton.displayName = "SideBarButton";

const SideBarSecondaryButton = ({
  item,
  selectedItem,
  onClick,
  children,
}: {
  item: string;
  selectedItem: string;
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <Button
    $="w-full justify-between text-sm py-2 h-auto font-normal pl-6 relative select-none mr-12"
    variant={selectedItem === item ? "selected" : "ghost"}
    onClick={onClick}
  >
    {children}
    <Ta.IconChevronRight
      $={cn(
        "absolute right-3 top-1/2 w-4 h-4 -translate-y-1/2",
        selectedItem !== item && "text-zinc-300",
      )}
    />
  </Button>
);
SideBarSecondaryButton.displayName = "SideBarSecondaryButton";

OrgRootSidebar.displayName = "OrgRootSidebar";
export function OrgRootSidebar() {
  const navigate = useNavigate();
  const [isLibraryOpen, setIsLibraryOpen] = useState(true);
  const [isActivityOpen, setIsActivityOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const { org } = useCurrentOrg();

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  return (
    <div $="relative p-4 h-full w-full flex flex-col justify-between items-center">
      <div $="space-y-4 flex-grow">
        <Button
          variant={selectedItem === "INBOX" ? "selected" : "ghost"}
          $="w-full justify-start"
          onClick={() => {
            handleItemClick("INBOX");
            void navigate({
              to: "/$org/inbox",
              params: { org: org.urlKey },
            });
          }}
        >
          <Ta.IconInbox $="h-5 w-5 mr-2" />
          INBOX
        </Button>

        <SideBarButton
          item="EXPLORER"
          icon={<Ta.IconListSearch $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("EXPLORER");
            void navigate({
              to: "/$org/explore",
              params: { org: org.urlKey },
            });
          }}
        >
          EXPLORER
        </SideBarButton>

        <SideBarButton
          item="WORKSPACES"
          icon={<Ta.IconDesk $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("WORKSPACES");
            void navigate({
              to: "/$org/workspaces",
              params: { org: org.urlKey },
            });
          }}
        >
          WORKSPACES
        </SideBarButton>

        <div $="relative z-0">
          <Button
            variant={selectedItem === "LIBRARY" ? "selected" : "ghost"}
            $="w-full justify-between tracking-widest font-medium"
            onClick={() => {
              setIsLibraryOpen(!isLibraryOpen);
              handleItemClick("LIBRARY");
            }}
          >
            <div $="flex items-center leading-6 tracking-[.08em]">
              <Ta.IconBook $="h-5 w-5 mr-2" />
              LIBRARY
            </div>
            <Lu.ChevronRight
              $={`h-4 w-4 transform transition-transform ${
                isLibraryOpen ? "rotate-90" : ""
              }`}
            />
          </Button>
          {isLibraryOpen && (
            <div $="ml-5 pr-2 mt-2 space-y-2 relative">
              <div $="absolute left-0 top-0 bottom-0"></div>
              <SideBarSecondaryButton
                item="Models"
                selectedItem={selectedItem}
                onClick={() => {
                  handleItemClick("Models");
                  void navigate({
                    to: "/$org/models",
                    params: { org: org.urlKey },
                  });
                }}
              >
                Models
              </SideBarSecondaryButton>
              <SideBarSecondaryButton
                item="Strategies"
                selectedItem={selectedItem}
                onClick={() => handleItemClick("Strategies")}
              >
                Strategies
              </SideBarSecondaryButton>
            </div>
          )}
        </div>

        <div $="relative">
          <Button
            variant={selectedItem === "ACTIVITY" ? "selected" : "ghost"}
            $="w-full justify-between tracking-widest font-medium"
            onClick={() => {
              setIsActivityOpen(!isActivityOpen);
              handleItemClick("ACTIVITY");
            }}
          >
            <div $="flex items-center leading-6 tracking-[.08em]">
              <Ta.IconActivity $="h-5 w-5 mr-2" />
              ACTIVITY
            </div>
            <Lu.ChevronRight
              $={`h-4 w-4 transform transition-transform ${
                isActivityOpen ? "rotate-90" : ""
              }`}
            />
          </Button>
          {isActivityOpen && (
            <div $="ml-5 pr-2 mt-2 space-y-2 relative">
              <div $="absolute left-0 top-0 bottom-0"></div>
              <SideBarSecondaryButton
                item="My activity"
                selectedItem={selectedItem}
                onClick={() => {
                  handleItemClick("My activity");
                  void navigate({
                    to: "/$org/myActivity",
                    params: { org: org.urlKey },
                  });
                }}
              >
                My activity
              </SideBarSecondaryButton>
              <SideBarSecondaryButton
                item="All Activity"
                selectedItem={selectedItem}
                onClick={() => handleItemClick("All Activity")}
              >
                All Activity
              </SideBarSecondaryButton>
            </div>
          )}
        </div>
      </div>
      <AiShimmerButton />
    </div>
  );
}

OrgWorkspaceProjectSelectorSidebar.displayName =
  "OrgWorkspaceProjectSelectorSidebar";
export function OrgWorkspaceProjectSelectorSidebar() {
  const [selectedItem, setSelectedItem] = useState("All Projects");

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  return (
    <div $="relative w-full h-full bg-white p-4 flex flex-none flex-col border-r border-gray-300">
      <div $="space-y-4 flex-grow">
        <SideBarButton
          item="All Projects"
          icon={<Ta.IconPrism $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("All Projects");
          }}
        >
          All Projects
        </SideBarButton>
        <SideBarButton
          item="Project 1"
          icon={<Ta.IconPrism $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("Project 1");
          }}
        >
          Project 1
        </SideBarButton>

        <SideBarButton
          item="Project 2"
          icon={<Ta.IconPrism $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("Project 2");
          }}
        >
          Project 2
        </SideBarButton>

        <SideBarButton
          item="Project 3"
          icon={<Ta.IconPrism $="h-5 w-5 mr-2" />}
          selectedItem={selectedItem}
          onClick={() => {
            handleItemClick("Project 3");
          }}
        >
          Project 3
        </SideBarButton>
      </div>
    </div>
  );
}
