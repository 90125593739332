import { Ta } from "./ui/icons";

interface SuggestedProps {
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>; // Optional setState function
}

export const Suggested: React.FC<SuggestedProps> = ({ setIsActive }) => {
  const handleClick = () => {
    if (setIsActive) {
      setIsActive(true); // Toggle the state
    }
  };
  return (
    <div $="bg-background text-center pb-5">
      <h3 $="text-[12px] text-[#737373] tracking-widest pt-5 pb-5">
        SUGGESTED QUERIES
      </h3>
      <div $="flex justify-center gap-4">
        <div $="border border-[#38A5E3] rounded-xl" onClick={handleClick}>
          <div $="w-[220px] h-[130px] flex flex-col justify-between items-center bg-[#38A5E30D]">
            <Ta.IconBuildingCommunity
              $="w-[48px] h-[48px] bg-[#38A5E34D] rounded-xl mt-[20px]"
              color="black"
              strokeWidth={1}
            />
            <div $="text-black flex items-center justify-center bg-[#38A5E30D] w-[100%] h-[35%]">
              <h3 $="text-[12px] font-medium">Multi-family Properties</h3>
            </div>
          </div>
        </div>
        <div $="border border-[#DF700B] rounded-xl" onClick={handleClick}>
          <div $="w-[220px] h-[130px] flex flex-col justify-between items-center bg-[#DF700B0D]">
            <Ta.IconSolarPanel
              $="w-[48px] h-[48px] bg-[#DF700B4D] rounded-xl mt-[20px]"
              color="black"
              strokeWidth={1}
            />
            <div $="text-black flex items-center justify-center bg-[#DF700B0D] w-[100%] h-[35%]">
              <h3 $="text-[12px] font-medium">Solar Projects</h3>
            </div>
          </div>
        </div>
        <div $="border border-[#F2C422] rounded-xl" onClick={handleClick}>
          <div $="w-[220px] h-[130px] flex flex-col justify-between items-center bg-[#F2C4220D]">
            <Ta.IconPlug
              $="w-[48px] h-[48px] bg-[#FDE2854D] rounded-xl mt-[20px]"
              color="black"
              strokeWidth={1}
            />
            <div $="text-black flex items-center justify-center bg-[#F2C4220D] w-[100%] h-[35%]">
              <h3 $="text-[12px] font-medium">EV Charging Stations</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
