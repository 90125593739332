import { memo } from "react";

type LayoutWithSidebarProps = {
  children: React.ReactNode;
  sidebar: React.ReactNode;
};

export const LayoutWithSidebar = memo(
  ({ children, sidebar }: LayoutWithSidebarProps) => {
    return (
      <div $="flex flex-grow bg-gray-100 justify-stretch items-stretch">
        <div $="w-[248px] flex-none bg-white border-r border-gray-300">
          {sidebar}
        </div>
        <div $="flex-grow relative">
          <div $="absolute inset-0 overflow-y-auto">{children}</div>
        </div>
      </div>
    );
  },
);

type LayoutWithNavbarProps = {
  children: React.ReactNode;
  navbar: React.ReactNode;
};

export const LayoutWithNavbar = memo(
  ({ children, navbar }: LayoutWithNavbarProps) => {
    return (
      <div $="relative flex-grow h-svh flex flex-col justify-stretch items-stretch overflow-hidden">
        {navbar}
        <div $="flex-grow relative">
          <div $="absolute inset-0 overflow-y-auto flex justify-stretch items-stretch">
            {children}
          </div>
        </div>
      </div>
    );
  },
);
