import { AppFailureCard } from "@/components/AppFailureDisplay";
import { Spinner } from "@/components/ui/spinner";
import { useCurrentOrg } from "@/contexts/current-org";
import { WorkspaceAPI, type WorkspaceID } from "@phosphor/server";
import { createContext, useContext } from "react";
import { useRpcQuery } from "../hooks/use-rpc-hooks";

const LoadedWorkspaceContext = createContext<
  WorkspaceAPI.WorkspaceWithUsers | undefined
>(undefined);

export const ProvideLoadedWorkspace: React.FC<
  React.PropsWithChildren<{ workspaceId: WorkspaceID }>
> = ({ children, workspaceId }) => {
  const { org } = useCurrentOrg();
  const {
    data: workspace,
    isLoading,
    error,
  } = useRpcQuery({
    orgID: org.id,
    queryKey: [org, "getWorkspace", workspaceId],
    request: new WorkspaceAPI.GetWorkspaceDetails({ workspaceId }),
  });

  if (isLoading) {
    return (
      <div $="h-svh w-full flex flex-grow flex-col justify-center items-center">
        <Spinner $ />
      </div>
    );
  }

  if (error) {
    return <AppFailureCard $ error={error} />;
  }

  return (
    <LoadedWorkspaceContext.Provider value={workspace}>
      {children}
    </LoadedWorkspaceContext.Provider>
  );
};

export const useLoadedWorkspace = () => {
  const context = useContext(LoadedWorkspaceContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentWorkspace must be used within a ProvideCurrentWorkspace",
    );
  }
  return context;
};
