import { type Atom, type PrimitiveAtom, atom, useSetAtom } from "jotai";
import { selectAtom } from "jotai/utils";
import { useEffect, useMemo } from "react";

export function useAtomFromValue<T>(value: T, equals = Object.is): Atom<T> {
  // need to wrap to prevent a problem with passing in a function
  // biome-ignore lint/correctness/useExhaustiveDependencies: ...
  const atomFrom: PrimitiveAtom<{ value: T }> = useMemo(
    () => atom({ value }),
    [],
  );
  // biome-ignore lint/correctness/useExhaustiveDependencies: ...
  const atomVal: Atom<T> = useMemo(
    () => selectAtom(atomFrom, (a) => a.value, equals),
    [atomFrom],
  );
  const setAtom = useSetAtom(atomFrom);
  useEffect(() => {
    setAtom({ value });
  }, [setAtom, value]);
  return atomVal;
}
