import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Ta } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { AppRequestFailures } from "@phosphor/server";
import React from "react";
import { useMemo } from "react";

interface AppFailureMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  error: AppRequestFailures;
}

/**
 * @example
 * <AppFailureMessage $ error={saveChangesMutation.error} />
 */
export const AppFailureMessage = React.forwardRef<
  HTMLDivElement,
  AppFailureMessageProps
>(({ error, className, ...props }, ref) => {
  const errorDetails = useMemo(() => getErrorDetails(error), [error]);
  if (!errorDetails) return null;
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "flex gap-1 flex-wrap p-4 border rounded-md",
        errorDetails.color,
        errorDetails.backgroundColor,
        className,
      )}
    >
      {errorDetails.message} {errorDetails.solution}
    </div>
  );
});
AppFailureMessage.displayName = "AppFailureMessage";

interface AppFailureDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  error: AppRequestFailures | null;
}

/**
 * @example
 * <AppFailureDisplay $ error={saveChangesMutation.error} />
 */
export const AppFailureDisplay = React.forwardRef<
  HTMLDivElement,
  AppFailureDisplayProps
>(({ error, className, ...props }, ref) => {
  const errorDetails = useMemo(() => error && getErrorDetails(error), [error]);
  if (!errorDetails) return null;
  return (
    <div
      ref={ref}
      {...props}
      className={cn("flex flex-col items-start space-y-2", className)}
    >
      <div $={`flex items-center space-x-2 ${errorDetails.color}`}>
        {errorDetails.icon}
        <h2 $="text-lg font-bold">{errorDetails.title}</h2>
      </div>
      <p $="text-gray-700 dark:text-gray-300">{errorDetails.message}</p>
      <p $="text-gray-600 dark:text-gray-400">{errorDetails.solution}</p>
    </div>
  );
});
AppFailureDisplay.displayName = "AppFailureDisplay";

/**
 * @example
 * <AppFailureCard $ error={createWorkspaceMutation.error} />
 */
export const AppFailureCard = React.forwardRef<
  HTMLDivElement,
  AppFailureDisplayProps
>(({ error, ...props }, ref) => {
  const errorDetails = useMemo(() => error && getErrorDetails(error), [error]);
  if (!errorDetails) return null;
  return (
    <Card ref={ref} {...props}>
      <CardHeader $>
        <div $={`flex items-center space-x-2 ${errorDetails.color}`}>
          {errorDetails.icon}
          <CardTitle $>{errorDetails.title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent $>
        <CardDescription $="text-gray-700 dark:text-gray-300">
          {errorDetails.message}
        </CardDescription>
        <CardDescription $="text-gray-600 dark:text-gray-400 mt-2">
          {errorDetails.solution}
        </CardDescription>
      </CardContent>
    </Card>
  );
});
AppFailureCard.displayName = "AppFailureCard";

export const getErrorDetails = (error: AppRequestFailures) => {
  switch (error._tag) {
    case "BadRequestWhoops":
      return {
        title: "Whoops.",
        message: error.whatWentWrong,
        solution: error.potentialSolution,
        icon: <Ta.IconAlertTriangle $="w-8 h-8" />,
        color: "text-yellow-600",
        backgroundColor: "bg-yellow-50 border-yellow-200",
        imageConcept: "confused_puppy",
      };
    case "InsufficientPermissions":
      return {
        title: "Sorry, you can't go there!",
        message: error.displayMessage,
        solution: error.potentialSolution,
        icon: <Ta.IconLock $="w-8 h-8" />,
        color: "text-red-600",
        backgroundColor: "bg-red-50 border-red-200",
        imageConcept: "sad_kitten",
      };
    case "InternalError":
      return {
        title: "Oops! Our bad!",
        message: "We're sorry, but something went wrong on our servers.",
        solution: `Please report this error to our team: ${error.referenceID}`,
        icon: <Ta.IconAlertCircle $="w-8 h-8" />,
        color: "text-red-600",
        backgroundColor: "bg-red-50 border-red-200",
        imageConcept: "embarrassed_robot",
      };
  }
};
