import type { JWTAccessToken, OrgID } from "@phosphor/server";
import { Option } from "effect";
import type React from "react";
import { createContext, useContext } from "react";
import { useCurrentUser } from "./CurrentUserContext";

interface BearerTokenContextType {
  getBearerToken: (orgId?: OrgID) => Option.Option<JWTAccessToken>;
}

const BearerTokenContext = createContext<BearerTokenContextType | undefined>(
  undefined,
);

export const ProvideBearerToken: React.FC<
  React.PropsWithChildren<{ orgId?: OrgID }>
> = ({ children, orgId }) => {
  const { currentUser } = useCurrentUser();

  const getBearerToken = (
    requestedOrgId?: OrgID,
  ): Option.Option<JWTAccessToken> => {
    const targetOrgId = requestedOrgId ?? orgId;

    if (targetOrgId) {
      const activeUserId = currentUser.activeOrgUsers[targetOrgId];
      const orgUser = currentUser.users.find(
        (user) =>
          user.orgInfo.id === targetOrgId && user.userInfo.id === activeUserId,
      );
      return Option.fromNullable(orgUser?.accessToken);
    }

    return Option.fromNullable(currentUser.account?.accessToken);
  };

  return (
    <BearerTokenContext.Provider value={{ getBearerToken }}>
      {children}
    </BearerTokenContext.Provider>
  );
};

export const useBearerToken = () => {
  const context = useContext(BearerTokenContext);
  if (context === undefined) {
    throw new Error("useBearerToken must be used within a ProvideBearerToken");
  }
  return context;
};
