import { S } from "@phosphor/prelude";
import { pipe } from "effect";
import { IconSpecifier, MarkdownText, OrgUserID } from "./common";

// workspaces
export const WorkspaceProposalID = pipe(S.UUID, S.brand("WorkspaceProposalID"));
export type WorkspaceProposalID = S.Schema.Type<typeof WorkspaceProposalID>;

export const ProjectID = pipe(S.UUID, S.brand("Databook.ProjectID"));
export type ProjectID = S.Schema.Type<typeof ProjectID>;

// Reference Sources / "Documents"

export const WorkspacePartyID = pipe(S.UUID, S.brand("Databook.PartyID"));
export type WorkspacePartyID = S.Schema.Type<typeof WorkspacePartyID>;
export const WorkspaceSourceID = pipe(S.UUID, S.brand("Databook.SourceID"));
export type WorkspaceSourceID = S.Schema.Type<typeof WorkspaceSourceID>;
export const WorkspaceModuleID = pipe(S.UUID, S.brand("Databook.ModuleID"));
export type WorkspaceModuleID = S.Schema.Type<typeof WorkspaceModuleID>;
export const WorkspaceStrategyID = pipe(S.UUID, S.brand("Databook.StrategyID"));
export type WorkspaceStrategyID = S.Schema.Type<typeof WorkspaceStrategyID>;
export const WorkspaceStrategyCheckID = pipe(
  S.UUID,
  S.brand("Databook.StrategyCheckID"),
);
export type WorkspaceStrategyCheckID = S.Schema.Type<
  typeof WorkspaceStrategyCheckID
>;
export const WorkspaceAssumptionID = pipe(
  S.UUID,
  S.brand("Databook.AssumptionID"),
);
export type WorkspaceAssumptionID = S.Schema.Type<typeof WorkspaceAssumptionID>;

/** A strategy is a list of concerns or risks that should be evaluated and applied to some kind of model. */

export const MVPWorkspaceCompacted = S.Struct({
  name: S.String,
  description: S.String,
  icon: IconSpecifier,
  // For displaying the "assumptions" view
  modules: S.Array(
    S.Struct({
      id: WorkspaceModuleID,
      displayName: S.String,
      // /** old school "identifier" */
      // identifier: RosettaModelIdentifier,
    }),
  ),
  agreementPartys: S.Array(
    S.Struct({
      id: WorkspacePartyID,
      displayName: S.String,
    }),
  ),
  /** aka "Model Inputs" / "Variables" */
  assumptions: S.Array(
    S.Struct({
      id: WorkspaceAssumptionID,
      /** parent module ID */
      moduleId: WorkspaceModuleID,
      displayLabel: S.String,
      // value such as "2024-09-01" or `0.5` or a Seconds since Unix Epoch
      value: S.Union(S.Number, S.String),
      format: S.Literal("Number", "Boolean", "String", "Date"),
      unit: S.String,
      editorComments: MarkdownText,
      lastChangedBy: OrgUserID.pipe(S.optional),
      lastChangedAt: S.DateFromNumber.pipe(S.optional),
    }),
  ),
  sources: S.Array(
    S.Struct({
      id: WorkspaceSourceID,
      displayTitle: MarkdownText,
      icon: IconSpecifier,
      fileName: S.String,
      uploaderComments: MarkdownText,
      mvpDocumentPreview: S.Literal("GenericPDF"),
      uploadedBy: OrgUserID.pipe(S.optional),
    }),
  ),
  agreementPartySources: S.Array(
    S.Struct({
      partyId: WorkspacePartyID,
      sourceId: WorkspaceSourceID,
      comment: MarkdownText,
    }),
  ),
  assumptionSources: S.Array(
    S.Struct({
      sourceId: WorkspaceSourceID,
      assumptionId: WorkspaceAssumptionID,
      linkedBy: OrgUserID.pipe(S.optional),
      linkedAt: S.DateFromNumber.pipe(S.optional),
      // unlinkedAt: S.DateFromNumber.pipe(S.optional),
    }),
  ),
  strategies: S.Array(
    S.Struct({
      id: WorkspaceStrategyID,
      displayTitle: MarkdownText,
      description: MarkdownText,
      icon: IconSpecifier,
      statusScaleMethod: S.Literal("TrafficLights", "OutOfFive"),
      checks: S.Array(
        S.Struct({
          titleDisplay: S.String,
          goal: S.String,
          actual: S.String,
          status: S.Union(S.Literal("Green", "Yellow", "Red"), S.Number),
          statusCriteria: S.Array(
            S.Struct({
              statusDisplay: S.String,
              // "Instruction" for the user to follow to check the status
              criteriaInstruction: MarkdownText,
            }),
          ),
        }),
      ),
    }),
  ),
  strategySources: S.Array(
    S.Struct({
      sourceId: WorkspaceSourceID,
      strategyId: WorkspaceStrategyID,
      linkedBy: OrgUserID.pipe(S.optional),
      linkedAt: S.DateFromNumber.pipe(S.optional),
      // unlinkedAt: S.DateFromNumber.pipe(S.optional),
    }),
  ),
});
export type MVPWorkspaceCompacted = S.Schema.Type<typeof MVPWorkspaceCompacted>;

export const MvpBuiltinLibraryItemID = pipe(
  S.String,
  S.brand("MvpBuiltinLibraryItemID"),
);
export type MvpBuiltinLibraryItemID = S.Schema.Type<
  typeof MvpBuiltinLibraryItemID
>;

export const WorkspaceChangeKind = S.Union(
  /**
   * Represents the import of a predefined library item template (which is usually hardcoded).
   *
   * This simplified WorkspaceChangeKind facilitates the creation of new library items
   * from existing templates without full changeset functionality.
   *
   * Key aspects:
   * 1. Uses "ImportMvpBuiltinLibraryItem" as the WorkspaceChangeKind.
   * 2. Contains a single "name" property identifying the template to import.
   * 3. When creating a library item with mvpImportLibraryItemName:
   *    - Creates a new changeset with this WorkspaceChangeKind.
   *    - Associates the changeset with the new library item.
   * 4. Allows for future expansion to more complex change types.
   * 5. Front-end provides predefined templates (BUILTIN_LIBRARY_ITEMS) for mvpImportLibraryItemName.
   *
   * This approach balances immediate template-based library item usage with
   * the potential for more sophisticated management in the future.
   */
  S.Struct({
    _tag: S.Literal("ImportMvpBuiltinLibraryItem"),
    /** e.g. "SOLAR_PROJECT" */
    name: MvpBuiltinLibraryItemID,
  }),
  S.Struct({
    _tag: S.Literal("MVP202410INIT"),
    blob: MVPWorkspaceCompacted,
  }),
);
export type WorkspaceChangeKind = S.Schema.Type<typeof WorkspaceChangeKind>;

export const WorkspaceChange = S.Struct({
  timestamp: S.Number,
  change: WorkspaceChangeKind,
});
export type WorkspaceChange = S.Schema.Type<typeof WorkspaceChange>;

export const WorkspaceChangeset = S.Struct({
  id: S.UUID,
  changes: S.Array(WorkspaceChange),
  authoredBy: OrgUserID.pipe(S.optional),
  mergedBy: OrgUserID.pipe(S.optional),
  proposalId: WorkspaceProposalID.pipe(S.optional),
  createdAt: S.DateFromNumber,
});
export type WorkspaceChangeset = S.Schema.Type<typeof WorkspaceChangeset>;

export const WorkspaceCommentContentKind = S.Union(
  S.Struct({
    _tag: S.Literal("Markdown"),
    markdown: MarkdownText,
  }),
  S.Struct({
    _tag: S.Literal("Changes"),
    changes: S.Array(WorkspaceChangeKind),
  }),
);
export type WorkspaceCommentContentKind = S.Schema.Type<
  typeof WorkspaceCommentContentKind
>;

export const WorkspaceChangesetID = pipe(
  S.UUID,
  S.brand("WorkspaceChangesetID"),
);
export type WorkspaceChangesetID = S.Schema.Type<typeof WorkspaceChangesetID>;

export const LibraryItemID = pipe(S.UUID, S.brand("LibraryItemID"));
export type LibraryItemID = S.Schema.Type<typeof LibraryItemID>;
