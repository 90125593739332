import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useCurrentOrg } from "@/contexts/current-org";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import {
  type OrgFolderID,
  WorkspaceAPI,
  type WorkspaceID,
} from "@phosphor/server";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { AppFailureCard } from "../AppFailureDisplay";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

interface CreateWorkspaceButtonProps {
  button: React.ReactNode;
  selectedFolder: OrgFolderID | undefined;
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  editing?: {
    workspaceId: WorkspaceID;
    initialName: string;
    tags: string;
  };
}

export const CreateWorkspaceButton: React.FC<CreateWorkspaceButtonProps> = ({
  button,
  selectedFolder,
  editing,
  isOpen: _isOpen1,
  setIsOpen: _setIsOpen1,
}) => {
  const navigate = useNavigate();
  const { org } = useCurrentOrg();
  const [_isOpen2, _setIsOpen2] = useState(false);
  const isOpen = _isOpen1 ?? _isOpen2;
  const setIsOpen = _setIsOpen1 ?? _setIsOpen2;

  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [workspaceTags, setWorkspaceTags] = useState<string>("");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (editing) {
      setWorkspaceName(editing.initialName);
      setWorkspaceTags(editing.tags);
    }
  }, [editing]);

  const createWorkspaceMutation = useRpcMutation({
    mutate: (input: WorkspaceAPI.CreateWorkspace) => input,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getWorkspaces", selectedFolder],
      });
      navigate({
        to: "/$org/workspace/$workspace",
        params: { org: org.urlKey, workspace: data.workspace_id },
      });
      setIsOpen(false);
    },
    onError: (error) => {
      console.error("Failed to create workspace:", error);
    },
  });

  const updateWorkspaceMutation = useRpcMutation({
    mutate: (input: WorkspaceAPI.UpdateWorkspace) => input,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getWorkspaces", selectedFolder],
      });
      setIsOpen(false);
    },
    onError: (error) => {
      console.error("Failed to update workspace:", error);
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "name") {
      setWorkspaceName(value);
    } else if (name === "tags") {
      setWorkspaceTags(value);
    }
  };

  const handleSubmit = () => {
    if (editing) {
      updateWorkspaceMutation.mutate(
        new WorkspaceAPI.UpdateWorkspace({
          workspaceId: editing.workspaceId,
          displayName: workspaceName,
          mock_tags: workspaceTags,
        }),
      );
    } else {
      createWorkspaceMutation.mutate(
        new WorkspaceAPI.CreateWorkspace({
          displayName: workspaceName,
          folder: selectedFolder,
        }),
      );
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger $ asChild>
        {button}
      </DialogTrigger>
      <DialogContent $="bg-background [&>button]:hidden p-5">
        <DialogHeader $>
          <DialogTitle $="text-[#0F172A] text-xl font-semibold mb-3">
            {editing ? "Update Workspace" : "Create New Workspace"}
          </DialogTitle>
          <DialogDescription $="space-y-4">
            <Input
              name="name"
              value={workspaceName}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              $="bg-background w-full"
              placeholder="Workspace name"
            />
            <Input
              name="tags"
              value={workspaceTags}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              $="bg-background w-full"
              placeholder="Tags (comma-separated)"
            />
            <AppFailureCard
              $
              error={
                createWorkspaceMutation.error || updateWorkspaceMutation.error
              }
            />
            <div $="flex justify-end gap-2 mt-6">
              <Button
                $="bg-white text-[#0F172A] hover:bg-gray-100 rounded-full"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                $="bg-[#0F172A] text-white rounded-full hover:bg-[#0F172A]"
                onClick={handleSubmit}
              >
                {editing ? "Update" : "Continue"}
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
