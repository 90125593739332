import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  Color,
  IconSpecifier,
  OrgFolderID,
  OrgID,
} from "../common";

export class FolderInfo extends S.Class<FolderInfo>("FolderInfo")({
  org_id: OrgID,
  folder_id: OrgFolderID,
  folder_display_name: S.String,
  color: Color.pipe(S.optional),
  icon: IconSpecifier.pipe(S.optional),
}) {}

export class CreateFolder extends S.TaggedRequest<CreateFolder>()(
  "CreateFolder",
  {
    payload: {
      org: OrgID,
      displayName: S.String,
      color: Color.pipe(S.optional),
      icon: IconSpecifier.pipe(S.optional),
    },
    failure: AppRequestFailures,
    success: FolderInfo,
  },
) {}

export class UpdateFolder extends S.TaggedRequest<UpdateFolder>()(
  "UpdateFolder",
  {
    payload: {
      folder_id: OrgFolderID,
      displayName: S.String.pipe(S.optional),
      color: Color.pipe(S.optional),
      icon: IconSpecifier.pipe(S.optional),
    },
    failure: AppRequestFailures,
    success: FolderInfo,
  },
) {}

export class GetOrgFolders extends S.TaggedRequest<GetOrgFolders>()(
  "GetOrgFolders",
  {
    payload: { org: OrgID },
    success: S.Array(FolderInfo),
    failure: AppRequestFailures,
  },
) {}
