import { Ta } from "./ui/icons";

export const ActivityContentMock = () => {
  return (
    <ul $>
      <li $="flex justify-between items-center pb-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Jane
            </a>{" "}
            <span $="text-black">is working on </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch TUV
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Dave
            </a>{" "}
            <span $="text-black">merged </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch XYZ
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Alice
            </a>{" "}
            <span $="text-black">created new </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch HIJ
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />

          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Morgan
            </a>{" "}
            <span $="text-black"> merged </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch ABC
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Yanna
            </a>{" "}
            <span $="text-black">is working on </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch LMNO
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Morgan
            </a>{" "}
            <span $="text-black"> merged </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch ABC
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center py-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Yanna
            </a>{" "}
            <span $="text-black"> created new </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch LMNO
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
      <li $="flex justify-between items-center pt-3">
        <div $="flex items-center">
          <Ta.IconArrowBearRight $="w-[20px] h-[20px] text-black" />
          <span $="text-sm">
            <a href="#" $="text-[#6B5889] font-semibold">
              Yanna
            </a>{" "}
            <span $="text-black"> is working on </span>
            <a
              href="#"
              $="text-black bg-[#F4F0F9] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0] px-[4px] rounded-sm border-[0.5px] border[#D4CBE0]"
            >
              branch LMNO
            </a>
          </span>
        </div>
        <span $="text-xs text-gray-500">Thu May 23 2024</span>
      </li>
    </ul>
  );
};
