import { AddImageModal } from "@/components/AddImageModal";
import { Home } from "@/components/Home";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { OrgAPI, type OrgID } from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCurrentOrg } from "../contexts/current-org";

export const Route = createFileRoute("/$org/_layout/")({
  component: OrgIndex,
});

function OrgIndex() {
  const { org, user } = useCurrentOrg();
  const [isEditing, setIsEditing] = useState(false);
  const { data: orgInfo, refetch } = useRpcQuery({
    queryKey: ["org", org.id],
    orgID: org.id,
    request: new OrgAPI.GetOrgInfo({ orgID: org.id }),
  });

  const isAdmin = user.role === "admin";

  const handleEditClick = () => setIsEditing(true);
  const handleEditComplete = () => {
    setIsEditing(false);
    refetch();
  };
  const handleCancel = () => setIsEditing(false);

  return (
    <div $>
      <div $="p-4 max-w-2xl mx-auto">
        <h2 $="flex items-center gap-3 text-2xl font-bold mb-4 group">
          {isEditing ? (
            <UpdateOrgInfoForm
              orgID={org.id}
              initialName={orgInfo?.orgInfo.displayName ?? org.displayName}
              initialLogoUrl={orgInfo?.orgInfo.logoUrl?.toString() ?? ""}
              onEditComplete={handleEditComplete}
              onCancel={handleCancel}
            />
          ) : (
            <>
              {orgInfo?.orgInfo.logoUrl && (
                <Avatar $="rounded-lg">
                  <AvatarImage
                    $
                    src={orgInfo.orgInfo.logoUrl.toString()}
                    alt="logo"
                  />
                </Avatar>
              )}
              {orgInfo?.orgInfo.displayName ?? org.displayName}
              {isAdmin && (
                <Button
                  $
                  variant="ghost"
                  size="icon"
                  onClick={handleEditClick}
                  aria-label="Edit organization name and logo"
                >
                  <Ta.IconPencil $="w-5 h-5 opacity-0 transition-opacity group-hover:opacity-100" />
                </Button>
              )}
            </>
          )}
        </h2>
        <p $="mb-4">Welcome to your organization&apos;s dashboard.</p>
      </div>
      <Home />
    </div>
  );
}

interface UpdateOrgInfoFormProps {
  orgID: OrgID;
  initialName: string;
  initialLogoUrl?: string;
  onEditComplete: () => void;
  onCancel: () => void;
}

function UpdateOrgInfoForm({
  orgID,
  initialName,
  initialLogoUrl = "",
  onEditComplete,
  onCancel,
}: UpdateOrgInfoFormProps) {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      name: initialName,
      logoUrl: initialLogoUrl,
    },
  });

  const updateOrgMutation = useRpcMutation({
    orgID,
    mutate: (input: OrgAPI.UpdateOrgInfo) => input,
    onSuccess: onEditComplete,
  });

  const onSubmit = form.handleSubmit((data) => {
    updateOrgMutation.mutate(
      new OrgAPI.UpdateOrgInfo({
        orgID,
        displayName: data.name,
        logoUrl: data.logoUrl,
      }),
    );
  });

  const handleImageChosen = (newURL: string) => {
    form.setValue("logoUrl", newURL);
    setIsImageModalOpen(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={onSubmit} $="flex items-center gap-2">
        <Button
          $
          type="button"
          variant="outline"
          size="icon"
          onClick={() => setIsImageModalOpen(true)}
        >
          {form.watch("logoUrl") ? (
            <img
              $="w-6 h-6 object-cover"
              src={form.watch("logoUrl")}
              alt="Organization logo"
            />
          ) : (
            <Ta.IconImageInPicture $="w-4 h-4" />
          )}
        </Button>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem $>
              <FormControl $>
                <Input $ {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button $ type="submit" size="sm">
          Save
        </Button>
        <Button $ type="button" variant="outline" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </form>
      <AddImageModal
        isOpen={isImageModalOpen}
        initialURL={form.watch("logoUrl")}
        onChosen={handleImageChosen}
        onDismiss={() => setIsImageModalOpen(false)}
        modalTitle="Choose Organization Logo"
      />
    </Form>
  );
}
