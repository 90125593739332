import { useWorkspaceNavigation } from "@/components/WorkspaceNavigationService";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import {
  MarkdownText,
  type WorkspaceID,
  WorkspaceProposalAPI,
} from "@phosphor/server";
import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useState } from "react";

interface CreateWorkspaceProposalButtonProps {
  button: React.ReactNode;
  workspaceId: WorkspaceID;
}

export const CreateWorkspaceProposalButton: React.FC<
  CreateWorkspaceProposalButtonProps
> = ({ button, workspaceId }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const workspaceNav = useWorkspaceNavigation();
  const queryClient = useQueryClient();
  const createProposalMutation = useRpcMutation({
    mutate: (input: WorkspaceProposalAPI.CreateProposal) => input,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [workspaceId, "proposals"],
        stale: true,
      });
      toast({
        title: "Proposal Created",
        description: "Your proposal has been created successfully.",
      });
      setTitle("");
      setDescription("");
      setIsOpen(false);
      workspaceNav.setSelectedProposals((prev) => [...prev, result.proposalId]);
    },
    onError: (error) => toast({ error }),
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const handleSubmit = () => {
    if (title.trim()) {
      createProposalMutation.mutate(
        new WorkspaceProposalAPI.CreateProposal({
          workspaceId,
          title,
          description: MarkdownText.make(description),
          initialContent: [],
        }),
      );
    }
  };

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger $ asChild>
        {button}
      </DialogTrigger>
      <DialogContent $="bg-background p-5">
        <DialogHeader $>
          <DialogTitle $="text-[#0F172A] text-xl font-semibold mb-3">
            Create New Proposal
          </DialogTitle>
          <div $="space-y-4">
            <Input
              name="title"
              value={title}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              $="bg-background w-full"
              placeholder="Proposal Title"
            />
            <Textarea
              name="description"
              value={description}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              $="bg-background w-full"
              placeholder="Proposal Description"
              rows={3}
            />
            <div $="flex justify-end gap-2 mt-6">
              <Button
                $="bg-white text-[#0F172A] hover:bg-gray-100 rounded-full"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                $="bg-[#0F172A] text-white rounded-full hover:bg-[#0F172A]"
                onClick={handleSubmit}
              >
                Create
              </Button>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
