import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  CommentTsID,
  CommentVisibility,
  MarkdownText,
  OrgUserID,
  ProposalStatus,
  WorkspaceID,
} from "../common";
import { WorkspaceChangeKind, WorkspaceProposalID } from "../workspace-common";

// Comment content types
export const WorkspaceCommentContentKind = S.Union(
  S.Struct({
    _tag: S.Literal("Markdown"),
    markdown: MarkdownText,
  }),
  S.Struct({
    _tag: S.Literal("Changes"),
    changes: S.Array(WorkspaceChangeKind),
  }),
);
export type WorkspaceCommentContentKind = S.Schema.Type<
  typeof WorkspaceCommentContentKind
>;

// Proposal creation
export class ProposalCreated extends S.Class<ProposalCreated>(
  "ProposalCreated",
)({
  proposalId: WorkspaceProposalID,
}) {}

export class CreateProposal extends S.TaggedRequest<CreateProposal>()(
  "CreateProposal",
  {
    payload: {
      workspaceId: WorkspaceID,
      title: S.String,
      description: MarkdownText,
      initialContent: S.Array(WorkspaceCommentContentKind),
    },
    success: ProposalCreated,
    failure: AppRequestFailures,
  },
) {}

// Proposal retrieval
export class ProposalInfo extends S.Class<ProposalInfo>("ProposalInfo")({
  id: WorkspaceProposalID,
  workspaceId: WorkspaceID,
  title: S.String,
  description: MarkdownText,
  createdBy: OrgUserID,
  createdAt: S.Date,
  updatedAt: S.Date,
}) {}

export class ProposalsRetrieved extends S.Class<ProposalsRetrieved>(
  "ProposalsRetrieved",
)({
  proposals: S.Array(ProposalInfo),
}) {}

export class ListProposals extends S.TaggedRequest<ListProposals>()(
  "ListProposals",
  {
    payload: {
      workspaceId: WorkspaceID,
      statuses: S.Array(ProposalStatus).pipe(S.optional),
    },
    success: ProposalsRetrieved,
    failure: AppRequestFailures,
  },
) {}

// Comment info and retrieval
export class CommentInfo extends S.Class<CommentInfo>("CommentInfo")({
  proposalId: WorkspaceProposalID,
  ts: CommentTsID,
  authorUserId: OrgUserID,
  content: S.Array(WorkspaceCommentContentKind),
  topic: S.String.pipe(S.optional),
  replyToTs: CommentTsID.pipe(S.optional),
  visibility: CommentVisibility,
  reviewStatus: ProposalStatus.pipe(S.optional),
  version: S.Number,
  updatedAt: S.Date,
}) {}

// Single proposal retrieval with comments
export class ProposalWithComments extends S.Class<ProposalWithComments>(
  "ProposalWithComments",
)({
  proposal: ProposalInfo,
  comments: S.Array(CommentInfo),
}) {}

export class GetProposal extends S.TaggedRequest<GetProposal>()("GetProposal", {
  payload: {
    proposalId: WorkspaceProposalID,
  },
  success: ProposalWithComments,
  failure: AppRequestFailures,
}) {}

// Proposal update
export class UpdateProposalResult extends S.Class<UpdateProposalResult>(
  "UpdateProposalResult",
)({
  proposalId: WorkspaceProposalID,
}) {}

export class UpdateProposal extends S.TaggedRequest<UpdateProposal>()(
  "UpdateProposal",
  {
    payload: {
      proposalId: WorkspaceProposalID,
      title: S.String.pipe(S.optional),
      description: MarkdownText.pipe(S.optional),
      status: ProposalStatus.pipe(S.optional),
    },
    success: UpdateProposalResult,
    failure: AppRequestFailures,
  },
) {}

// Comment creation
export class CommentCreated extends S.Class<CommentCreated>("CommentCreated")({
  proposalId: WorkspaceProposalID,
  ts: CommentTsID,
}) {}

export class CreateComment extends S.TaggedRequest<CreateComment>()(
  "CreateComment",
  {
    payload: {
      proposalId: WorkspaceProposalID,
      content: S.Array(WorkspaceCommentContentKind),
      topic: S.String.pipe(S.optional),
      replyToTs: CommentTsID.pipe(S.optional),
      reviewStatus: ProposalStatus.pipe(S.optional),
    },
    success: CommentCreated,
    failure: AppRequestFailures,
  },
) {}

// Comment listing
export class CommentsRetrieved extends S.Class<CommentsRetrieved>(
  "CommentsRetrieved",
)({
  comments: S.Array(CommentInfo),
}) {}

export class ListComments extends S.TaggedRequest<ListComments>()(
  "ListComments",
  {
    payload: {
      proposalId: WorkspaceProposalID,
      topic: S.String.pipe(S.optional),
    },
    success: CommentsRetrieved,
    failure: AppRequestFailures,
  },
) {}

// Comment update
export class UpdateCommentResult extends S.Class<UpdateCommentResult>(
  "UpdateCommentResult",
)({
  proposalId: WorkspaceProposalID,
  ts: CommentTsID,
}) {}

export class UpdateComment extends S.TaggedRequest<UpdateComment>()(
  "UpdateComment",
  {
    payload: {
      proposalId: WorkspaceProposalID,
      commentTs: CommentTsID,
      content: S.Array(WorkspaceCommentContentKind),
    },
    success: UpdateCommentResult,
    failure: AppRequestFailures,
  },
) {}

// Delete proposal
export class DeleteProposal extends S.TaggedRequest<DeleteProposal>()(
  "DeleteProposal",
  {
    payload: {
      proposalId: WorkspaceProposalID,
    },
    success: S.Void,
    failure: AppRequestFailures,
  },
) {}
