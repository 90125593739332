import { Dashboard } from "@/components/Dashboard";
import { Shimmer } from "@/components/Shimmer";
import { WorkspaceDetails } from "@/components/WorkspaceDetails";
import { SetupCurrentWorkspaceDialog } from "@/components/workspace/SetupCurrentWorkspaceDialog";
import { useLoadedWorkspace } from "@/contexts/current-workspace";
import { createFileRoute } from "@tanstack/react-router";

// Define the index route for the workspace layout
export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout/",
)({
  component: WorkspaceIndex,
});

function WorkspaceIndex() {
  const { workspaceId } = useLoadedWorkspace();

  return (
    <div $="flex flex-grow justify-between h-screen overflow-y-auto p-4 min-w-[800px] text-black">
      <div $="w-[60%]">
        <Dashboard />
        <Shimmer />
        <SetupCurrentWorkspaceDialog workspaceId={workspaceId} />
      </div>
      <div $="w-[38%]">
        <WorkspaceDetails />
      </div>
    </div>
  );
}
