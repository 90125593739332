import { cn } from "@/lib/utils";
import * as React from "react";

type RosettaMocksType = "solar" | "timings" | "inverted";

type RosettaMocksProps = React.HTMLAttributes<HTMLIFrameElement> & {
  type: RosettaMocksType;
};

export const RosettaMocks = React.forwardRef<
  HTMLIFrameElement,
  RosettaMocksProps
>(({ type, className, ...props }, ref) => {
  const srcMap: Record<RosettaMocksType, string> = {
    solar: "/rosetta1/PhosphorModelingStandalone.htm",
    timings: "/rosetta1/TimingsStandalone.htm",
    inverted: "/rosetta1/PhosphorSolarProjectRefactor.htm",
  };

  return (
    <iframe
      ref={ref}
      className={cn("flex-grow", className)}
      src={srcMap[type]}
      {...props}
    />
  );
});

RosettaMocks.displayName = "RosettaMocks";
