import { toJSON } from "effect/Inspectable";
import { isAtom } from "../jotai/isAtom";
import type { JotaiGetter } from "../jotai/jotai-types";
import type { $IntentionalAny } from "./IntentionalAny.type.js";
import { tightJsonStringify } from "./tightJsonStringify.js";

const UNDEFINED = "∅";
const UNDEFINED_RE = new RegExp(`"${UNDEFINED}"`, "g");
function baseReplacer(key: string, obj: $IntentionalAny) {
  obj = toJSON(obj);
  if (obj instanceof Error) {
    return { ...obj, name: obj.name, message: obj.message, stack: obj.stack };
  } else if (obj === undefined) {
    return UNDEFINED;
  } else if (typeof obj === "string") {
    // hide JWT tokens
    return String(obj).startsWith("eyJ")
      ? String(obj).slice(0, 20) + "..."
      : obj;
  }

  return obj;
}

export function tightJavaScriptify(value: unknown, get?: JotaiGetter): string {
  const js = get
    ? tightJsonStringify(isAtom(value) ? get(value) : value, (key, obj) =>
        baseReplacer(key, isAtom(obj) ? get(obj) : obj),
      )
    : tightJsonStringify(value, baseReplacer);

  if (!js) return String(js);

  return js
    .replace(/(\\?")([^"]+)\1:/g, "$2:")
    .replace(UNDEFINED_RE, "undefined")
    .replace(/([^\\"])"((:?[^"\\]+|\\[^"]|\\")+)"/g, (full, init, inner) => {
      if (inner.includes("\\n")) {
        return `${init}\`${inner
          .replace(/`/g, "\\`")
          .replace(/\\"/g, '"')
          .replace(/([^\\])\\n/g, "$1\n")}\``;
      }
      return full;
    });
}
