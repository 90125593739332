import { S, URLFromString } from "@phosphor/prelude";
import {
  AppRequestFailures,
  OrgID,
  OrgURLKey,
  WorkspaceID,
  WorkspaceInvitationHandoffKey,
} from "../common";

export class CreateInvite extends S.TaggedRequest<CreateInvite>()(
  "CreateInvite",
  {
    payload: {
      workspace_id: WorkspaceID,
    },
    success: WorkspaceInvitationHandoffKey,
    failure: AppRequestFailures,
  },
) {}

export class AcceptInvite extends S.TaggedRequest<AcceptInvite>()(
  "AcceptInvite",
  {
    payload: { invitation_key: WorkspaceInvitationHandoffKey },
    success: S.Struct({
      workspace_id: WorkspaceID,
      org_id: OrgID,
    }),
    failure: AppRequestFailures,
  },
) {}

export class VerifyInvitation extends S.TaggedRequest<VerifyInvitation>()(
  "VerifyInvitation",
  {
    payload: { token: WorkspaceInvitationHandoffKey },
    success: S.Struct({
      workspaceDisplayName: S.String,
      organizationDisplayName: S.String,
      organizationURLKey: OrgURLKey,
      organizationPhotoURL: URLFromString.pipe(S.optional),
    }),
    failure: AppRequestFailures,
  },
) {}
