import { BUILTIN_LIBRARY_ITEMS } from "@/components/library-items/BUILTIN_LIBRARY_ITEMS";
import { LibraryItemChooser } from "@/components/library-items/LibraryItemChooser";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  type FuseSearchHookOptions,
  useFuseSearch,
} from "@/hooks/useFuseSearch";
import { cn } from "@/lib/utils";
import type {
  LibraryItemAPI,
  LibraryItemID,
  MvpBuiltinLibraryItemID,
} from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

type CreateLibraryItemOptions = {
  title: string;
  description: string;
  /** e.g. "SOLAR_PROJECT", this will be translated into an initial WorkspaceChangeKind */
  mvpBuiltinLibraryItemID: MvpBuiltinLibraryItemID;
};

type SelectedLibraryItem = { BUILTIN?: string; ITEM?: LibraryItemID };

interface ImportLibraryItemListProps {
  items: readonly LibraryItemAPI.LibraryItemInfo[];
  selectedItemId: SelectedLibraryItem | null;
  onSelectItem: (selection: SelectedLibraryItem) => void;
  onCreateItem: (options: CreateLibraryItemOptions) => void;
}

const FUSE_LIBRARY_ITEM_OPTIONS: FuseSearchHookOptions<LibraryItemAPI.LibraryItemInfo> =
  {
    keys: [
      { name: "name" as const, weight: 0.7 },
      { name: "description" as const, weight: 0.3 },
    ],
  };
const FUSE_LIBRARY_ITEM_SAMPLE_OPTIONS: FuseSearchHookOptions<
  (typeof BUILTIN_LIBRARY_ITEMS)[number]
> = {
  keys: [
    { name: "name" as const, weight: 0.7 },
    { name: "searchKeywords" as const, weight: 0.3 },
  ],
};

const libraryItemButtonClass = (selected: boolean) =>
  cn(
    `w-full justify-start rounded-none hover:bg-[#DBEAFE] hover:text-blue-500 border-b border-grey-200 border-l-0 border-r-0 border-t-0 font-normal`,
    selected ? "bg-[#DBEAFE] text-blue-500" : "bg-transparent text-primary",
  );

const ImportLibraryItemList: React.FC<ImportLibraryItemListProps> = ({
  items,
  selectedItemId,
  onSelectItem,
  onCreateItem,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredItems = useFuseSearch(
    { items, search: searchTerm },
    FUSE_LIBRARY_ITEM_OPTIONS,
  );
  const filteredBuiltinItems = useFuseSearch(
    { items: BUILTIN_LIBRARY_ITEMS, search: searchTerm },
    FUSE_LIBRARY_ITEM_SAMPLE_OPTIONS,
  );

  return (
    <>
      <div $="flex justify-stretch items-center p-4">
        <h2 $="text-left text-xl font-semibold flex-grow">Model Library</h2>
        <CreateNewItemButton onCreateItem={onCreateItem}>
          <Button $ variant="ghost" size="icon">
            <Ta.IconPlus $="w-4 h-4" />
          </Button>
        </CreateNewItemButton>
      </div>
      <div $="px-4 space-y-4 mb-4">
        <div $="relative">
          <Input
            type="text"
            placeholder="Search Library Items"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            $="w-full pl-8"
          />
          <Ta.IconSearch $="w-4 h-4 text-gray-500 absolute left-2 top-1/2 transform -translate-y-1/2" />
        </div>
      </div>
      {filteredItems.map((item) => (
        <Button
          $={libraryItemButtonClass(selectedItemId?.ITEM === item.id)}
          key={item.id}
          onClick={() => onSelectItem({ ITEM: item.id })}
        >
          {item.name}
        </Button>
      ))}
      {filteredBuiltinItems.map((item) => (
        <Button
          $={libraryItemButtonClass(selectedItemId?.BUILTIN === item.key)}
          key={item.key}
          onClick={() => onSelectItem({ BUILTIN: item.key })}
        >
          {item.name}
        </Button>
      ))}
    </>
  );
};

const CreateNewItemButton = (props: {
  onCreateItem: (options: CreateLibraryItemOptions) => void;
  children: React.ReactNode;
}) => {
  const [newItemName, setNewItemName] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");
  const [newItemMvpBuiltinKey, setNewItemMvpBuiltinKey] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCreateItem = () => {
    const builtin =
      newItemMvpBuiltinKey &&
      BUILTIN_LIBRARY_ITEMS.find((a) => a.key === newItemMvpBuiltinKey);
    if (newItemName.trim() && builtin) {
      props.onCreateItem({
        title: newItemName.trim(),
        description: newItemDescription.trim(),
        mvpBuiltinLibraryItemID: builtin.builtinID,
      });
      setNewItemName("");
      setNewItemDescription("");
      setNewItemMvpBuiltinKey("");
      setIsDialogOpen(false);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger $ asChild>
        {props.children}
      </DialogTrigger>
      <DialogContent $>
        <DialogHeader $>
          <DialogTitle $>Create New Library Item</DialogTitle>
          <DialogDescription $>
            Create a new library item from an MVP import name.
          </DialogDescription>
        </DialogHeader>
        <div $="space-y-4">
          <div $>
            <Label $ htmlFor="newItemName">
              Name
            </Label>
            <Input
              id="newItemName"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              placeholder="Enter library item name"
              $
            />
          </div>
          <div $>
            <Label $ htmlFor="newItemDescription">
              Description
            </Label>
            <Input
              id="newItemDescription"
              value={newItemDescription}
              onChange={(e) => setNewItemDescription(e.target.value)}
              placeholder="Enter library item description"
              $
            />
          </div>
          <div $>
            <Label $ htmlFor="newItemMvpImportName">
              Based on MVP Import Builtin
            </Label>
            <Select
              value={newItemMvpBuiltinKey}
              onValueChange={setNewItemMvpBuiltinKey}
            >
              <SelectTrigger $="w-full" id="newItemMvpImportName">
                <SelectValue $ placeholder="Select MVP Builtin Library Item" />
              </SelectTrigger>
              <SelectContent $>
                {BUILTIN_LIBRARY_ITEMS.toSorted(
                  ({ builtinID: a }, { builtinID: b }) => a.localeCompare(b),
                ).map((a) => (
                  <SelectItem $ value={a.key} key={a.key}>
                    {a.builtinID} / {a.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button onClick={handleCreateItem} $="w-full">
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const Route = createFileRoute("/$org/_layout/models")({
  component: Models,
});

function Models() {
  return (
    <LibraryItemChooser
      className="h-full"
      onImportBuiltinItem={(builtin) => {
        alert(`Importing builtin item: ${builtin}`);
      }}
      onImportLibraryItem={(id) => {
        alert(`Importing library item: ${id}`);
      }}
    />
  );
}
