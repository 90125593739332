import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Ta } from "@/components/ui/icons";
import { SAMPLE_SOLAR } from "@/contexts/sample-workspace-data";
import { cn } from "@/lib/utils";
import type { WorkspaceStrategyID } from "@phosphor/server";
import type React from "react";

interface LinkedStrategyChipProps {
  strategyId: WorkspaceStrategyID;
}

export const LinkedStrategyChip: React.FC<LinkedStrategyChipProps> = ({
  strategyId,
}) => {
  const sampleStrategy = SAMPLE_SOLAR.strategies.find(
    (strategy) => strategy.id === strategyId,
  );

  const displayData = sampleStrategy ?? {
    displayTitle: "Unknown Strategy",
    description: "",
    statusScaleMethod: "TrafficLights" as const,
    checks: [],
  };

  const getStatusIcon = (status: string | number) => {
    switch (status) {
      case "Green":
        return <Ta.IconCircleCheck $="text-green-500 w-4 h-4" />;
      case "Yellow":
        return <Ta.IconAlertTriangle $="text-yellow-500 w-4 h-4" />;
      case "Red":
        return <Ta.IconAlertOctagon $="text-red-500 w-4 h-4" />;
      default:
        return <Ta.IconHelp $="text-gray-500 w-4 h-4" />;
    }
  };

  const statusCounts = displayData.checks.reduce(
    (acc, check) => {
      if (check.status === "Green") acc.green++;
      if (check.status === "Yellow") acc.yellow++;
      if (check.status === "Red") acc.red++;
      return acc;
    },
    { green: 0, yellow: 0, red: 0 },
  );

  const StatusDot = ({ color, count }: { color: string; count: number }) => (
    <div
      $={cn(
        "w-5 h-5 rounded-full flex items-center justify-center flex-none",
        color === "green" && "bg-green-500",
        color === "yellow" && "bg-yellow-500",
        color === "red" && "bg-red-500",
      )}
    >
      <span $="text-xs font-semibold text-white">{count}</span>
    </div>
  );

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center gap-1 text-neutral-800 px-2 py-1 rounded-full text-xs cursor-pointer">
          <span $="mr-1">{displayData.displayTitle}</span>
          <StatusDot color="green" count={statusCounts.green} />
          <StatusDot color="yellow" count={statusCounts.yellow} />
          <StatusDot color="red" count={statusCounts.red} />
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-96">
        <div $="space-y-4">
          <div $>
            <h4 $="text-sm font-semibold">{displayData.displayTitle}</h4>
            <p $="text-xs text-gray-500">{displayData.description}</p>
          </div>
          <div $>
            <p $="text-xs font-medium mb-2">
              Status Scale: {displayData.statusScaleMethod}
            </p>
            {displayData.checks.map((check, index) => (
              <div key={index} $="mb-2 last:mb-0">
                <div $="flex items-center justify-between">
                  <span $="text-xs font-medium">{check.titleDisplay}</span>
                  {getStatusIcon(check.status)}
                </div>
                <div $="flex justify-between text-xs text-gray-500">
                  <span $>Goal: {check.goal}</span>
                  <span $>Actual: {check.actual}</span>
                </div>
                <div $="mt-1">
                  {check.statusCriteria.map((criteria, criteriaIndex) => (
                    <p key={criteriaIndex} $="text-xs">
                      {criteria.statusDisplay}: {criteria.criteriaInstruction}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
