export interface MockFinancialModel {
  key: string;
  name: string;
  metrics: Array<{
    label: string;
    values: {
      "PPA 20yr": string;
      "Project life": string;
      percentage: string;
    };
  }>;
}

export const MOCK_FINANCIAL_MODELS: MockFinancialModel[] = [
  {
    key: "1",
    name: "Sponsor Returns",
    metrics: [
      {
        label: "Sponsor levered post-tax MOIC",
        values: {
          "PPA 20yr": "6.26x",
          "Project life": "6.36x",
          percentage: "N/A",
        },
      },
      {
        label: "Sponsor levered post-tax IRR",
        values: {
          "PPA 20yr": "14.92%",
          "Project life": "15.02%",
          percentage: "N/A",
        },
      },
    ],
  },
  {
    key: "2",
    name: "Sources of Funds",
    metrics: [
      {
        label: "Tax equity",
        values: {
          "PPA 20yr": "$4,589,025",
          "Project life": "$5,067,247",
          percentage: "7%",
        },
      },
      {
        label: "Back-leverage debt",
        values: {
          "PPA 20yr": "$5,789,283",
          "Project life": "$5,529,295",
          percentage: "-4%",
        },
      },
      {
        label: "Sponsor equity",
        values: {
          "PPA 20yr": "$3,066,970",
          "Project life": "$2,848,736",
          percentage: "-3%",
        },
      },
    ],
  },
  {
    key: "3",
    name: "Operating Metrics",
    metrics: [
      {
        label: "Net Operating Income (NOI)",
        values: {
          "PPA 20yr": "$1,200,000",
          "Project life": "$1,500,000",
          percentage: "N/A",
        },
      },
      {
        label: "Operating Expenses",
        values: {
          "PPA 20yr": "$500,000",
          "Project life": "$550,000",
          percentage: "N/A",
        },
      },
      {
        label: "Gross Margin",
        values: {
          "PPA 20yr": "60%",
          "Project life": "63%",
          percentage: "N/A",
        },
      },
    ],
  },
  {
    key: "4",
    name: "Financial Ratios",
    metrics: [
      {
        label: "Debt Service Coverage Ratio (DSCR)",
        values: {
          "PPA 20yr": "1.25x",
          "Project life": "1.30x",
          percentage: "N/A",
        },
      },
      {
        label: "Loan-to-Value (LTV)",
        values: {
          "PPA 20yr": "70%",
          "Project life": "68%",
          percentage: "N/A",
        },
      },
    ],
  },
  {
    key: "5",
    name: "Revenue Projections",
    metrics: [
      {
        label: "Annual Revenue",
        values: {
          "PPA 20yr": "$2,000,000",
          "Project life": "$2,200,000",
          percentage: "N/A",
        },
      },
      {
        label: "Revenue Growth Rate",
        values: {
          "PPA 20yr": "5%",
          "Project life": "4.5%",
          percentage: "N/A",
        },
      },
    ],
  },
];
