import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ActivityContentMock } from "./ActivityContentMock";
import { Ta } from "./ui/icons";

export const WorkspaceDetails = () => {
  return (
    <div $="bg-background shadow-sm rounded border border-zinc-300 overflow-hidden">
      <Tabs defaultValue="workspace" $="w-100">
        <TabsList $="grid w-full grid-cols-2 bg-[#FAF9FB] p-0 overflow-hidden border-b border-zinc-300">
          <TabsTrigger
            $="data-[state=active]:bg-neutral-50 bg-[#EFEDF3] text-muted-foreground data-[state=active]:text-[#202734] data-[state=active]:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.37)] border-r border-zinc-300 h-[100%] font-medium tracking-widest"
            value="workspace"
          >
            OVERVIEW
            {/* <Ta.IconWindowMaximize
              $="w-[20px] h-[20px] mr-2"
              color={"#7487AA"}
            /> */}
          </TabsTrigger>
          <TabsTrigger
            $="data-[state=active]:bg-neutral-50 bg-[#EFEDF3] text-muted-foreground data-[state=active]:text-[#202734] data-[state=active]:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.37)] h-[100%] font-medium tracking-widest"
            value="activity"
          >
            ACTIVITY
            {/* <Ta.IconWindowMaximize
              $="w-[20px] h-[20px] mr-2"
              color={"#7487AA"}
            /> */}
          </TabsTrigger>
        </TabsList>
        <TabsContent $="p-4" value="workspace">
          <div $="mb-6">
            <h3 $="text-sm text-[#8F849EF5] tracking-widest">CORRECTNESS</h3>
            <div $="flex mt-2 bg-zinc-50 rounded-sm border border-zinc-200 p-2 w-full justify-center space-between text-center">
              <div $="w-full">
                <h3 $="text-xs font-medium text-gray-600">Model Audit</h3>
                <div $="flex flex-row items-center mt-1 justify-stretch">
                  <div $="flex-grow bg-[#FEE2E2] text-[#EF4444] text-[10px] font-medium px-2 py-1 shadow-[inset_0px_0px_0px_0.5px_rgba(0,0,0,0.12)] rounded-l">
                    3 Alerts
                  </div>
                  <div $="flex-grow bg-[#FEF9C3] text-[#908401] text-[10px] font-medium px-2 py-1 shadow-[inset_0px_0px_0px_0.5px_rgba(0,0,0,0.12)] rounded-r">
                    5 Warnings
                  </div>
                </div>
              </div>

              <div $="ml-4 w-full">
                <h3 $="text-xs font-medium text-gray-600">Attribution</h3>
                <span $="text-xs text-gray-800">
                  <span $="text-orange-500">10 items</span> to review
                </span>
              </div>
            </div>
          </div>

          <div $="mb-6">
            <h3 $="text-sm text-[#8F849EF5] tracking-widest">OWNERS</h3>
            <div $="mt-2">
              <div $="flex items-center mb-2">
                <img
                  src="https://i.pravatar.cc/150?img=59"
                  alt="John Marciano"
                  $="w-6 h-6 rounded-full mr-2"
                />
                <div $>
                  <p $="text-sm font-medium text-gray-800">John Marciano</p>
                  <p $="text-xs text-[#737373]">
                    Tax Equity Lead at Block Sabeth
                  </p>
                </div>
              </div>
              <div $="flex items-center">
                <img
                  src="https://i.pravatar.cc/150?img=18"
                  alt="Bruno Kibon"
                  $="w-6 h-6 rounded-full mr-2"
                />
                <div $>
                  <p $="text-sm font-medium text-gray-800">Bruno Kibon</p>
                  <p $="text-xs text-[#737373]">Tax Equity Lead at BlockRock</p>
                </div>
              </div>
            </div>
          </div>

          <div $="mb-6">
            <h3 $="ttext-sm text-[#8F849EF5] tracking-widest">CONNECTIONS</h3>
            <ul $="mt-2">
              <li $="flex items-center mb-2 border-b border-zinc-200 py-2">
                <Ta.IconBrandRedhat $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800 font-medium">
                  Redhat Warehouse
                </span>
              </li>
              <li $="flex items-center mb-2 border-b border-zinc-200 py-2">
                <Ta.IconTableShare $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800 font-medium">
                  Solar Model Forecast 2024
                </span>
              </li>
              <li $="flex items-center mb-2 py-2">
                <Ta.IconTableShare $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800 font-medium">
                  CIBAM Model 2024
                </span>
              </li>
            </ul>
          </div>

          <div $>
            <h3 $="text-sm text-[#8F849EF5] tracking-widest">QUICK LINKS</h3>
            <ul $="mt-2">
              <li $="flex items-center py-2 border-b border-zinc-200">
                <Ta.IconFileSymlink $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800">February Solar Report</span>
              </li>
              <li $="flex items-center py-2 border-b border-zinc-200">
                <Ta.IconFileSymlink $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800">
                  March 2024 Investment Memo
                </span>
              </li>
              <li $="flex items-center py-2 ">
                <Ta.IconFileSymlink $="w-[16px] h-[16px] mr-2 stroke-[1.25] stroke-black" />
                <span $="text-sm text-gray-800">
                  March 2024 Solar Infra Memo
                </span>
              </li>
            </ul>
          </div>
        </TabsContent>
        <TabsContent $="p-4 mt-0" value="activity">
          <ActivityContentMock />
        </TabsContent>
      </Tabs>
    </div>
  );
};
