import { AssumptionsTable } from "@/components/AssumptionsTable";
import { DocumentsTable } from "@/components/DocumentsTable";
import {
  ProvideDatabookContext,
  useDatabook,
} from "@/contexts/DatabookContext";
import { useSelectAtom } from "@/lib/jotai/useSelectAtom";
import { S } from "@phosphor/prelude";
import {
  WorkspaceChangeKind,
  WorkspaceID,
  WorkspaceProposalID,
} from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useMemo } from "react";

const DatabookSearchSchema = S.Struct({
  proposals: S.Array(WorkspaceProposalID).pipe(S.optional),
  unsaved: S.Array(WorkspaceChangeKind).pipe(S.optional),
  documentSorting: S.Array(S.Struct({ id: S.String, desc: S.Boolean })).pipe(
    S.optional,
  ),
  assumptionSorting: S.Array(S.Struct({ id: S.String, desc: S.Boolean })).pipe(
    S.optional,
  ),
});

export const Route = createFileRoute(
  "/$org/_layout/admin/_layout/workspace/$workspace/databook",
)({
  component: DatabookPageWrapper,
  validateSearch: S.decodeUnknownSync(DatabookSearchSchema),
});

function DatabookPageWrapper() {
  const params = Route.useParams();
  const workspaceId = useMemo(
    () => WorkspaceID.make(params.workspace),
    [params.workspace],
  );
  return (
    <ProvideDatabookContext workspaceId={workspaceId}>
      <DatabookPage />
    </ProvideDatabookContext>
  );
}

function DatabookPage() {
  const { databookAtom } = useDatabook();
  const workspaceName = useSelectAtom(databookAtom, (a) => a.data?.name);

  return (
    <div $="p-8">
      <h1 $="text-2xl font-bold mb-4">{workspaceName}</h1>
      <AssumptionsTable route={Route.id} />

      <h2 $="text-xl font-semibold mb-2 mt-4">Documents</h2>
      <DocumentsTable />
    </div>
  );
}
